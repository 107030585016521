/*
Template Name: Color Admin - Responsive Admin Dashboard Template build with Twitter Bootstrap 3 & 4
Version: 4.2.0
Author: Sean Ngu
Website: http://www.seantheme.com/color-admin-v4.2/admin/

::  1.0 General Reset & Setup
-------------------------------------------
    1.1 Reset and overrides
    1.2 Font Awesome Version Control

::  2.0 Page Options
-------------------------------------------
    2.1  Option - Fixed Header
    2.1  Option - Fixed Header
    2.2  Option - Fixed Sidebar
    2.3  Option - Page without Sidebar
    2.4  Option - Page with Right Sidebar
    2.5  Option - Page with Two Sidebar
    2.6  Option - Boxed Layout (Added in V1.9)

::  3.0 Page Structure
-------------------------------------------
    3.1  Page Loader
    3.2  Page Header
         3.2.1 Page Header - Default
         3.2.2 Page Header - Inverse
         3.2.3 Page Header - Mega Menu (Added in V1.7)
    3.3  Page Sidebar
         3.3.1 Page Sidebar - Grid Style
         3.3.2 Page Sidebar - Gradient Style
         3.3.3 Page Sidebar - Sub Menu
         3.3.4 Page Sidebar - Minified Style
         3.3.5 Page Sidebar - Light Style (Added in V1.7)
         3.3.6 Page Sidebar - Wide Style (Added in V1.7)
         3.3.7 Page Sidebar - Transparent Style (Added in V1.9)
         3.3.8 Page Sidebar - Right Sidebar (Added in V4.0)
         3.3.9 Page Sidebar - Floating Sub Menu (Added in V4.0)
    3.4  Page Content
    3.5  Page Footer
    3.6  Page Top Menu (Added in V1.9)
         3.6.1 Page Top Menu - Sub Menu (Added in V1.9)
         3.6.2 Page Top Menu - Left & Right Control (Added in V1.9)

::  4.0 Components
-------------------------------------------
    4.1  Component - Form Elements
    4.2  Component - Dropdown Menu
    4.3  Component - Tooltip
    4.4  Component - Alert
    4.5  Component - Note Setting
    4.6  Component - Badge & Label Setting
    4.7  Component - Pagination & pager
    4.8  Component - Progress bar
    4.9  Component - Nav Setting
    4.10 Component - Nav Tabs
    4.11 Component - Nav Pills
    4.12 Component - Tab Content
    4.13 Component - Accordion Panel
    4.14 Component - Button
         4.14.1 Component - Button - Default
         4.14.2 Component - Button - White
         4.14.3 Component - Button - Inverse
         4.14.4 Component - Button - Primary
         4.14.5 Component - Button - Success
         4.14.6 Component - Button - Warning
         4.14.7 Component - Button - Danger
         4.14.8 Component - Button - Info
         4.14.9 Component - Button - Purple
         4.14.10 Component - Button - Yellow
         4.14.11 Component - Button - Grey
         4.14.12 Component - Button - Lime
         4.14.13 Component - Button - Pink
         4.14.14 Component - Button - Indigo
         4.14.15 Component - Button - Green
    4.15 Component - Panel
    4.16.Component - Panel - Panel Expand
    4.17 Component - Panel - Panel loading
    4.18 Component - Modal Setting
    4.19 Component - Media Object
    4.20 Component - Table
    4.21 Component - Well
    4.22 Component - Jumbotron
    4.23 Component - List Group
    4.24 Component - Carousel
    4.25 Component - Code
    4.26 Component - Popover
    4.27 Component - Caret
    4.28 Component - Lead
    4.29 Component - List Inline
    4.30 Component - Blockquote
    4.31 Component - Close Button
    4.32 Component - Inverse List Group Item (Added in V1.3)
    4.33 Component - Material Icon (Added in V2.1)
    4.34 Component - CSS Checkbox (Added in V3.0)
    4.35 Component - CSS Radio Button (Added in V3.0)
    4.36 Component - Bootstrap 4 - Cards (Added in V3.0)

::  5.0 Plugins
-------------------------------------------
    5.1  Plugin - jQuery Draggable
    5.2  Plugin - Slimscrollbar
    5.3  Plugin - Gritter Notification
    5.4  Plugin - Data Table
    5.5  Plugin - Datepicker
    5.6  Plugin - Colorpicker
    5.7  Plugin - Bootstrap Timepicker
    5.8  Plugin - jQuery Autocomplete
    5.9  Plugin - Combobox
    5.10 Plugin - Bootstrap Select
    5.11 Plugin - TagIt
    5.12 Plugin - Parsley
    5.13 Plugin - CKEditor
    5.14 Plugin - Bootstrap Wizard
    5.15 Plugin - Fullcalendar
    5.16 Plugin - jVectorMap
    5.17 Plugin - Flot Chart
    5.18 Plugin - Sparkline
    5.19 Plugin - Iris Range Slider
    5.20 Plugin - Bootstrap Colorpalette
    5.21 Plugin - X-Editable
    5.22 Plugin - Password Indicator
    5.23 Plugin - Powerange Slider (Added in V1.2)
    5.24 Plugin - DateTimepicker (Added in V1.2)
    5.25 Plugin - Wysihtml5 Sandbox (Added in V1.2)
    5.26 Plugin - Typeahead (Added in V1.2)
    5.27 Plugin - Select 2 Choice (Added in V1.2)
    5.28 Plugin - Bootstrap Calendar (Added in V1.3)
    5.29 Plugin - Superbox (Added in V1.3)
    5.30 Plugin - Pace Loader (Added in V1.5)
    5.31 Plugin - Jstree (Added in V1.7)
    5.32 Plugin - Bootstrap Date Range Picker (Added in V1.7) 
    5.33 Plugin - Select 2 (Added in V1.7)
    5.34 Plugin - Bootstrap Date Timepicker (Added in V1.8)
    5.35 Plugin - Nvd3 Chart (Added in v1.9)
    5.36 Plugin - Intro Js Setting (Added in V2.1)
    5.37 Plugin - Summernote (Added in V2.1)
    5.38 Plugin - jQuery Simple Colorpicker (Added in V2.1)
    5.39 Plugin - Dropzone (Added in V2.1)
    5.40 Plugin - Bootstrap SweetAlert (Added in V3.0)
    5.41 Plugin - Highlight JS (Added in V4.0)
    5.42 Plugin - jQuery Smart Wizard (Added in V4.0)

::  6.0 Widgets
-------------------------------
    6.1  Widget - General Setting (Added in v4.2)
    6.2  Widget - Media Message
    6.3  Widget - Stats
    6.4  Widget - Todolist
    6.5  Widget - Theme Panel (Added in V1.2)
    6.6  Widget - Chart (Added in V1.3)
    6.7  Widget - Registered Users List (Added in V1.3)
    6.8  Widget - Chat List (Added in V1.3)
    6.9  Widget - Unlimited Nav Tabs (Added in V1.6)
    6.10 Widget - Widget List (Added in V4.2)
    6.11 Widget - Widget Chat (Added in V4.2)
    6.12 Widget - Widget Input (Added in V4.2)
    6.13 Widget - Widget Todolist (Added in V4.2)
    6.14 Widget - Widget Map (Added in V4.2)
    6.15 Widget - Widget Icon (Added in V4.2)
    6.16 Widget - Widget Image (Added in V4.2)
    6.17 Widget - Widget Card (Added in V4.2)
    6.18 Widget - Widget Chart (Added in V4.2)
    6.19 Widget - Widget Table (Added in V4.2)

::  7.0 Pages
-------------------------------
    7.1  Page - Search Result
    7.2  Page - Vector / Google Map
    7.3  Page - Invoice                
    7.4  Page - Login
    7.5  Page - Gallery
    7.6  Page - 404 Error
    7.7  Page - Timeline (Added in V1.0)
    7.8  Page - Coming Soon (Added in V1.0)
    7.9  Page - Login V2 (Added in V1.2) 
    7.10 Page - Email Inbox (Added in V1.4)
    7.11 Page - Email Detail (Added in V1.4)
    7.12 Page - Email Compose (Added in V1.4)
    7.13 Page - Login & Register V3 (Added in V1.7)
    7.14 Page - Profile (Added in V1.7)
    
::  8.0 Content Theme Flat Black
-------------------------------------------
    8.1  Flat Black - Body Setting
    8.2  Flat Black -  Navbar Inverse Setting
    8.3  Flat Black - Sidebar Setting
    8.4  Flat Black - Page Footer Setting
    8.5  Flat Black - List Group item Setting
    8.6  Flat Black - Nav Pills Setting
    8.7  Flat Black - Badge Setting
    8.8  Flat Black - Tabs Setting
    8.9  Flat Black - Panel Setting
    8.10 Flat Black - Table Setting
    8.11 Flat Black - Button Inverse Setting
    8.12 Flat Black - Button Default Setting
    8.13 Flat Black - Button White Setting
    8.14 Flat Black - Pagination Setting
    8.15 Flat Black -  Progress Bar Setting
    8.16 Flat Black - Vector Map Setting
    8.17 Flat Black - Helper Classes Setting
    8.18 Flat Black - Bootstrap Wizard Setting
    8.19 Flat Black - Timeline
    8.20 Flat Black - Invoice
    8.21 Flat Black - Error Page

::  9.0 Predefined CSS Setting
-------------------------------------------
    9.1  Predefined Classes
    
::  10.0 Content Right To Left (Added in V4.1)
-------------------------------------------
    10.1  RTL Settings
*/

/* -------------------------------
   1.0 General reset & setup
------------------------------- */

/* 1.1 Reset and overrides */

body,
html {
  height: 100%;
}
body {
  background: #d9e0e7;
  font-size: 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #707478;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ie8 body {
  font-family: Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #242a30;
}
.h1,
h1 {
  font-size: 36px;
}
.h2,
h2 {
  font-size: 30px;
}
.h3,
h3 {
  font-size: 24px;
}
.h4,
h4 {
  font-size: 18px;
}
.h5,
h5 {
  font-size: 14px;
}
.h6,
h6 {
  font-size: 12px;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 60%;
  font-weight: normal;
  color: #6a7786;
}
a {
  color: #007bff;
  transition: color 100ms ease-in-out;
}
a:hover {
  color: #0056b3;
}
a:focus {
  outline: none;
}
b,
strong {
  font-weight: bold;
}
label {
  color: #242a30;
  font-weight: 600;
}
hr {
  border: none;
  height: 1px;
  background: #f1f3f5;
}

/* 1.2 Font Awesome Version Control */

.fc button .fc-icon,
.datepicker th.prev,
.datepicker th.next,
.top-menu li > a .caret,
.gallery .rating span.star,
.wysihtml5-toolbar .glyphicon,
.editable-buttons .glyphicon,
.input-append .icon-eye-open,
.input-prepend .icon-eye-open,
.input-append .icon-eye-close,
.input-prepend .icon-eye-close,
.tagit .ui-icon-close:before,
.sidebar li > a .caret:before,
.attached-document > li:before,
.float-sub-menu li > a .caret:before,
.combobox-container .glyphicon-remove,
.combobox-container .icon-remove:before,
.bootstrap-timepicker-widget .glyphicon,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
.bootstrap-datetimepicker-widget .glyphicon,
.bootstrap-calendar .calendar .icon-arrow-right,
.bootstrap-calendar .calendar .icon-arrow-left,
.theme-panel .theme-list > li.active > a:before,
.sw-main > ul.step-anchor.nav-tabs > li.done > a .number:before,
.simplecolorpicker.glyphicons span.color[data-selected]:after,
.simplecolorpicker.fontawesome span.color[data-selected]:after,
.jstree-default .jstree-checked > .jstree-checkbox:after,
.jstree-default.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox:after {
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

/* -------------------------------
   2.0 Page Option
------------------------------- */

/* 2.1 Option - Fixed Header */

.page-header-fixed {
  padding-top: 64px;
}
.page-header-fixed #header {
  position: fixed;
}
.page-header-fixed.has-scroll #header {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
}

/* 2.2 Option - Fixed Sidebar */

.page-sidebar-fixed .sidebar {
  position: fixed;
}

/* 2.3 Option - Page without Sidebar */

.page-without-sidebar .content {
  margin-left: 0;
}

/* 2.4 Option - Page with Right Sidebar */

.page-with-right-sidebar .content {
  margin-left: 0;
  margin-right: 220px;
}
.page-with-right-sidebar .footer {
  margin-left: 30px;
  margin-right: 250px;
}
.page-with-right-sidebar .sidebar,
.page-with-right-sidebar .sidebar-bg {
  left: auto;
  right: 0;
}

/* 2.5 Option - Page with Two Sidebar */

.page-with-two-sidebar.page-sidebar-fixed .sidebar.sidebar-right {
  position: fixed;
}
.page-with-two-sidebar .sidebar.sidebar-right,
.page-with-two-sidebar .sidebar-bg.sidebar-right {
  right: 0;
  left: auto;
}
.page-with-two-sidebar .content {
  margin-right: 220px;
}
.page-with-two-sidebar.page-sidebar-minified .sidebar.sidebar-right,
.page-with-two-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right {
  width: 220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar.sidebar-right,
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-bg.sidebar-right {
  right: -220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .content {
  margin-right: 0;
}
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .sidebar-bg.sidebar-right,
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .sidebar.sidebar-right {
  right: -250px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .content,
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .content {
  margin-right: 0;
}

/* 2.6 Option - Boxed Layout (Added in V1.9) */

.boxed-layout {
  background-image: url(images/cover-pattern.png);
}
.boxed-layout .top-menu,
.boxed-layout .pace {
  width: 1080px;
  left: 50%;
  margin-left: -540px;
}
.boxed-layout .page-container {
  width: 1080px;
  margin: 0 auto;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.5);
  min-height: 100%;
  background: #d9e0e7;
  overflow: hidden;
  position: relative;
}
.boxed-layout .page-container #header,
.boxed-layout .page-container .sidebar,
.boxed-layout .page-container .sidebar-bg {
  left: auto;
}
.boxed-layout .page-container #header {
  width: 1080px;
  right: auto;
}
.boxed-layout .pace .pace-progress,
.boxed-layout .pace .pace-activity,
.boxed-layout .page-with-top-menu .top-menu {
  position: absolute;
}

/* -------------------------------
   3.0 Page Structure
------------------------------- */

/* 3.1 Page Loader */

#page-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e6e8ec;
  z-index: 9999;
}
#page-loader.fade {
  display: none;
}
#page-loader.fade.in,
#page-loader.fade.show {
  display: block;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner,
.spinner-small {
  height: 40px;
  width: 40px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-top: 2px solid #fff;
  border-radius: 100%;
}
.fade.in .spinner,
.fade.in .spinner-small,
.fade.show .spinner,
.fade.show .spinner-small {
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
}
.spinner-small {
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-top: 2px solid #24b498;
  height: 30px;
  width: 30px;
  margin: -15px 0 0 -15px;
}

/* 3.2 Page Header */

.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  margin-bottom: 0;
  padding: 0;
  transition: box-shadow 0.2s linear;
}
.header:before,
.header:after {
  content: "";
  display: table;
  clear: both;
}
.header .navbar-toggle {
  position: relative;
  float: right;
  padding: 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: none;
  border-radius: 4px;
  outline: none;
}
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: none !important;
  opacity: 0.6;
}
.header .navbar-toggle.pull-left {
  margin-left: 15px;
  margin-right: 0;
}
.header .navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.header .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
.header .navbar-header:before,
.header .navbar-header:after {
  content: "";
  display: table;
  clear: both;
}
.header .navbar-brand {
  float: left;
  margin-right: 10px;
  padding: 10px 20px;
  height: 50px;
  width: 220px;
  font-weight: 100;
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
}
.header .navbar-brand img {
  max-width: 100%;
  max-height: 100%;
}
.header .navbar-logo {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  background: #00acac;
  border: 10px solid transparent;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.header .navbar {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.header .navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.header .navbar-nav > li {
  float: left;
  position: relative;
}
.header .navbar-nav > li.divider {
  height: 34px;
  margin-top: 10px;
  background: #e2e7eb;
  width: 1px;
}
.header .navbar-nav > li > a,
.header .navbar-nav > li .dropdown-toggle {
  display: block;
  text-decoration: none;
  line-height: 20px;
  padding: 15px;
  border: none;
}
.header .navbar-nav > li > a:hover,
.header .navbar-nav > li > a:focus {
  opacity: 0.6;
}
.header .navbar-nav > li > a .label,
.header .navbar-nav > li .dropdown-toggle .label {
  position: absolute;
  top: 8px;
  right: 5px;
  display: block;
  background: #00acac;
  line-height: 12px;
  font-weight: 600;
  color: #fff;
  padding: 0.3em 0.6em 0.3em;
  border-radius: 20px;
}
.header .navbar-nav > li > a > i {
  top: 1px;
  position: relative;
  font-size: 16px;
}
.header .navbar-nav > li > a.icon i {
  font-size: 24px;
  height: 24px;
  margin: -2px 0;
  display: block;
}
.header .navbar-nav > .open > a,
.header .navbar-nav > .open > a:hover,
.header .navbar-nav > .open > a:focus {
  background: none !important;
  color: #333;
  opacity: 1;
}
.header .navbar-nav .dropdown-toggle:after {
  display: none;
}
.header .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top: 1px solid #f2f3f4;
  box-shadow: 0 13px 25px -2px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.header .navbar-nav > li > .dropdown-menu.media-list .media-heading {
  font-weight: 600;
  margin: 0;
  line-height: 16px;
  font-size: 12px;
}
.header .navbar-form {
  padding: 0 15px;
  margin: 9px 0;
}
.header .navbar-form .form-group {
  margin: 0;
}
.header .navbar-form .btn-search {
  position: absolute;
  right: 15px;
  top: 9px;
  height: 32px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  background: none;
  border-radius: 0 30px 30px 0;
}
.header .navbar-form .btn-search > i[class*="ion-"] {
  font-size: 22px;
  display: block;
}
.header .navbar-form .form-control {
  width: 220px;
  padding: 5px 15px;
  height: 32px;
  background: #f2f3f4;
  border-color: #f2f3f4;
  border-radius: 30px;
}
@keyframes expand {
  from {
    width: 220px;
  }
  to {
    width: 300px;
  }
}
@-webkit-keyframes expand {
  from {
    width: 220px;
  }
  to {
    width: 300px;
  }
}
.header .navbar-form .form-control:focus {
  width: 300px;
  border-color: #f2f3f4;
  box-shadow: none;
  -webkit-animation: expand 0.2s;
  animation: expand 0.2s;
}
.fade .navbar-form .form-control {
  -webkit-animation: none;
}
.header .navbar-user img {
  float: left;
  width: 30px;
  height: 30px;
  margin: -5px 10px -5px 0;
  border-radius: 30px;
}
.header .navbar-user .image {
  float: left;
  width: 30px;
  height: 30px;
  margin: -5px 10px -5px 0;
  border-radius: 30px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.header .navbar-user .image.image-icon {
  float: left;
  width: 30px;
  height: 30px;
  margin: -5px 10px -5px 0;
  border-radius: 30px;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  overflow: hidden;
}
.header
  .navbar-user
  .image.im
  .navbar-header.navbar-header-without-bg
  .navbar-brand {
  background: none !important;
}
.navbar-language .flag-icon {
  font-size: 15px;
  margin: 2px 7px 0;
  float: left;
}

/* 3.2.1 Page Header - Default */

.header.navbar-default {
  background: #fff;
}
.header.navbar-default .brand,
.header.navbar-default .navbar-brand {
  color: #333;
}
.header.navbar-default .navbar-toggle .icon-bar {
  background: #2d353c;
}
.header.navbar-default .navbar-nav > li > a,
.header.navbar-default .navbar-nav > li .dropdown-toggle {
  color: #585663;
}
.header.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
}

/* 3.2.2 Page Header - Inverse */

.header.navbar-inverse {
  background: #1a2229;
}
.header.navbar-inverse .navbar-toggle .icon-bar {
  background: #585d61;
}
.header.navbar-inverse .navbar-brand,
.header.navbar-inverse .navbar-nav > li > a,
.header.navbar-inverse .navbar-nav > li .dropdown-toggle {
  color: #a8acb1;
}
.header.navbar-inverse .navbar-nav > li.divider {
  background: #3f4b55;
}
.header.navbar-inverse .navbar-form {
  margin: 10px 0;
}
.header.navbar-inverse .navbar-form .form-control,
.header.navbar-inverse .navbar-form .form-control:focus {
  background: #585d62;
  border-color: #585d62;
  color: #fff;
  height: 30px;
}
.header.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder {
  color: #a8acb1;
}
.header.navbar-inverse .navbar-form .form-control::-moz-placeholder {
  color: #a8acb1;
}
.header.navbar-inverse .navbar-form .form-control:-ms-input-placeholder {
  color: #a8acb1;
}
.header.navbar-inverse .navbar-form .form-control:-moz-placeholder {
  color: #a8acb1;
}
.header.navbar-inverse .navbar-form .btn-search {
  color: #a8acb1;
}

/* 3.2.3 Page Header - Mega Menu (Added in V1.7) */

.dropdown.dropdown-lg,
.header .dropdown.dropdown-lg {
  position: static;
}
.dropdown-menu.dropdown-menu-lg {
  left: 20px;
  right: 20px;
  padding: 20px;
}
.dropdown-header {
  font-size: 14px;
  font-weight: bold;
  color: #242a30;
  padding: 0;
  margin: 0 0 10px;
}
.dropdown-menu.dropdown-menu-lg .nav > li > a {
  padding: 0;
  background: none;
  line-height: 24px;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active.open > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background: #f4f6f7 !important;
  opacity: 1;
}

/* 3.3 Page Sidebar */

.sidebar {
  width: 220px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 64px;
  background: #702963;
  z-index: 1010;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.sidebar-bg {
  background: #702963;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  z-index: 1000;
}
.sidebar .nav {
  display: block;
}
.sidebar .nav > li {
  position: relative;
}
.sidebar .nav > li > a {
  padding: 7px 20px;
  line-height: 20px;
  color: #ffffff;
  display: block;
  text-decoration: none;
}
.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus {
  background: none;
  color: #fff;
}
.sidebar .nav > li:before,
.sidebar .nav > li:after,
.sidebar .nav > li > a:before,
.sidebar .nav > li > a:after {
  content: "";
  clear: both;
  display: table;
}
.sidebar .nav > li > a i {
  float: left;
  margin-right: 10px;
  width: 14px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.sidebar .nav > li > a i[class^="ion-"] {
  margin-right: 8px;
  width: 18px;
  font-size: 18px;
  margin-left: -2px;
}
.sidebar .nav > li > a .icon-img {
  float: left;
  margin-right: 10px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
  overflow: hidden;
  border-radius: 3px;
}
.sidebar .nav > li > a .icon-img img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.sidebar .nav > li > a .badge {
  margin-top: 1px;
  padding: 3px 8px;
  background: #1b1f24;
  font-weight: 600;
  font-size: 10px;
}
.sidebar .nav > li.active > a {
  position: relative;
  z-index: 10;
}
.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:hover,
.sidebar .nav > li.active > a:focus {
  color: #fff;
  background: #5f2454;
}
.sidebar .nav > li.active > a i {
  color: #00acac;
}
.sidebar .nav > li.active > a .label.label-success,
.sidebar .nav > li.active > a .badge.badge-success {
  background: #008a8a;
}
.sidebar .nav > li.nav-header {
  margin: 0;
  padding: 15px 20px 3px;
  line-height: 20px;
  font-size: 11px;
  color: #fff;
  font-weight: 600;
}
.sidebar .nav > li.nav-header a {
  padding: 0;
  margin: 0;
  display: inline;
}
.sidebar .nav > li.nav-header a:hover,
.sidebar .nav > li.nav-header a:focus {
  background: none;
  color: #fff;
}
.sidebar .nav > li.nav-header a i {
  float: none;
  margin: 0;
}
.sidebar .nav > li.nav-profile {
  padding: 20px;
  color: #fff;
  background: #702963;
  overflow: hidden;
}
.sidebar .nav > li.nav-profile a {
  padding: 0;
}
.sidebar .nav > li.nav-profile > a {
  margin: -20px;
  padding: 20px;
  display: block;
  color: #fff;
}
.sidebar .nav > li.nav-profile .image {
  width: 34px;
  height: 34px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.sidebar .nav > li.nav-profile .image img {
  max-width: 100%;
  max-height: 100%;
}
.sidebar .nav > li.nav-profile .image.image-icon i {
  float: none;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  display: block;
  width: auto;
  margin: 0;
}
.sidebar .nav > li.nav-profile .info {
  font-size: 14px;
  position: relative;
}
.sidebar .nav > li.nav-profile .info .caret {
  margin-top: 2px;
}
.sidebar .nav > li.nav-profile .info small {
  display: block;
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  margin-bottom: -5px;
}
.sidebar .nav > li.nav-profile .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("images/cover-sidebar-user.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.sidebar .nav > li.nav-profile .cover.with-shadow:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.2s linear;
  background: rgba(26, 34, 41, 0.75);
}
.sidebar .nav > li.nav-profile a:hover .cover.with-shadow:before,
.sidebar .nav > li.nav-profile a:focus .cover.with-shadow:before {
  opacity: 0.75;
}
.sidebar .nav > li.nav-widget {
  padding: 10px 20px;
}
.sidebar .nav > li.nav-widget i {
  margin-right: auto;
}
.sidebar .nav.nav-profile {
  padding: 0;
  border-bottom: 1px solid #242a31;
  padding-bottom: 5px;
  display: none;
}
.sidebar .nav.nav-profile.active {
  display: block;
}

/* 3.3.1 Page Sidebar - Grid Style */

.sidebar.sidebar-grid .nav > li > a {
  border-bottom: 1px solid #46505a;
  border-top: 1px solid #46505a;
}
.sidebar.sidebar-grid .nav > li.active > a {
  border-color: #242a31;
  z-index: 10;
}
.sidebar.sidebar-grid .nav > li.expanding > a,
.sidebar.sidebar-grid .nav > li.expand > a {
  border-bottom-color: transparent;
}
.sidebar.sidebar-grid .nav > li + li {
  margin-top: -1px;
}

/* 3.3.2 Page Sidebar - Gradient Style */

.gradient-enabled .sidebar .nav > li.active > a .label.label-theme,
.gradient-enabled .sidebar .nav > li.active > a .badge {
  background: rgba(0, 0, 0, 0.4);
}

/* 3.3.3 Page Sidebar - Sub Menu */

.sidebar li > a .caret,
.float-sub-menu li > a .caret {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 11px;
  border: none;
}
.sidebar li > a .caret:before,
.float-sub-menu li > a .caret:before {
  content: "\f054";
  display: block;
  text-align: center;
  transition: all 0.2s linear;
  opacity: 0.5;
}
.sidebar li.active > a .caret:before,
.sidebar li.expanding > a .caret:before,
.sidebar li.expand > a .caret:before,
.float-sub-menu li.active > a .caret:before,
.float-sub-menu li.expanding > a .caret:before,
.float-sub-menu li.expand > a .caret:before {
  opacity: 1;
  transform: rotate(90deg);
}
.sidebar li.closing > a .caret:before,
.sidebar li.closed > a .caret:before,
.float-sub-menu li.closing > a .caret:before,
.float-sub-menu li.closed > a .caret:before {
  transform: rotate(0deg);
}
.sidebar .sub-menu {
  list-style-type: none;
  padding: 5px 0 10px 39px;
  margin: 0;
  position: relative;
  display: none;
}
.sidebar .sub-menu > li {
  position: relative;
}
.sidebar .sub-menu > li:before {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  bottom: 0;
  width: 2px;
}
.sidebar .sub-menu > li:after {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  border: 1px solid #889097;
  top: 11px;
  border-radius: 4px;
  margin-top: -2px;
  z-index: 10;
  background: #2d353c;
}
.sidebar .sub-menu > li:first-child:before {
  top: -14px;
}
.sidebar .sub-menu > li:first-child:last-child:before {
  height: 27px;
}
.sidebar .sub-menu > li:last-child:before {
  bottom: auto;
  height: 13px;
}
.sidebar .sub-menu > li:before,
.sidebar .sub-menu > li > a:after,
.sidebar .sub-menu > li.has-sub > a:before {
  background: #242a31;
}
.sidebar .sub-menu > li.active:after {
  border-color: #00acac;
}
.sidebar .sub-menu > li > a {
  padding: 3px 20px 3px 15px;
  display: block;
  color: #889097;
  text-decoration: none;
  position: relative;
}
.sidebar .sub-menu > li > a:hover,
.sidebar .sub-menu > li > a:focus,
.sidebar .sub-menu > li.active > a,
.sidebar .sub-menu > li.active > a:hover,
.sidebar .sub-menu > li.active > a:focus {
  color: #fff;
}
.sidebar .sub-menu > li > a:after {
  content: "";
  position: absolute;
  left: -11px;
  top: 11px;
  width: 11px;
  height: 2px;
}
.sidebar .sub-menu > li.has-sub > a:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 11px;
  bottom: 11px;
  width: 2px;
}
.sidebar .sub-menu .sub-menu {
  padding: 0 0 0 15px;
  background: none;
}
.sidebar .nav > li li.has-sub.active > a {
  color: #889097;
}
.sidebar .has-sub.active > .sub-menu {
  display: block;
}
.sidebar .nav .sub-menu > li > a .caret {
  margin-top: -1px;
  left: -5px;
  color: #a3a7ac;
}

/* 3.3.4 Page Sidebar - Minified Style */

.page-sidebar-minified .sidebar {
  width: 60px;
  position: absolute;
}
.page-sidebar-minified .sidebar-bg {
  width: 60px;
}
.page-sidebar-minified .content {
  margin-left: 60px;
}
.page-sidebar-minified .footer {
  margin-left: 85px;
}
.page-sidebar-minified.page-sidebar-fixed .sidebar {
  position: fixed;
}
.page-sidebar-minified:not(.page-sidebar-fixed) .sidebar .slimScrollDiv,
.page-sidebar-minified:not(.page-sidebar-fixed) .sidebar .slimScrollDiv > div {
  overflow: visible !important;
  height: auto !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv,
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv > div,
.page-sidebar-minified.page-sidebar-fixed .sidebar .slimScrollDiv,
.page-sidebar-minified.page-sidebar-fixed .sidebar .slimScrollDiv > div {
  overflow: hidden !important;
}
.page-sidebar-minified .sidebar .nav > li > a {
  padding: 10px 20px;
}
.page-sidebar-minified .sidebar .nav-profile,
.page-sidebar-minified .sidebar .nav-header,
.page-sidebar-minified .sidebar .nav > li > a > span {
  display: none;
}
.page-sidebar-minified .sidebar .nav-profile.expand {
  display: none !important;
}
.page-sidebar-minified .sidebar .caret {
  position: absolute;
  top: 10px;
  right: 5px;
}
.page-sidebar-minified .sidebar .caret:before,
.page-sidebar-minified .sidebar li.active .caret:before,
.page-sidebar-minified .sidebar li.expanding .caret:before,
.page-sidebar-minified .sidebar li.expand .caret:before {
  transform: rotate(0deg);
}
.page-sidebar-minified .sidebar .nav > li > a > i {
  margin: 0;
}
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 220px;
  display: none;
  padding: 10px 0 10px 30px;
  margin: 0;
  background: #242a31;
}
.page-sidebar-minified .sidebar-minify-btn i:before {
  content: "\f101";
}
.page-sidebar-minified.page-with-right-sidebar .content {
  margin-right: 60px;
  margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .footer {
  margin-right: 90px;
  margin-left: 30px;
}
.page-sidebar-minified.page-with-right-sidebar .sub-menu {
  left: auto !important;
  right: 100%;
}
.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret {
  position: absolute;
  left: 0;
}
.page-sidebar-minified.page-with-right-sidebar
  .nav
  > li.has-sub
  > a
  .caret:before {
  content: "\f104";
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu .caret {
  left: 0;
  top: 2px;
  border-left: 4px solid transparent !important;
  border-right: 4px solid !important;
}
.page-sidebar-minified.page-with-right-sidebar
  .sidebar
  .nav
  li.has-sub
  > .sub-menu {
  padding: 10px 30px 10px 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu:before {
  right: 26px;
  left: auto;
}
.page-sidebar-minified.page-with-right-sidebar
  .sidebar
  .sub-menu
  > li
  > a:before {
  right: 0;
  left: auto;
  margin-right: -6px;
  margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn i:before {
  content: "\f100";
}
.page-sidebar-minified .sidebar .slimScrollBar,
.page-sidebar-minified .sidebar .slimScrollRail {
  display: none !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollBar {
  display: block !important;
}
.sidebar-minify-btn {
  margin: 10px 0;
  float: right;
  padding: 5px 20px 5px 10px !important;
  background: #1b1f24;
  color: #fff;
  border-radius: 20px 0 0 20px;
}
.sidebar-minify-btn:hover,
.sidebar-minify-btn:focus {
  background: #575d63 !important;
}
.sidebar-minify-btn i {
  margin: 0 !important;
  color: #fff;
}

/* 3.3.5 Page Sidebar - Light Style (Added in V1.7) */

.page-with-light-sidebar .sidebar,
.page-with-light-sidebar .sidebar-bg {
  background: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li > a {
  color: #575d63;
  font-weight: 600;
}
.flat-black .page-with-light-sidebar .sidebar .nav > li > a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li > a:focus,
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li > a:hover,
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li > a:focus {
  color: #333;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile a,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a:hover,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a:focus {
  color: #fff !important;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu,
.page-sidebar-minified.page-with-light-sidebar
  .sidebar
  .nav
  li.has-sub
  > .sub-menu,
.page-sidebar-minified.page-with-light-sidebar
  .sidebar
  .nav
  > li.has-sub:focus
  > a,
.page-sidebar-minified.page-with-light-sidebar
  .sidebar
  .nav
  > li.has-sub:hover
  > a {
  background: none;
  color: #2d353c;
}
.page-with-light-sidebar .sidebar .nav > li.active > a {
  color: #fff;
}
.page-with-light-sidebar .sidebar .nav.nav-profile {
  border-bottom: 1px solid #b1b9c0;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu:before {
  background: #b1b9c0;
}
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:hover,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:focus,
.page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.page-with-light-sidebar .sidebar .nav > li.active > a,
.page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active > a,
.page-with-light-sidebar.page-sidebar-minified
  .sidebar
  .nav
  > li.active:hover
  > a,
.page-with-light-sidebar.page-sidebar-minified
  .sidebar
  .nav
  > li.active:focus
  > a {
  color: #2d353c;
  background: #e6e9ea;
  font-weight: 600;
}
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.flat-black
  .page-with-light-sidebar
  .sidebar
  .nav
  > li
  .sidebar-minify-btn:hover,
.flat-black
  .page-with-light-sidebar
  .sidebar
  .nav
  > li
  .sidebar-minify-btn:focus {
  background: #e0e0e0;
}
.page-with-light-sidebar .sidebar .nav > li.expand > a,
.page-with-light-sidebar .sidebar .nav > li > a:hover,
.page-with-light-sidebar .sidebar .nav > li > a:focus {
  background: none;
  color: #2d353c;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu > li.active > a:before {
  color: #575d63;
}
.page-with-light-sidebar .sidebar .sub-menu > li > a {
  font-weight: normal;
}
.page-with-light-sidebar .sidebar .sub-menu > li.active > a {
  font-weight: 600;
}
.page-with-light-sidebar .sidebar .sub-menu > li > a:focus,
.page-with-light-sidebar .sidebar .sub-menu > li > a:hover,
.page-with-light-sidebar .sidebar .sub-menu > li.active > a,
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:focus,
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li.active > a {
  color: #333;
}
.flat-black .page-with-light-sidebar .sidebar .sub-menu,
.flat-black .page-with-light-sidebar .sidebar .nav > li.nav-profile,
.flat-black
  .page-with-light-sidebar.page-sidebar-minified
  .sidebar
  .nav
  li.has-sub
  > .sub-menu {
  background: #f5f5f5;
}
.page-with-light-sidebar .sidebar .sub-menu > li:before,
.page-with-light-sidebar .sidebar .sub-menu > li > a:after,
.page-with-light-sidebar .sidebar .sub-menu > li.has-sub > a:before {
  background: #e7e9ea;
}
.page-with-light-sidebar .sidebar .sub-menu > li:after {
  background: #fff;
}
.page-with-light-sidebar .sidebar .slimScrollBar {
  background: #000 !important;
}
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li:before,
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li > a:after,
.flat-black
  .page-with-light-sidebar
  .sidebar
  .sub-menu
  > li.has-sub
  > a:before {
  background: #eee;
}
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li:after {
  background: #fff;
}
.flat-black .page-with-light-sidebar .sidebar .slimScrollBar {
  background: #000 !important;
}

/* 3.3.6 Page Sidebar - Wide Style (Added in V1.7) */

.page-with-wide-sidebar .sidebar,
.page-with-wide-sidebar .sidebar + .sidebar-bg,
.page-with-wide-sidebar.page-sidebar-minified .sidebar.sidebar-right,
.page-with-wide-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right {
  width: 250px;
}
.page-with-wide-sidebar .content {
  margin-left: 250px;
}
.page-with-wide-sidebar.page-with-right-sidebar:not(.page-sidebar-minified)
  .content {
  margin-right: 250px;
  margin-left: 0;
}
.page-with-wide-sidebar.page-with-two-sidebar .content {
  margin-left: 250px;
  margin-right: 250px;
}
.page-with-wide-sidebar.page-sidebar-minified .sidebar,
.page-with-wide-sidebar.page-sidebar-minified .sidebar + .sidebar-bg {
  width: 60px;
}
.page-with-wide-sidebar.page-sidebar-minified:not(.page-with-right-sidebar)
  .content {
  margin-left: 60px;
}
.page-with-wide-sidebar .footer {
  margin-left: 280px;
}
.page-with-wide-sidebar.page-with-right-sidebar:not(.page-sidebar-minified)
  .footer {
  margin-left: 30px;
  margin-right: 280px;
}

/* 3.3.7 Page Sidebar - Transparent Style (Added in V1.9) */

.sidebar.sidebar-transparent {
  background: none;
}
.sidebar.sidebar-transparent .nav > li.nav-profile,
.sidebar.sidebar-transparent .nav > li.nav-profile.active {
  background: rgba(0, 0, 0, 0.25);
}
.sidebar.sidebar-transparent .nav > li.nav-profile a:hover,
.sidebar.sidebar-transparent .nav > li.nav-profile a:focus,
.sidebar.sidebar-transparent .nav > li.nav-profile .cover,
.sidebar.sidebar-transparent .nav > li.nav-profile .cover.with-shadow:before {
  background: none;
}
.sidebar.sidebar-transparent .sub-menu {
  background: none;
}
.page-sidebar-toggled.page-sidebar-minified
  .sidebar.sidebar-transparent
  + .sidebar-bg,
.sidebar.sidebar-transparent + .sidebar-bg {
  background: url(images/cover-sidebar.jpg);
  background-size: cover;
}
.sidebar.sidebar-transparent + .sidebar-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(45, 53, 60, 0.75);
}
.page-sidebar-minified
  .sidebar.sidebar-transparent
  .nav
  > li.has-sub
  .sub-menu {
  background: rgba(26, 34, 41, 0.85);
}
.sidebar.sidebar-transparent .nav > li.nav-header {
  color: #fff;
}
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:hover > a,
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:focus > a,
.sidebar.sidebar-transparent .nav > li.expand > a,
.sidebar.sidebar-transparent .nav > li > a:focus,
.sidebar.sidebar-transparent .nav > li > a:hover {
  background: none;
  color: #fff;
}
.sidebar.sidebar-transparent .has-sub.active > .sub-menu {
  overflow: hidden;
}
.sidebar.sidebar-transparent .nav > li.active > a,
.sidebar.sidebar-transparent .nav > li.active > a:hover,
.sidebar.sidebar-transparent .nav > li.active > a:focus {
  background: rgba(0, 0, 0, 0.3);
}
.sidebar.sidebar-transparent .sub-menu > li:before,
.sidebar.sidebar-transparent .sub-menu > li > a:after,
.sidebar.sidebar-transparent .sub-menu > li.has-sub > a:before {
  background: rgba(0, 0, 0, 0.3);
}

/* 3.3.8 Page Sidebar - Right Sidebar (Added in V4.0) */

.page-with-right-sidebar .sidebar {
  text-align: right;
}
.page-with-right-sidebar .sidebar .nav > li > a .icon-img,
.page-with-right-sidebar .sidebar .nav > li > a i {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu {
  padding-left: 0;
  padding-right: 39px;
}
.page-with-right-sidebar .sidebar .sub-menu > li:before {
  left: auto;
  right: -13px;
}
.page-with-right-sidebar .sidebar .sub-menu > li:after {
  left: auto;
  right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu > li > a {
  padding-left: 20px;
  padding-right: 15px;
}
.page-with-right-sidebar .sidebar .sub-menu > li > a:after {
  left: auto;
  right: -11px;
}
.page-with-right-sidebar .sidebar .nav > li.nav-profile .image {
  margin-left: auto;
  margin-right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu .sub-menu {
  padding-left: 0;
  padding-right: 15px;
}
.page-with-right-sidebar.page-sidebar-minified .sidebar li > a .caret {
  margin-left: 5px;
}
.page-with-right-sidebar.page-sidebar-minified .sidebar li > a .caret:before {
  transform: none !important;
}
.page-with-right-sidebar .float-sub-menu-line,
.page-with-right-sidebar .float-sub-menu-arrow,
.page-with-right-sidebar .float-sub-menu li:after {
  left: auto;
  right: 0;
}
.page-with-right-sidebar .sidebar li.active > a .caret:before,
.page-with-right-sidebar .sidebar li.expanding > a .caret:before,
.page-with-right-sidebar .sidebar li.expand > a .caret:before,
.page-with-right-sidebar .float-sub-menu li.active > a .caret:before,
.page-with-right-sidebar .float-sub-menu li.expanding > a .caret:before,
.page-with-right-sidebar .float-sub-menu li.expand > a .caret:before {
  transform: rotate(-90deg);
}
.page-with-right-sidebar .float-sub-menu {
  padding-left: 0;
  padding-right: 39px;
  text-align: right;
}
.page-with-right-sidebar .float-sub-menu li > a {
  padding-left: 15px;
  padding-right: 20px;
}
.page-with-right-sidebar .float-sub-menu li > a .caret,
.page-with-right-sidebar .sidebar li > a .caret {
  float: left;
  margin-left: -5px;
  margin-right: 2px;
}
.page-with-right-sidebar .float-sub-menu .sub-menu {
  padding-left: 0;
  padding-right: 15px;
}
.page-with-right-sidebar .float-sub-menu li > a .caret:before,
.page-with-right-sidebar .sidebar li > a .caret:before {
  content: "\f053";
}
.page-with-right-sidebar .float-sub-menu li:before {
  right: -13px;
  left: auto;
}
.page-with-right-sidebar .float-sub-menu li > a:after {
  right: -11px;
  left: auto;
}

/* 3.3.9 Page Sidebar - Floating Sub Menu (Added in V4.0) */

.float-sub-menu-container {
  position: fixed;
  width: 220px;
  margin: 0;
  background: #242a31;
  z-index: 1020;
}
.float-sub-menu {
  padding: 9px 0 9px 39px;
  margin: 0;
  list-style-type: none;
}
.float-sub-menu .sub-menu {
  display: none;
  list-style-type: none;
}
.float-sub-menu .active > .sub-menu {
  display: block;
}
.float-sub-menu li {
  position: relative;
}
.float-sub-menu li:first-child:before {
  top: -14px;
}
.float-sub-menu > li:first-child:before {
  top: 11px;
}
.float-sub-menu-arrow {
  position: absolute;
  top: 20px;
  left: 0;
  width: 28px;
  height: 2px;
  background: #2e353c;
}
.float-sub-menu-line {
  position: absolute;
  top: 20px;
  left: 26px;
  width: 2px;
  background: #2e353c;
}
.float-sub-menu .float-sub-menu-arrow:before,
.float-sub-menu .float-sub-menu-arrow:after {
  display: none !important;
}
.float-sub-menu li:before,
.float-sub-menu li > a:after,
.float-sub-menu li.has-sub > a:before {
  background: #2d353c;
}
.float-sub-menu li:before {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  bottom: 0;
  width: 2px;
}
.float-sub-menu li:last-child:before {
  bottom: auto;
  height: 13px;
}
.float-sub-menu > li:first-child:last-child:before {
  height: 0px !important;
}
.float-sub-menu li:first-child:last-child:before {
  height: 27px;
}
.float-sub-menu li:after {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  border: 1px solid #889097;
  top: 11px;
  border-radius: 4px;
  margin-top: -2px;
  z-index: 10;
  background: #242a31;
}
.float-sub-menu li.has-sub > a:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 11px;
  bottom: 11px;
  width: 2px;
}
.float-sub-menu li > a:after {
  content: "";
  position: absolute;
  left: -11px;
  top: 11px;
  width: 11px;
  height: 2px;
}
.float-sub-menu .sub-menu {
  padding: 0 0 0 15px;
  background: none;
}
.float-sub-menu li > a {
  line-height: 18px;
  padding: 3px 20px 3px 15px;
  display: block;
  color: #889097;
  text-decoration: none;
  position: relative;
}
.float-sub-menu li > a:hover,
.float-sub-menu li > a:focus,
.float-sub-menu li.active > a,
.float-sub-menu li.active > a:hover,
.float-sub-menu li.active > a:focus {
  color: #fff;
}
.float-sub-menu li.active:after {
  border-color: #00acac;
}
.float-sub-menu li > a .caret {
  margin-top: -1px;
  color: #a3a7ac;
}

/* 3.4 Page Content */

.content {
  margin-left: 220px;
  padding: 20px 30px;
}
.content.content-full-width {
  padding: 0;
}
.content.content-full-width .page-header {
  margin: 20px;
}
.content.content-full-width .breadcrumb {
  /* margin: 0 20px 0 0; */
}
.content.content-inverse-mode h1,
.content.content-inverse-mode h2,
.content.content-inverse-mode h3,
.content.content-inverse-mode h4,
.content.content-inverse-mode h5,
.content.content-inverse-mode h6,
.content.content-inverse-mode .page-header,
.content.content-inverse-mode .breadcrumb a {
  color: #fff;
}
.content.content-inverse-mode .breadcrumb > li,
.content.content-inverse-mode .page-header small {
  color: rgba(255, 255, 255, 0.75);
}
.content.content-full-width .page-header,
.content.content-full-width .breadcrumb {
  position: relative;
  z-index: 10;
}
.content.content-full-width .breadcrumb {
  z-index: 20;
}
.page-content-full-height .content {
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.page-header {
  font-size: 24px;
  margin: 0 0 15px;
  padding: 0;
  border: none;
  line-height: 32px;
  font-weight: 500;
}
.page-header small {
  line-height: 20px;
}
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
}
.breadcrumb > li {
  line-height: 32px;
}
.ie8 .breadcrumb > li {
  display: inline;
}
.breadcrumb > li a {
  color: #333;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #ccc;
}

.breadcrumb-item + .active {
  color: red;
}

/* 3.5 Page Footer */

.footer {
  z-index: 1020;
  padding: 10px 0;
  margin: 0 30px 0 250px;
  border-top: 1px solid #cad0d6;
  line-height: 20px;
}

/* 3.6 Page Top Menu (Added in V1.9) */

.page-with-top-menu {
  padding-top: 90px;
}
.page-with-top-menu .sidebar {
  padding-top: 90px;
}
.top-menu {
  background: #2d353c;
  height: 40px;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 1015;
}
.top-menu .nav {
  white-space: nowrap;
  display: block;
}
.top-menu .nav > li {
  display: inline-block;
  position: relative;
}
.top-menu .nav > li + li {
  margin-left: -3px;
}
.top-menu .nav > li > a {
  padding: 10px 20px;
  color: #a8acb1;
  display: block;
  line-height: 20px;
  text-decoration: none;
}
.top-menu li > a .caret {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 11px;
  border: none;
}
.top-menu li > a .caret:before {
  content: "\f054";
  display: block;
  text-align: center;
  transition: all 0.2s linear;
  opacity: 0.5;
  transform: rotate(90deg);
}
.top-menu .nav > li > a .badge,
.top-menu .nav > li > a .label {
  margin-left: 5px;
}
.top-menu .nav > li > a .badge {
  background: #1b1f24;
}
.top-menu .nav > li > a .icon-img {
  float: left;
  margin-right: 7px;
  width: 20px;
  height: 20px;
  margin-left: -3px;
  overflow: hidden;
  border-radius: 4px;
}
.top-menu .nav > li > a .icon-img img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.top-menu .nav > li > a .badge.pull-right,
.top-menu .nav > li > a .label.pull-right {
  margin-top: 1px;
}
.top-menu .nav > li.active > a .label.label-theme,
.top-menu .nav > li.active > a .badge {
  background: rgba(0, 0, 0, 0.4);
}
.top-menu .nav > li > a i {
  float: left;
  font-size: 14px;
  margin-right: 10px;
  line-height: 20px;
  width: 14px;
  text-align: center;
}
.top-menu .nav > li > a .caret.pull-right {
  margin-top: 8px;
  margin-left: 5px;
}
.top-menu .nav > li:hover > a,
.top-menu .nav > li:focus > a,
.top-menu .nav > li > a:hover,
.top-menu .nav > li > a:focus {
  background: none;
  color: #fff;
}

/* 3.6.1 Page Top Menu - Sub Menu (Added in V1.9) */

.top-menu .nav .sub-menu {
  display: none;
  position: absolute;
  left: 0;
  width: 220px;
  top: 100%;
  background: #1a2229;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
}
.top-menu .sub-menu > li > a {
  padding: 5px 20px;
  display: block;
  color: #8a8f94;
  text-decoration: none;
  position: relative;
}
.top-menu .nav .sub-menu > li > a .caret {
  margin-top: -1px;
}
.top-menu .sub-menu .sub-menu {
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  left: 0;
  top: 0;
}
.top-menu .sub-menu > li > a:hover,
.top-menu .sub-menu > li > a:focus,
.top-menu .sub-menu > li.active > a,
.top-menu .sub-menu > li.active > a:hover,
.top-menu .sub-menu > li.active > a:focus {
  color: #fff;
}
.top-menu .nav > li.has-sub:hover > .sub-menu {
  display: block;
}
.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:hover,
.top-menu .nav > li.active > a:focus {
  background: #242a31;
  color: #fff;
}

/* 3.6.2 Page Top Menu - Left & Right Control (Added in V1.9) */

.top-menu .nav > li.menu-control {
  position: absolute;
  top: 0;
  width: 40px;
  background: #2d353c;
  height: 40px;
  margin: 0;
}
.top-menu .nav > li.menu-control > a {
  padding: 0;
  text-align: center;
  line-height: 40px;
}
.top-menu .nav > li.menu-control > a i {
  float: none;
  margin: 0;
  font-size: 18px;
  display: block;
  line-height: 40px;
  color: #fff;
  width: auto;
}
.top-menu .nav > li.menu-control.menu-control-left {
  left: -50px;
}
.top-menu .nav > li.menu-control.menu-control-right {
  right: -50px;
}
@keyframes slideInLeft {
  from {
    left: -50px;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes slideInLeft {
  from {
    left: -50px;
  }
  to {
    left: 0;
  }
}
@keyframes slideInRight {
  from {
    right: -50px;
  }
  to {
    right: 0;
  }
}
@-webkit-keyframes slideInRight {
  from {
    right: -50px;
  }
  to {
    right: 0;
  }
}
.top-menu .nav > li.menu-control.menu-control-left.show {
  -webkit-animation: slideInLeft 0.2s;
  animation: slideInLeft 0.2s;
  left: 0;
}
.top-menu .nav > li.menu-control.menu-control-right.show {
  -webkit-animation: slideInRight 0.2s;
  animation: slideInRight 0.2s;
  right: 0;
}
.top-menu .nav > li.menu-control.menu-control-left > a {
  box-shadow: 5px 0 8px -3px rgba(0, 0, 0, 0.5);
}
.top-menu .nav > li.menu-control.menu-control-right > a {
  box-shadow: -5px 0 8px -3px rgba(0, 0, 0, 0.5);
}

/* -------------------------------
   4.0 Components
------------------------------- */

/* 4.1 Component - Form Elements */

.form-control {
  border: 1px solid #d3d8de;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.42857143;
  height: 34px;
  padding: 6px 12px;
}
.form-control:focus,
.form-control.focus,
.form-control.input-white:focus,
.form-control.input-white.focus {
  border-color: #5db0ff;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.form-control.form-control-with-bg,
.form-control-with-bg .form-control {
  background: #f2f3f4;
}
.form-control.input-lg,
.form-control.form-control-lg {
  font-size: 14px;
}
.form-control.input-xs {
  height: 20px;
}
.form-control.input-inline {
  display: inline;
  width: auto;
  padding: 0 7px;
}
.form-control.input-white {
  background: #fff;
  border-color: #fff;
}
.form-control.inverse-mode {
  background: url(images/transparent/white-0.2.png);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.form-control.inverse-mode {
  border-color: transparent;
}
.form-control.inverse-mode:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.5);
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: #e5e9ed;
  opacity: 0.6;
}
.form-control[disabled]:focus,
.form-control[readonly]:focus,
fieldset[disabled] .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccd0d4;
}
.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  opacity: 0.6;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  height: 30px;
  margin-bottom: 0;
  line-height: 1.42857143;
  padding: 6px 0;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
select.form-control:not([size]):not([multiple]) {
  height: 34px;
}
select[multiple],
select[size],
textarea.form-control {
  height: auto;
}
.col-form-label,
.row.form-group > .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}
/*
.form-control-lg,
.input-group-lg > .form-control, 
.input-group-lg > .input-group-append > .btn, 
.input-group-lg > .input-group-append > .input-group-text, 
.input-group-lg > .input-group-prepend > .btn, 
.input-group-lg > .input-group-prepend > .input-group-text,
select.form-control-lg:not([size]):not([multiple]) {
	height: 46px;
	padding: 10px 16px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.form-control-sm, 
.input-group-sm > .form-control, 
.input-group-sm > .input-group-append > .btn, 
.input-group-sm > .input-group-append > .input-group-text, 
.input-group-sm > .input-group-prepend > .btn, 
.input-group-sm > .input-group-prepend > .input-group-text,
select.form-control-sm:not([size]):not([multiple]) {
	height: 30px;
	padding: 5px 10px;
	line-height: 1.5;
	border-radius: 3px;
	font-size: 12px;
}

.input-group .form-control:last-child, 
.input-group-addon:first-child,
.input-group > .input-group-prepend > .btn, 
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), 
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child), 
.input-group > .input-group-append:not(:last-child) > .btn, 
.input-group > .input-group-append:not(:last-child) > .input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group-addon:last-child,
.input-group > .input-group-append > .btn, 
.input-group > .input-group-append > .input-group-text, 
.input-group > .input-group-prepend:not(:first-child) > .btn, 
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), 
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-lg > .form-control, 
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	font-size: 14px;
}


.input-group .form-control + .input-group-btn .btn:first-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}*/

.form-horizontal .control-label,
.form-horizontal .col-form-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: left;
}
.form-horizontal.form-horizontal-text-right .control-label,
.form-horizontal.form-horizontal-text-right .col-form-label {
  text-align: right;
}
.form-horizontal [class*="col-"] {
  position: initial;
}
.form-horizontal.form-bordered .form-group {
  border-bottom: 1px solid #eee;
  margin: 0;
}
.form-horizontal.form-bordered .form-group:last-child {
  border-bottom: 0;
}
.form-horizontal.form-bordered .form-group > .control-label,
.form-bordered .form-group > .col-form-label {
  padding: 22px 15px 15px;
}
.form-horizontal.form-bordered .form-group > div {
  padding: 15px;
}
.form-horizontal.form-bordered .form-group > div {
  border-left: 1px solid #eee;
}
.form-horizontal.form-bordered .form-group > .control-label {
  border-right: 1px solid #eee;
  margin-right: -1px;
}
.form-horizontal.form-bordered .has-feedback .form-control-feedback {
  top: 15px;
}
.form-check {
  padding-top: 7px;
}
.form-inline .form-check {
  padding-top: 0;
}
.form-check-input {
  margin-top: 2px;
}
.form-check-input:disabled ~ .form-check-label {
  color: #7b7f83;
}
.valid-feedback,
.invalid-feedback {
  font-size: 90%;
}
.valid-tooltip,
.invalid-tooltip {
  font-size: 12px;
  left: 0;
}
.valid-feedback,
.has-success .col-form-label,
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success .form-control-feedback,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label,
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #008a8a;
}
.valid-tooltip {
  background: rgba(0, 172, 172, 0.8);
}
.has-success .form-control,
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #00acac;
  box-shadow: none;
}
.has-success .form-control:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #008a8a;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(0, 172, 172, 0.3);
}
.has-success .input-group-addon {
  color: #025656;
  background-color: #b3e6e6;
  border-color: #00acac;
}
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label,
.has-warning .col-form-label,
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning .form-control-feedback {
  color: #c47d15;
}
.has-warning .form-control {
  border-color: #f59c1a;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #c47d15;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(245, 156, 26, 0.3);
}
.has-warning .input-group-addon {
  background-color: #fce1ba;
  color: #7b4e0e;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error .form-control-feedback,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.invalid-feedback {
  color: #cc4946;
}
.has-error .form-control,
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #702963;
  box-shadow: none;
}
.has-error .form-control:focus,
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #cc4946;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(204, 73, 70, 0.3);
}
.invalid-tooltip {
  background: rgba(255, 91, 87, 0.8);
}
.has-error .input-group-addon {
  background-color: #ffcdcc;
  color: #802d2b;
}
.form-control-feedback {
  line-height: 34px;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  text-align: center;
  border-radius: 4px;
}
.input-group-addon:focus {
  outline: none !important;
}
.input-group-addon .glyphicon {
  position: relative;
  top: 1px;
}
.input-group-addon,
.input-group-text {
  background-color: #d3d8df;
  border: none;
}
.input-group-text {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: 0;
}
legend {
  padding-bottom: 3px;
  border-bottom: 1px solid #e2e7eb;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

/* 4.2 Component - Dropdown Menu */

.dropdown-menu {
  border: none;
  box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}
.dropdown-menu > li > a,
.dropdown-item {
  display: block;
  padding: 5px 15px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #f2f3f4;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background: #348fe2;
  color: #fff;
}
.dropdown-menu .divider,
.dropdown-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu .divider {
  border-color: #e5e5e5;
}
.dropdown-menu.media-list {
  max-width: 360px;
  padding: 0;
}
.dropdown-menu.media-list p {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
  line-height: 16px;
  max-width: 270px;
  color: #585c61;
}
.dropdown-menu.media-list .dropdown-header {
  padding: 10px 15px !important;
  background: #f2f3f4;
  font-size: 10px;
  color: #585c61;
}
.dropdown-menu.media-list .dropdown-header + .media {
  margin-top: -10px;
}
.dropdown-menu.media-list > .media {
  margin-top: 0;
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  margin-bottom: -1px;
}
.dropdown-menu.media-list > .media > a {
  display: block;
  padding: 10px 15px !important;
  clear: both;
  white-space: nowrap;
}
.dropdown-menu.media-list > .media > a:hover,
.dropdown-menu.media-list > .media > a:focus {
  background: #f9f9f9;
}
.dropdown-menu.media-list > .media .media-left {
  padding-right: 10px;
}
.dropdown-menu.media-list > .media .media-right {
  padding-left: 10px;
}
.dropdown-menu.media-list > .media .media-object {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
}
.dropdown-menu.media-list > .media .media-object-icon {
  position: absolute;
  margin-left: 30px;
  border: 2px solid #fff;
  border-radius: 40px;
  background: #fff;
  margin-top: -11px;
  display: block;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
}
.dropdown-footer {
  padding: 10px 15px;
}
.dropdown-menu > li.dropdown-footer > a {
  padding: 0 !important;
  display: inline !important;
}
.dropdown-menu > li.dropdown-footer > a:hover,
.dropdown-menu > li.dropdown-footer > a:focus {
  background: none !important;
  text-decoration: underline !important;
}
.dropdown-toggle:after {
  vertical-align: 1px;
  border-width: 4px;
}
.dropdown-toggle.no-caret:after {
  display: none;
}

/* 4.3 Component - Tooltip */

.tooltip-inner {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 3px;
}

/* 4.4 Component - Alert */

.alert {
  border: none;
  padding: 15px;
}
.alert.alert-success {
  background: #b3e6e6;
  color: #025656;
}
.alert.alert-info {
  background: #c8e9f3;
  color: #255b6c;
}
.alert.alert-danger {
  background: #ffcdcc;
  color: #802d2b;
}
.alert.alert-warning {
  background: #fce1ba;
  color: #7b4e0e;
}
.alert.alert-primary {
  background: #c2ddf7;
  color: #1a4772;
}
.alert.alert-dark {
  background: #c0c2c4;
  color: #5b6165;
}
.alert.alert-secondary {
  background: #e9edef;
  color: #5b6165;
}
.alert.alert-light {
  background: #fff;
  color: #62686d;
}
.alert.alert-purple {
  background: #d4d7e9;
  color: #393e5b;
}
.alert.alert-yellow {
  background: #fff4b2;
  color: #806d00;
}
.alert.alert-lime {
  background: #ddefc9;
  color: #486626;
}
.alert.alert-indigo {
  background: #e1d4f7;
  color: #442a6f;
}
.alert.alert-pink {
  background: #fed4e5;
  color: #7e2b4c;
}
.alert.alert-green {
  background: #cce9cc;
  color: #195519;
}

.alert.alert-success .alert-link {
  color: #012b2b;
}
.alert.alert-info .alert-link {
  color: #122e36;
}
.alert.alert-danger .alert-link {
  color: #401716;
}
.alert.alert-warning .alert-link {
  color: #3d2707;
}
.alert.alert-primary .alert-link {
  color: #0d2439;
}
.alert.alert-dark .alert-link {
  color: #2e3132;
}
.alert.alert-secondary .alert-link {
  color: #2e3132;
}
.alert.alert-light .alert-link {
  color: #2e353c;
}
.alert.alert-purple .alert-link {
  color: #1d1f2e;
}
.alert.alert-yellow .alert-link {
  color: #403600;
}
.alert.alert-lime .alert-link {
  color: #243313;
}
.alert.alert-indigo .alert-link {
  color: #221538;
}
.alert.alert-pink .alert-link {
  color: #3f1526;
}
.alert.alert-green .alert-link {
  color: #0d2a0d;
}

/* 4.5 Component - Note Setting */

.note {
  margin-bottom: 20px;
  padding: 15px;
  border-left: 3px solid;
  position: relative;
  border-radius: 4px;
}
.panel > .note {
  border-radius: 0;
}
.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  color: inherit;
}
.note .note-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}
.note .note-icon > i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -28px;
}
.note .note-icon + .note-content {
  margin-left: 85px;
}
.note.note-with-right-icon {
  border-left: none;
  border-right: 3px solid;
}
.note.note-with-right-icon .note-icon {
  left: auto;
  right: 0;
}
.note.note-with-right-icon .note-content {
  margin-left: 0;
  margin-right: 85px;
}
.note.note-danger {
  border-color: #702963;
  background: #ffcdcc;
  color: #802d2b;
}
.note.note-danger .note-icon {
  background: #702963;
}
.note.note-warning {
  border-color: #f59c1a;
  background: #fce1ba;
  color: #7b4e0e;
}
.note.note-warning .note-icon {
  background: #f59c1a;
}
.note.note-yellow {
  border-color: #ffd900;
  background: #fff4b2;
  color: #806d00;
}
.note.note-yellow .note-icon {
  background: #ffd900;
}
.note.note-lime {
  border-color: #90ca4b;
  background: #ddefc9;
  color: #486626;
}
.note.note-lime .note-icon {
  background: #90ca4b;
}
.note.note-success {
  border-color: #00acac;
  background: #b3e6e6;
  color: #025656;
}
.note.note-success .note-icon {
  background: #00acac;
}
.note.note-primary {
  border-color: #348fe2;
  background: #c2ddf7;
  color: #1a4772;
}
.note.note-primary .note-icon {
  background: #348fe2;
}
.note.note-info {
  border-color: #49b6d6;
  background: #c8e9f3;
  color: #255b6c;
}
.note.note-info .note-icon {
  background: #49b6d6;
}
.note.note-purple {
  border-color: #727cb6;
  background: #d4d7e9;
  color: #393e5b;
}
.note.note-purple .note-icon {
  background: #727cb6;
}
.note.note-dark,
.note.note-inverse {
  border-color: #2d353c;
  background: #c0c2c4;
  color: #5b6165;
}
.note.note-dark .note-icon,
.note.note-inverse .note-icon {
  background: #2d353c;
}
.note.note-secondary,
.note.note-default {
  border-color: #b6c2c9;
  background: #e9edef;
  color: #5b6165;
}
.note.note-secondary .note-icon,
.note.note-default .note-icon {
  background: #b6c2c9;
}
.note.note-light {
  border-color: #f2f3f4;
  background: #fff;
  color: #62686d;
}
.note.note-light .note-icon {
  background: #f2f3f4;
}
.note.note-indigo {
  border-color: #8753de;
  background: #e1d4f7;
  color: #442a6f;
}
.note.note-indigo .note-icon {
  background: #8753de;
}
.note.note-pink {
  border-color: #fb5597;
  background: #fed4e5;
  color: #7e2b4c;
}
.note.note-pink .note-icon {
  background: #fb5597;
}
.note.note-green {
  border-color: #32a932;
  background: #cce9cc;
  color: #195519;
}
.note.note-green .note-icon {
  background: #32a932;
}

/* 4.6 Component - Badge & Label Setting */

.badge {
  font-size: 75%;
  line-height: 1.25;
  font-weight: 600;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.badge.badge-square {
  border-radius: 0;
}
.badge.badge-default,
.label.label-default {
  background: #f2f3f4;
  color: #2d353c;
}
.badge.badge-danger,
.label.label-danger {
  background: #702963;
}
.badge.badge-warning,
.label.label-warning {
  background: #f59c1a;
}
.badge.badge-success,
.label.label-success,
.badge.badge-teal,
.label.label-teal {
  background: #00acac;
}
.badge.badge-info,
.label.label-info {
  background: #49b6d6;
}
.badge.badge-primary,
.label.label-primary {
  background: #348fe2;
}
.badge.badge-secondary,
.label.label-secondary {
  background: #b6c2c9;
}
.badge.badge-lime,
.label.label-lime {
  background: #90ca4b;
}
.badge.badge-yellow,
.label.label-yellow {
  background: #ffd900;
  color: #000;
}
.badge.badge-purple,
.label.label-purple {
  background: #727cb6;
}
.badge.badge-light,
.label.label-light {
  background: #fff;
  color: #62686d;
}
.badge.badge-inverse,
.label.label-inverse,
.badge.badge-dark,
.label.label-dark {
  background: #2d353c;
}
.badge.badge-pink,
.label.label-pink {
  background: #fb5597;
}
.badge.badge-indigo,
.label.label-indigo {
  background: #8753de;
}
.badge.badge-green,
.label.label-green {
  background: #32a932;
}

/* 4.7 Component - Pagination & pager */

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
.pager:before,
.pager:after {
  content: "";
  display: table;
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  text-decoration: none;
}
.pager li > a,
.pager li > span,
.pagination > li > a {
  border-color: #dadfe2;
  color: #242a30;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager.pager-without-border li > a,
.pager.pager-without-border li > span,
.pagination.pagination-without-border > li > a {
  border-color: #fff;
}
.pager > .disabled > span,
.pager > .disabled > a,
.pager > .disabled > a:hover,
.pager > .disabled > a:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus,
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus {
  opacity: 0.6;
  border-color: #ddd;
  background: #fff;
  cursor: not-allowed;
}
.pagination > li > a {
  display: inline-block;
  color: #242a30;
  margin-left: 5px;
  padding: 6px 12px;
  line-height: 1.42857143;
  border: 1px solid #ddd;
  text-decoration: none;
  border-radius: 6px !important;
}
.pagination > li:first-child > a {
  margin-left: 0;
}
.pagination-sm > li > a,
.pagination-sm > li > span,
.pagination-sm .page-link {
  font-size: 10px;
  margin-left: 4px;
  padding: 5px 10px;
  line-height: 1.5;
}
.pagination-lg > li > a,
.pagination-lg > li > span,
.pagination-lg .page-link {
  font-size: 14px;
  margin-left: 6px;
  padding: 10px 16px;
  line-height: 1.3333333;
}
.pager li > a:hover,
.pager li > a:focus,
.pager li > span:hover,
.pager li > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus {
  color: #242a30;
  background: #f2f3f4;
  border-color: #dadfe2;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #348ee3 !important;
  border-color: #348ee3 !important;
  color: #fff;
}

/* 4.8 Component - Progress bar */

.progress {
  box-shadow: none;
  background: #e2e7eb;
}
.progress-bar {
  background-color: #348fe2;
  box-shadow: none;
}
.progress-xs {
  height: 5px;
}
.progress-sm {
  height: 10px;
}
.progress-lg {
  height: 30px;
}
.progress-xs .progress-bar {
  line-height: 5px;
}
.progress-sm .progress-bar {
  line-height: 10px;
}
.progress-lg .progress-bar {
  line-height: 30px;
}

/* 4.9 Component - Nav Setting */

.nav > li > a {
  color: #6e7179;
}
.nav > li > a:hover,
.nav > li > a:focus {
  color: #333;
  background: #fafafa;
}

/* 4.10 Component - Nav Tabs */

.nav-tabs {
  background: #c1ccd1;
  border-radius: 5px 5px 0 0;
}
.nav-tabs-inverse > .nav-tabs,
.nav-tabs.nav-tabs-inverse {
  background: #242a30;
}
.nav-tabs .nav-link {
  padding: 10px 15px;
}
.nav-tabs > li > a {
  margin-right: 5px;
  line-height: 20px;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 3px 3px 0 0;
}
.nav-tabs,
.nav-tabs > li > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: none !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  color: #242a30;
}
.nav-tabs.nav-tabs-inverse > li.active > a,
.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav-tabs.nav-tabs-inverse > li.active > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link.active,
.nav-tabs.nav-tabs-inverse .nav-link.active:hover,
.nav-tabs.nav-tabs-inverse .nav-link.active:focus,
.nav-tabs.nav-tabs-inverse .nav-item.show .nav-link {
  color: #242a30;
  background: #fff;
}
.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav-tabs.nav-tabs-inverse > li > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link:hover,
.nav-tabs.nav-tabs-inverse .nav-link:focus {
  color: #fff;
  background: none;
}
.nav-tabs .nav-item,
.nav-tabs > li,
.nav-tabs.nav-justified > li {
  margin-bottom: 0;
}

/* 4.11 Component - Nav Pills */

.nav-pills {
  margin-bottom: 10px;
}
.nav-pills > li > a.active,
.nav-pills > li > a.active:focus,
.nav-pills > li > a.active:hover {
  background: #242a30;
  color: #fff;
}
.nav-pills > li > a,
.nav-pills .nav-link {
  padding: 10px 15px;
  border-radius: 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background: #242a30;
  color: #fff;
}
.nav.nav-pills.nav-sm > li {
  margin: 0 0 3px;
}
.nav.nav-pills.nav-sm > li a {
  padding: 8px 10px;
  line-height: 1.5;
}
.nav-pills > li + li {
  margin-left: 5px;
}
.nav-stacked > li + li {
  margin-left: 0;
  margin-top: 5px;
}

/* 4.12 Component - Tab Content */

.tab-content {
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 3px;
}
.nav-tabs + .tab-content {
  border-radius: 0 0 3px 3px;
}

/* 4.13 Component - Accordion Panel */

.panel-title a {
  display: block;
}
.panel-title > a:hover,
.panel-title > a:focus {
  text-decoration: none;
}

/* 4.14 Component - Button */

.btn {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px;
  transition: all 0.1s ease-in-out;
  outline: none !important;
  box-shadow: none !important;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
}
.btn:active,
.btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
.btn.btn-sm {
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px;
}
.btn.btn-xs {
  font-size: 12px;
  line-height: 18px;
  padding: 1px 5px;
}
.btn[class*="btn-gradient-"] {
  transition: background 0.1s linear;
}
.btn.btn-lg,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-prepend > .btn {
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;
}
.btn-icon,
.btn.btn-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
}
.btn-circle,
.btn.btn-circle {
  border-radius: 100px;
}
.btn-icon.btn-xs {
  width: 16px;
  height: 16px;
  font-size: 8px;
  line-height: 16px;
}
.btn-icon.btn-sm {
  width: 22px;
  height: 22px;
  font-size: 11px;
  line-height: 22px;
}
.btn.btn-icon.btn-lg {
  width: 34px;
  height: 34px;
  font-size: 17px;
  line-height: 34px;
}
.btn-scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 1020;
}
.page-with-right-sidebar .btn-scroll-to-top {
  left: 25px;
  right: auto;
}
.btn > .pull-left,
.btn > .pull-right {
  line-height: 1.428571429;
}
.input-group-prepend .btn {
  border-right: 1px solid;
}
.input-group-append .btn {
  border-left: 1px solid;
}
.btn-rounded {
  border-radius: 200px;
}

/* 4.14.1 Component - Button - Default */

.btn.btn-default,
.btn.btn-default.disabled,
.btn.btn-default.disabled:hover,
.btn.btn-default.disabled:focus,
.btn.btn-default[disabled],
.btn.btn-default[disabled]:hover,
.btn.btn-default[disabled]:focus {
  color: #2e353c;
  background: #f2f3f4;
  border-color: #f2f3f4;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.show > .btn-default.dropdown-toggle,
.open > .dropdown-toggle.btn-default,
.open > .dropdown-toggle.btn-default:hover,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active {
  background: #c5ced4;
  border-color: #c5ced4;
}
.input-group-append .btn.btn-default,
.btn-group .btn.btn-default:not(.active) + .btn.btn-default,
.input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
  border-left-color: #c5ced4;
}
.input-group-prepend .btn.btn-default {
  border-right-color: #c5ced4;
}

/* 4.14.2 Component - Button - White */

.btn.btn-white,
.btn.btn-white.disabled,
.btn.btn-white.disabled:hover,
.btn.btn-white.disabled:focus,
.btn.btn-white[disabled],
.btn.btn-white[disabled]:hover,
.btn.btn-white[disabled]:focus {
  color: #2d353c;
  background: #fff;
  border-color: #d9dfe3;
}
.btn.btn-white.btn-white-without-border {
  border-color: #fff;
}
.btn.btn-white.btn-white-without-border.active,
.btn.btn-white.btn-white-without-border.active:hover,
.btn.btn-white.btn-white-without-border.active:focus {
  border-color: #d9dfe3;
}
.btn.btn-white.btn-white-without-border:hover,
.btn.btn-white.btn-white-without-border:focus {
  border-color: #d9dfe3;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.active.focus,
.btn-white.active:focus,
.btn-white.active:hover,
.btn-white:active.focus,
.btn-white:active:focus,
.btn-white:active:hover,
.show > .btn-white.dropdown-toggle,
.open > .dropdown-toggle.btn-white,
.open > .dropdown-toggle.btn-white:hover,
.open > .dropdown-toggle.btn-white:focus,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active {
  background: #e2e7eb;
  border-color: #d9dfe3;
}
.input-group-append .btn.btn-white,
.btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
  border-left-color: #d9dfe3;
}
.input-group-prepend .btn.btn-white {
  border-right-color: #d9dfe3;
}

/* 4.14.3 Component - Button - Inverse */

.btn.btn-inverse,
.btn.btn-inverse.disabled,
.btn.btn-inverse.disabled:hover,
.btn.btn-inverse.disabled:focus,
.btn.btn-inverse[disabled],
.btn.btn-inverse[disabled]:hover,
.btn.btn-inverse[disabled]:focus {
  color: #fff;
  background: #2d353c;
  border-color: #2d353c;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.show > .btn-inverse.dropdown-toggle,
.open > .dropdown-toggle.btn-inverse,
.open > .dropdown-toggle.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:not(:disabled):not(.disabled).active,
.btn-inverse:not(:disabled):not(.disabled):active {
  background: #242a30;
  border-color: #242a30;
}
.input-group-append .btn.btn-inverse,
.btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse,
.input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
  border-left-color: #242a30;
}
.input-group-prepend .btn {
  border-right-color: #242a30;
}

/* 4.14.4 Component - Button - Primary */

.btn.btn-primary,
.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover,
.btn.btn-primary.disabled:focus,
.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover,
.btn.btn-primary[disabled]:focus {
  color: #fff;
  background: #348fe2;
  border-color: #348fe2;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.show > .btn-primary.dropdown-toggle,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary:hover,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: #2a72b5;
  border-color: #2a72b5;
}
.input-group-append .btn.btn-primary,
.btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
  border-left-color: #2a72b5;
}
.input-group-prepend .btn.btn-primary {
  border-right-color: #2a72b5;
}

/* 4.14.5 Component - Button - Success */

.btn.btn-success,
.btn.btn-success.disabled,
.btn.btn-success.disabled:hover,
.btn.btn-success.disabled:focus,
.btn.btn-success[disabled],
.btn.btn-success[disabled]:hover,
.btn.btn-success[disabled]:focus {
  color: #fff;
  background: #00acac;
  border-color: #00acac;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.show > .btn-success.dropdown-toggle,
.open > .dropdown-toggle.btn-success,
.open > .dropdown-toggle.btn-success:hover,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
  background: #008a8a;
  border-color: #008a8a;
}
.input-group-append .btn.btn-success,
.btn-group .btn.btn-success:not(.active) + .btn.btn-success,
.input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
  border-left-color: #008a8a;
}
.input-group-prepend .btn.btn-success {
  border-right-color: #008a8a;
}

/* 4.14.6 Component - Button - Warning */

.btn.btn-warning,
.btn.btn-warning.disabled,
.btn.btn-warning.disabled:hover,
.btn.btn-warning.disabled:focus,
.btn.btn-warning[disabled],
.btn.btn-warning[disabled]:hover,
.btn.btn-warning[disabled]:focus {
  color: #fff;
  background: #f59c1a;
  border-color: #f59c1a;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.show > .btn-warning.dropdown-toggle,
.open > .dropdown-toggle.btn-warning,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
  background: #c47d15;
  border-color: #c47d15;
}
.input-group-append .btn.btn-warning,
.btn-group .btn.btn-warning:not(.active) + .btn.btn-warning,
.input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
  border-left-color: #c47d15;
}
.input-group-prepend .btn.btn-warning {
  border-right-color: #c47d15;
}

/* 4.14.7 Component - Button - Danger */

.btn.btn-danger,
.btn.btn-danger.disabled,
.btn.btn-danger.disabled:hover,
.btn.btn-danger.disabled:focus,
.btn.btn-danger[disabled],
.btn.btn-danger[disabled]:hover,
.btn.btn-danger[disabled]:focus {
  color: #fff;
  background: #702963;
  border-color: #702963;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.show > .btn-danger.dropdown-toggle,
.open > .dropdown-toggle.btn-danger,
.open > .dropdown-toggle.btn-danger:hover,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
  background: #cc4946;
  border-color: #cc4946;
}
.input-group-append .btn.btn-danger,
.btn-group .btn.btn-danger:not(.active) + .btn.btn-danger,
.input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
  border-left-color: #cc4946;
}
.input-group-prepend .btn.btn-danger {
  border-right-color: #cc4946;
}

/* 4.14.8 Component - Button - Info */

.btn.btn-info,
.btn.btn-info.disabled,
.btn.btn-info.disabled:hover,
.btn.btn-info.disabled:focus,
.btn.btn-info[disabled],
.btn.btn-info[disabled]:hover,
.btn.btn-info[disabled]:focus {
  color: #fff;
  background: #49b6d6;
  border-color: #49b6d6;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.show > .btn-info.dropdown-toggle,
.open > .dropdown-toggle.btn-info,
.open > .dropdown-toggle.btn-info:hover,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active {
  background: #3a92ab;
  border-color: #3a92ab;
}
.input-group-append .btn.btn-info,
.btn-group .btn.btn-info:not(.active) + .btn.btn-info,
.input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
  border-left-color: #3a92ab;
}
.input-group-prepend .btn.btn-info {
  border-right-color: #3a92ab;
}

/* 4.14.9 Component - Button - Purple */

.btn.btn-purple,
.btn.btn-purple.disabled,
.btn.btn-purple.disabled:hover,
.btn.btn-purple.disabled:focus,
.btn.btn-purple[disabled],
.btn.btn-purple[disabled]:hover,
.btn.btn-purple[disabled]:focus {
  color: #fff;
  background: #727cb6;
  border-color: #727cb6;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.btn-purple.active.focus,
.btn-purple.active:focus,
.btn-purple.active:hover,
.btn-purple:active.focus,
.btn-purple:active:focus,
.btn-purple:active:hover,
.show > .btn-purple.dropdown-toggle,
.open > .dropdown-toggle.btn-purple,
.open > .dropdown-toggle.btn-purple:hover,
.open > .dropdown-toggle.btn-purple:focus,
.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active {
  background: #555d88;
  border-color: #555d88;
}
.input-group-append .btn.btn-purple,
.btn-group .btn.btn-purple:not(.active) + .btn.btn-purple,
.input-group-btn .btn.btn-purple:not(.active) + .btn.btn-purple {
  border-left-color: #555d88;
}
.input-group-prepend .btn.btn-purple {
  border-right-color: #555d88;
}

/* 4.14.10 Component - Button - Yellow */

.btn.btn-yellow,
.btn.btn-yellow.disabled,
.btn.btn-yellow.disabled:hover,
.btn.btn-yellow.disabled:focus,
.btn.btn-yellow[disabled],
.btn.btn-yellow[disabled]:hover,
.btn.btn-yellow[disabled]:focus {
  color: #000;
  background: #ffd900;
  border-color: #ffd900;
}
.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active,
.btn-yellow.active.focus,
.btn-yellow.active:focus,
.btn-yellow.active:hover,
.btn-yellow:active.focus,
.btn-yellow:active:focus,
.btn-yellow:active:hover,
.show > .btn-yellow.dropdown-toggle,
.open > .dropdown-toggle.btn-yellow,
.open > .dropdown-toggle.btn-yellow:hover,
.open > .dropdown-toggle.btn-yellow:focus,
.btn-yellow:not(:disabled):not(.disabled).active,
.btn-yellow:not(:disabled):not(.disabled):active {
  background: #bfa300;
  border-color: #bfa300;
}
.input-group-append .btn.btn-yellow,
.btn-group .btn.btn-yellow:not(.active) + .btn.btn-yellow,
.input-group-btn .btn.btn-yellow:not(.active) + .btn.btn-yellow {
  border-left-color: #bfa300;
}
.input-group-prepend .btn.btn-yellow {
  border-right-color: #bfa300;
}

/* 4.14.11 Component - Button - Grey */

.btn.btn-grey,
.btn.btn-grey.disabled,
.btn.btn-grey.disabled:hover,
.btn.btn-grey.disabled:focus,
.btn.btn-grey[disabled],
.btn.btn-grey[disabled]:hover,
.btn.btn-grey[disabled]:focus {
  color: #fff;
  background: #b6c2c9;
  border-color: #b6c2c9;
}
.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active,
.btn-grey.active,
.btn-grey.active.focus,
.btn-grey.active:focus,
.btn-grey.active:hover,
.btn-grey:active.focus,
.btn-grey:active:focus,
.btn-grey:active:hover,
.show > .btn-grey.dropdown-toggle,
.open > .dropdown-toggle.btn-grey,
.open > .dropdown-toggle.btn-grey:hover,
.open > .dropdown-toggle.btn-grey:focus,
.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active {
  background: #889197;
  border-color: #889197;
}
.input-group-append .btn.btn-grey,
.btn-group .btn.btn-grey:not(.active) + .btn.btn-grey,
.input-group-btn .btn.btn-grey:not(.active) + .btn.btn-grey {
  border-left-color: #889197;
}
.input-group-prepend .btn.btn-grey {
  border-right-color: #889197;
}

/* 4.14.12 Component - Button - Lime */

.btn.btn-lime,
.btn.btn-lime.disabled,
.btn.btn-lime.disabled:hover,
.btn.btn-lime.disabled:focus,
.btn.btn-lime[disabled],
.btn.btn-lime[disabled]:hover,
.btn.btn-lime[disabled]:focus {
  color: #fff;
  background: #90ca4b;
  border-color: #90ca4b;
}
.btn-lime:hover,
.btn-lime:focus,
.btn-lime:active,
.btn-lime.active,
.btn-lime.active.focus,
.btn-lime.active:focus,
.btn-lime.active:hover,
.btn-lime:active.focus,
.btn-lime:active:focus,
.btn-lime:active:hover,
.show > .btn-lime.dropdown-toggle,
.open > .dropdown-toggle.btn-lime,
.open > .dropdown-toggle.btn-lime:hover,
.open > .dropdown-toggle.btn-lime:focus,
.btn-lime:not(:disabled):not(.disabled).active,
.btn-lime:not(:disabled):not(.disabled):active {
  background: #6c9738;
  border-color: #6c9738;
}
.input-group-append .btn.btn-lime,
.btn-group .btn.btn-lime:not(.active) + .btn.btn-lime,
.input-group-btn .btn.btn-lime:not(.active) + .btn.btn-lime {
  border-left-color: #6c9738;
}
.input-group-prepend .btn.btn-lime {
  border-right-color: #6c9738;
}

/* 4.14.13 Component - Button - Pink */

.btn.btn-pink,
.btn.btn-pink.disabled,
.btn.btn-pink.disabled:hover,
.btn.btn-pink.disabled:focus,
.btn.btn-pink[disabled],
.btn.btn-pink[disabled]:hover,
.btn.btn-pink[disabled]:focus {
  color: #fff;
  background: #fb5597;
  border-color: #fb5597;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.active.focus,
.btn-pink.active:focus,
.btn-pink.active:hover,
.btn-pink:active.focus,
.btn-pink:active:focus,
.btn-pink:active:hover,
.show > .btn-pink.dropdown-toggle,
.open > .dropdown-toggle.btn-pink,
.open > .dropdown-toggle.btn-pink:hover,
.open > .dropdown-toggle.btn-pink:focus,
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active {
  background: #bc4071;
  border-color: #bc4071;
}
.input-group-append .btn.btn-pink,
.btn-group .btn.btn-pink:not(.active) + .btn.btn-pink,
.input-group-btn .btn.btn-pink:not(.active) + .btn.btn-pink {
  border-left-color: #bc4071;
}
.input-group-prepend .btn.btn-pink {
  border-right-color: #bc4071;
}

/* 4.14.14 Component - Button - Indigo */

.btn.btn-indigo,
.btn.btn-indigo.disabled,
.btn.btn-indigo.disabled:hover,
.btn.btn-indigo.disabled:focus,
.btn.btn-indigo[disabled],
.btn.btn-indigo[disabled]:hover,
.btn.btn-indigo[disabled]:focus {
  color: #fff;
  background: #8753de;
  border-color: #8753de;
}
.btn-indigo:hover,
.btn-indigo:focus,
.btn-indigo:active,
.btn-indigo.active,
.btn-indigo.active.focus,
.btn-indigo.active:focus,
.btn-indigo.active:hover,
.btn-indigo:active.focus,
.btn-indigo:active:focus,
.btn-indigo:active:hover,
.show > .btn-indigo.dropdown-toggle,
.open > .dropdown-toggle.btn-indigo,
.open > .dropdown-toggle.btn-indigo:hover,
.open > .dropdown-toggle.btn-indigo:focus,
.btn-indigo:not(:disabled):not(.disabled).active,
.btn-indigo:not(:disabled):not(.disabled):active {
  background: #653ea6;
  border-color: #653ea6;
}
.input-group-append .btn.btn-indigo,
.btn-group .btn.btn-indigo:not(.active) + .btn.btn-indigo,
.input-group-btn .btn.btn-indigo:not(.active) + .btn.btn-indigo {
  border-left-color: #653ea6;
}
.input-group-prepend .btn.btn-indigo {
  border-right-color: #653ea6;
}

/* 4.14.15 Component - Button - Green */

.btn.btn-green,
.btn.btn-green.disabled,
.btn.btn-green.disabled:hover,
.btn.btn-green.disabled:focus,
.btn.btn-green[disabled],
.btn.btn-green[disabled]:hover,
.btn.btn-green[disabled]:focus {
  color: #fff;
  background: #32a932;
  border-color: #32a932;
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active,
.btn-green.active.focus,
.btn-green.active:focus,
.btn-green.active:hover,
.btn-green:active.focus,
.btn-green:active:focus,
.btn-green:active:hover,
.show > .btn-green.dropdown-toggle,
.open > .dropdown-toggle.btn-green,
.open > .dropdown-toggle.btn-green:hover,
.open > .dropdown-toggle.btn-green:focus,
.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):active {
  background: #257f25;
  border-color: #257f25;
}
.input-group-append .btn.btn-green,
.btn-group .btn.btn-green:not(.active) + .btn.btn-green,
.input-group-btn .btn.btn-green:not(.active) + .btn.btn-green {
  border-left-color: #257f25;
}
.input-group-prepend .btn.btn-green {
  border-right-color: #257f25;
}

/* 4.15 Component - Panel */

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 3px;
}
.panel .tab-content {
  border-radius: 0 0 3px 3px;
}
.panel > .table,
.panel > .tab-content {
  margin-bottom: 0;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-heading {
  padding: 10px 15px;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-heading + .table,
.panel-heading + .slimScrollDiv {
  border-top: 1px solid #eee;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > small > a,
.panel-title > .small,
.panel-title > .small > a {
  color: inherit;
}
.panel-heading-btn {
  float: right;
}
.panel-heading-btn > a {
  margin-left: 8px;
}
.panel-heading .btn-group .btn {
  margin-top: -7px;
}
.panel-heading .btn-group .btn.btn-sm {
  margin-top: -5px;
}
.panel-heading .btn-group .btn.btn-xs {
  margin-top: -1px;
}
.panel-heading .label.pull-left,
.panel-heading .label.pull-right {
  line-height: 15px;
}
.panel-heading .progress.pull-left,
.panel-heading .progress.pull-right {
  width: 40%;
  min-width: 120px;
}
.panel-heading + .alert {
  margin-bottom: 0;
  border-radius: 0;
}
.panel-heading .nav-tabs {
  margin-top: -10px;
  margin-right: -15px;
}
.panel-heading .nav-tabs > li > a {
  padding: 10px 15px;
  line-height: 20px;
}
.panel-with-tabs.panel-default .panel-heading {
  background: #c1ccd1;
  color: #333;
}
.panel-title {
  line-height: 20px;
  font-size: 12px;
}
.panel-title .pull-right {
  line-height: 20px;
}
.panel-title .accordion-toggle {
  margin: -10px -15px;
  padding: 10px 15px;
}
.panel-title .accordion-toggle.accordion-toggle-styled .fa:before {
  content: "\f056";
}
.panel-title .accordion-toggle.accordion-toggle-styled.collapsed .fa:before {
  content: "\f055";
}
.panel-toolbar {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
  background: #fff;
}
.panel-toolbar + .form-control {
  margin: -1px 0 0;
  border-right: none;
  border-left: none;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 3px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-body {
  padding: 15px;
}
.panel-body:before,
.panel-body:after {
  content: "";
  display: table;
  clear: both;
}
.panel-body[class^="bg-"] {
  border-radius: 0 0 3px 3px;
}
.panel-body.no-border {
  border: none !important;
}
.panel-body.panel-form,
.panel-body.no-padding,
.panel-body.panel-table,
.panel-body.panel-full-width {
  padding: 0 !important;
}
.panel-body.with-table > .table {
  border: 0;
  margin: 0;
}
.panel-body.with-table > .table tr:last-child th,
.panel-body.with-table > .table tr:last-child td {
  border-bottom: 0;
}
.panel-footer {
  background: #fff;
  border-top: 1px solid #eee;
  padding: 10px 15px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.form-control + .panel-footer {
  border-top: none;
}
.panel-default > .panel-heading {
  background: #fafafa;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eee;
}
.panel-info > .panel-heading,
.panel-danger > .panel-heading,
.panel-inverse > .panel-heading,
.panel-success > .panel-heading,
.panel-warning > .panel-heading,
.panel-primary > .panel-heading {
  color: #fff;
}
.panel-inverse > .panel-heading {
  background: #242a30;
}
.panel-success > .panel-heading {
  background: #008a8a;
}
.panel-warning > .panel-heading {
  background: #c47d15;
}
.panel-danger > .panel-heading {
  background: #cc4946;
}
.panel-primary > .panel-heading {
  background: #2a72b5;
}
.panel-info > .panel-heading {
  background: #3a92ab;
}

.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0;
}
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .panel-collapse > .table,
.panel > .table,
.panel > .table-responsive > .table {
  margin-bottom: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel.panel-no-rounded-corner .panel-body,
.panel.panel-no-rounded-corner .panel-footer,
.panel.panel-no-rounded-corner .panel-heading {
  border-radius: 0 !important;
}
.panel.panel-hover-icon .panel-heading .panel-heading-btn .btn i {
  visibility: hidden;
}
.panel.panel-hover-icon .panel-heading:hover .panel-heading-btn .btn i,
.panel.panel-hover-icon .panel-heading:focus .panel-heading-btn .btn i {
  visibility: visible;
}

/* 4.16 Component - Panel - Panel Expand */

.panel.panel-expand {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  overflow-x: scroll;
  z-index: 1080;
}
.panel-expand .header,
.panel-expand .sidebar,
.panel-expand .sidebar-bg {
  z-index: 0;
}
.panel.panel-expand,
.panel.panel-expand > .panel-body,
.panel.panel-expand > .panel-heading {
  border-radius: 0;
}
.panel-expand .height-xs,
.panel-expand .height-sm,
.panel-expand .height-md,
.panel-expand .height-lg,
.panel-expand .height-full {
  height: 100% !important;
}
.panel.panel-expand > .panel-heading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
}
.panel.panel-expand > .panel-heading + div {
  margin-top: 40px;
}
.panel.panel-expand > .panel-heading .fa.fa-expand:before {
  content: "\f066";
}
.panel.panel-expand > .panel-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.panel-expand .content .row [class^="col-"] {
  position: inherit;
}
.panel-expand .content .panel.panel-expand .row > [class^="col-"] {
  position: relative;
}
.panel-expand .vertical-box .vertical-box-column {
  display: table-cell;
}
.panel-expand .page-content-full-height .content {
  -webkit-transform: none;
  transform: none;
}

/* 4.17 Component - Panel - Panel loading */

.panel.panel-loading .panel-body {
  position: relative;
  z-index: 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.panel.panel-loading .panel-body .panel-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.9;
  -webkit-animation: fadeIn 0.2s;
  animation: fadeIn 0.2s;
  z-index: 1020;
  border-radius: 0 0 4px 4px;
}

/* 4.18 Component - Modal Setting */

.modal-content {
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.modal-header {
  padding: 12px 15px;
  border-bottom-color: #e2e7eb;
}
.modal-header .close {
  margin: -12px -15px -12px 0;
  padding: 12px 15px;
  line-height: 27px;
}
.modal-body {
  padding: 15px;
}
.modal-footer {
  border-top-color: #e2e7eb;
  padding: 14px 15px 15px;
}
.modal-message .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.modal-message .modal-content {
  border-radius: 0;
}
.modal-message .modal-body,
.modal-message .modal-header,
.modal-message .modal-footer {
  width: 60%;
  border: none;
  margin: 0 auto;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.modal-backdrop.fade.in {
  opacity: 0.5;
}

/* 4.19 Component - Media Object */

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}
.media + .media {
  margin-top: 15px;
}
.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.media-list {
  padding-left: 0;
  list-style: none;
}
.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
.media-body {
  width: 10000px;
}
.media .media-object {
  width: 128px;
}
.media.media-lg .media-object {
  width: 256px;
}
.media.media-sm .media-object {
  width: 64px;
}
.media.media-xs .media-object {
  width: 32px;
}
.media > .pull-left,
.media > .media-left {
  padding-right: 15px;
}
.media > .pull-right,
.media > .media-right {
  padding-left: 15px;
}
.media a:not(.btn):hover,
.media a:not(.btn):focus,
.media a:not(.btn):hover .media-heading,
.media a:not(.btn):focus .media-heading,
.media a:not(.btn).media-heading:hover,
.media a:not(.btn).media-heading:focus {
  color: #242a30;
  text-decoration: none;
}
.media-list.media-list-with-divider > li + li {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

/* 4.20 Component - Table */

.table {
  border-color: #e2e7eb;
  border-radius: 3px;
  background: none;
}
.table.table-inverse {
  background: none;
}
.table thead th,
.table > thead > tr > th {
  color: #242a30;
  font-weight: 600;
  border-bottom: 1px solid #b6c2c9 !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: #e2e7eb;
  padding: 10px 15px;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 7px 15px;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background: #e2e7ec !important;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background: #f2f3f4;
}
.table.table-inverse {
  color: rgba(255, 255, 255, 0.75);
}
.table.table-inverse > thead > tr > th,
.table.table-inverse > tbody > tr > th,
.table.table-inverse > tfoot > tr > th {
  color: #fff;
}
.flat-black .table.table-inverse > thead > tr > th,
.table.table-inverse > thead > tr > th,
.table.table-inverse > tbody > tr > th,
.table.table-inverse > tfoot > tr > th,
.table.table-inverse > thead > tr > td,
.table.table-inverse > tbody > tr > td,
.table.table-inverse > tfoot > tr > td {
  border-color: #444 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  background: none !important;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background: #c8e9f3;
  border-color: #a1c5d0;
  color: #255b6c;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background: #b3e6e6;
  border-color: #8fbdbd;
  color: #025656;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background: #ffcdcc;
  border-color: #d8abaa;
  color: #802d2b;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background: #fce1ba;
  border-color: #d2b894;
  color: #7b4e0e;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background: #e9edef;
  border-color: #e9edef;
  color: #5b6165;
}
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.table.table-bordered > thead:first-child > tr:first-child > td,
.table.table-bordered > thead:first-child > tr:first-child > th {
  border-top: 1px solid #e2e7eb;
}
.table .with-form-control .form-control,
.table .with-input-group .input-group {
  margin-top: -8px;
  margin-bottom: -8px;
}
.table-condensed .with-form-control .form-control,
.table-condensed .with-input-group .input-group {
  margin-top: -6px;
  margin-bottom: -5px;
}
.table .with-img img,
.table .with-btn .btn,
.table .with-btn-group .btn-group {
  margin-top: -8px;
  margin-bottom: -7px;
}
.table .with-checkbox .checkbox,
.table .with-radio .radio {
  margin: 0;
  padding: 0;
}
.table .with-checkbox .checkbox-css label,
.table .with-radio .radio-css label {
  min-height: inherit;
}
.table .with-checkbox .checkbox-css input + label:before,
.table .with-checkbox .checkbox-css input:checked + label:after,
.table .with-radio .radio-css input + label:before {
  top: 0;
}
.table .with-radio .radio-css input:checked + label:after {
  top: 5px;
}

/* 4.21 Component - Well */

.well {
  padding: 15px;
  background: #fff;
  box-shadow: none;
}
.well-sm {
  padding: 10px;
}
.well-lg {
  padding: 30px;
}

/* 4.22 Component - Jumbotron */

.jumbotron {
  background: #f0f3f4;
  border-radius: 6px;
}
.jumbotron h1,
.jumbotron .h1 {
  font-size: 56px;
}
.jumbotron p {
  font-size: 18px;
}

/* 4.23 Component - List Group */

.list-group-item {
  padding: 10px 15px;
}
a.list-group-item,
button.list-group-item {
  color: #575d63;
}
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background: #348fe2;
}
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #242a30;
}
.nav-tabs.card-header-tabs {
  background: none;
  margin-bottom: -11px;
}

/* 4.24 Component - Carousel */

.carousel .carousel-control .fa {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  text-align: center;
  line-height: 30px;
  margin-left: -15px;
}
.carousel .carousel-control.left .fa {
  margin-left: 15px;
}

/* 4.25 Component - Code */

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #e83e8c;
  background-color: #fff2f6;
  border-radius: 4px;
}
.inverse-mode code,
code.inverse-mode {
  background: #2e353c;
  color: #ffd900;
}

/* 4.26 Component - Popover */

.popover {
  font-family: inherit;
  padding: 0;
  border: none;
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.2);
}
.popover-title {
  padding: 10px 12px;
  background: #f5f5f5;
  border: none;
  font-size: 12px;
  line-height: 16px;
}
.popover-content {
  padding: 12px;
  min-width: 240px;
}
.popover > .arrow {
  border-color: transparent !important;
}

/* 4.27 Component - Caret */

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* 4.28 Component - Lead */

.lead {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.4;
}

/* 4.29 Component - List Inline */

.list-inline > li {
  display: inline;
}
.list-inline > li + li {
  margin-left: 15px;
}

/* 4.30 Component - Blockquote */

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #e5e5e5;
}
blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #707478;
}
blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #e5e5e5;
  border-left: 0;
}

/* 4.31 Component - Close Button */

.close {
  margin-left: 15px;
  text-shadow: none;
  line-height: 14px;
}

/* 4.32 Component - Inverse List Group Item (Added in V1.3) */

.list-group-item {
  border-color: #e2e7eb;
}
.list-group-item.list-group-item-inverse {
  background: #242a30;
  border-color: #2d353c;
  color: #fff;
}
.list-group-item.list-group-item-inverse:hover,
.list-group-item.list-group-item-inverse:focus {
  color: #fff;
  background: #2d353c;
}
.list-group-item.list-group-item-inverse .label-inverse,
.list-group-item.list-group-item-inverse .badge-inverse {
  background: #2d353c;
  background: rgba(0, 0, 0, 0.4);
}

/* 4.33 Component - Material Icon (Added in V2.1) */

.material-icons {
  text-rendering: optimizeLegibility;
}

/* 4.34 Component - CSS Checkbox (Added in V3.0) */

.checkbox.checkbox-css {
  position: relative;
  line-height: 16px;
  padding-top: 7px;
}
.checkbox.checkbox-css.checkbox-inline {
  padding-left: 0;
  display: inline-block;
}
.checkbox.checkbox-css.checkbox-inline
  + .checkbox.checkbox-css.checkbox-inline {
  margin-left: 15px;
}
.checkbox.checkbox-css label {
  padding-left: 24px;
  margin: 0;
}
.checkbox.checkbox-css label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: #dee2e6;
}
.has-success .checkbox.checkbox-css label:before,
.has-success.checkbox.checkbox-css label:before,
.is-valid .checkbox.checkbox-css label:before,
.is-valid.checkbox.checkbox-css label:before {
  background: #b3e6e6;
}
.has-success .checkbox.checkbox-css label,
.has-success.checkbox.checkbox-css label,
.is-valid .checkbox.checkbox-css label,
.is-valid.checkbox.checkbox-css label {
  color: #008a8a;
}
.has-error .checkbox.checkbox-css label:before,
.has-error.checkbox.checkbox-css label:before,
.is-invalid .checkbox.checkbox-css label:before,
.is-invalid.checkbox.checkbox-css label:before {
  background: #ffcdcc;
}
.has-error .checkbox.checkbox-css label,
.has-error.checkbox.checkbox-css label,
.is-invalid .checkbox.checkbox-css label,
.is-invalid.checkbox.checkbox-css label {
  color: #cc4946;
}
.has-warning .checkbox.checkbox-css label:before,
.has-warning.checkbox.checkbox-css label:before {
  background: #fce1ba;
}
.checkbox.checkbox-css input {
  display: none;
}
.checkbox.checkbox-css input:checked + label:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  position: absolute;
  top: 7px;
  left: 0;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
}
.checkbox.checkbox-css input:checked + label:before {
  background: #348fe2;
  border-color: #348fe2;
}
.checkbox.checkbox-css.checkbox-success input:checked + label:before {
  background: #00acac;
  border-color: #00acac;
}
.checkbox.checkbox-css.checkbox-info input:checked + label:before {
  background: #49b6d6;
  border-color: #49b6d6;
}
.checkbox.checkbox-css.checkbox-danger input:checked + label:before {
  background: #702963;
  border-color: #702963;
}
.checkbox.checkbox-css.checkbox-warning input:checked + label:before {
  background: #f59c1a;
  border-color: #f59c1a;
}
.checkbox.checkbox-css.checkbox-inverse input:checked + label:before {
  background: #2d353c;
  border-color: #2d353c;
}

/* 4.35 Component - CSS Radio Button (Added in V3.0) */

.radio.radio-css {
  position: relative;
  line-height: 16px;
  padding-top: 7px;
}
.radio.radio-css.radio-inline {
  padding-left: 0;
  display: inline-block;
}
.radio.radio-css.radio-inline + .radio.radio-css.radio-inline {
  margin-left: 15px;
}
.radio.radio-css label {
  padding-left: 24px;
  margin: 0;
}
.radio.radio-css label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #dee2e6;
}
.has-success .radio.radio-css label:before,
.has-success.radio.radio-css label:before,
.is-valid .radio.radio-css label:before,
.is-valid.radio.radio-css label:before {
  background: #b3e6e6;
}
.has-success .radio.radio-css label,
.has-success.radio.radio-css label,
.is-valid .radio.radio-css label,
.is-valid.radio.radio-css label {
  color: #008a8a;
}
.has-error .radio.radio-css label:before,
.has-error.radio.radio-css label:before,
.is-invalid .radio.radio-css label:before,
.is-invalid.radio.radio-css label:before {
  background: #ffcdcc;
}
.has-error .radio.radio-css label,
.has-error.radio.radio-css label,
.is-invalid .radio.radio-css label,
.is-invalid.radio.radio-css label {
  color: #cc4946;
}
.has-warning .radio.radio-css label:before,
.has-warning.radio.radio-css label:before {
  background: #fce1ba;
}
.radio.radio-css input {
  display: none;
}
.radio.radio-css input:checked + label:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #fff;
}
.radio.radio-css input:checked + label:before {
  background: #348fe2;
  border-color: #348fe2;
}
.radio.radio-css.radio-success input:checked + label:before {
  background: #00acac;
  border-color: #00acac;
}
.radio.radio-css.radio-info input:checked + label:before {
  background: #49b6d6;
  border-color: #49b6d6;
}
.radio.radio-css.radio-danger input:checked + label:before {
  background: #702963;
  border-color: #702963;
}
.radio.radio-css.radio-warning input:checked + label:before {
  background: #f59c1a;
  border-color: #f59c1a;
}
.radio.radio-css.radio-inverse input:checked + label:before {
  background: #2d353c;
  border-color: #2d353c;
}

/* 4.36 Component - Bootstrap 4 - Cards (Added in V3.0) */

.card {
  position: relative;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  /* margin-bottom: 15px; */
}
/*
.card-accordion .card + .card {
	margin-top: -10px;
}	
*/
.card-block {
  padding: 15px;
}
.card-title {
  margin-bottom: 15px;
}
.card-subtitle {
  margin-top: -5px;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 30px;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.card-header {
  padding: 10px 15px;
  margin-bottom: 0;
  margin-top: 0;
  background-color: #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  font-weight: 600;
}
.card-header-link,
a.card-header-link {
  margin: -10px -15px;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
}
.card-header:first-child {
  border-radius: 4px 4px 0 0;
}
.card-header.collapsed {
  border-radius: 4px;
}
.card-footer {
  padding: 10px 15px;
  background-color: #f2f3f4;
  border-top: 1px solid #f2f3f4;
  font-weight: 600;
}
.card-footer:last-child {
  border-radius: 0 0 4px 4px;
}
.card-header-tabs {
  margin-right: -5px;
  margin-bottom: -5px;
  margin-left: -5px;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 0px;
}
.card-primary {
  background-color: #348fe2;
  border-color: #348fe2;
}
.card-primary .card-header,
.card-primary .card-footer {
  background-color: transparent;
}
.card-success {
  background-color: #00acac;
  border-color: #00acac;
}
.card-success .card-header,
.card-success .card-footer {
  background-color: transparent;
}
.card-info {
  background-color: #49b6d6;
  border-color: #49b6d6;
}
.card-info .card-header,
.card-info .card-footer {
  background-color: transparent;
}
.card-warning {
  background-color: #f59c1a;
  border-color: #f59c1a;
}
.card-warning .card-header,
.card-warning .card-footer {
  background-color: transparent;
}
.card-danger {
  background-color: #702963;
  border-color: #702963;
}
.card-danger .card-header,
.card-danger .card-footer {
  background-color: transparent;
}
.card-outline-primary {
  background-color: transparent;
  border-color: #0275d8;
}
.card-outline-secondary {
  background-color: transparent;
  border-color: #ccc;
}
.card-outline-info {
  background-color: transparent;
  border-color: #5bc0de;
}
.card-outline-success {
  background-color: transparent;
  border-color: #5cb85c;
}
.card-outline-warning {
  background-color: transparent;
  border-color: #f0ad4e;
}
.card-outline-danger {
  background-color: transparent;
  border-color: #d9534f;
}
.card-inverse {
  color: rgba(255, 255, 255, 0.65);
}
.card-inverse .card-header,
.card-inverse .card-footer {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.card-inverse .card-header,
.card-inverse .card-footer,
.card-inverse .card-title,
.card-inverse .card-blockquote {
  color: #fff;
}
.card-inverse .card-link,
.card-inverse .card-text,
.card-inverse .card-subtitle,
.card-inverse .card-blockquote .blockquote-footer {
  color: rgba(255, 255, 255, 0.65);
}
.card-inverse .card-link:focus,
.card-inverse .card-link:hover {
  color: #fff;
}
.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}
.card-img {
  border-radius: 4px;
  max-width: 100%;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
}
.card-img-top {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  max-width: 100%;
}
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  max-width: 100%;
}
.card.card-inverse footer {
  color: rgba(255, 255, 255, 0.75);
}
.card[class*="card-outline-"] {
  border-width: 1px;
  border-style: solid;
}
.nav-tabs.card-header-tabs > li > a {
  border: none !important;
}
.nav-tabs.card-header-tabs > li > a:hover,
.nav-tabs.card-header-tabs > li > a:focus {
  background: none;
}
.nav-tabs.card-header-tabs > li > a.active,
.nav-tabs.card-header-tabs > li.active > a {
  border-bottom-color: #fff !important;
  background: #fff !important;
  color: #000;
}

/* 4.37 Component - Checkbox Switcher (Added in V4.1) */

.switcher {
  display: inline-block;
}
.switcher label {
  display: block;
  margin: 0;
  width: 46px;
  height: 28px;
  position: relative;
}
.switcher input {
  display: none;
}
.switcher label:before {
  content: "";
  display: block;
  width: 46px;
  height: 28px;
  background: #dee2e6;
  border: 1px solid #dee2e6;
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  border-radius: 46px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.switcher label:after {
  content: "";
  height: 24px;
  width: 24px;
  position: absolute;
  left: 2px;
  top: 2px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  background: #fff;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.switcher input:checked + label:before {
  content: "";
  background: #348fe2;
  border: 2px solid #348fe2;
}
.switcher.switcher-success input:checked + label:before {
  background: #00acac;
  border-color: #00acac;
}
.switcher.switcher-green input:checked + label:before {
  background: #32a932;
  border-color: #32a932;
}
.switcher.switcher-lime input:checked + label:before {
  background: #90ca4b;
  border-color: #90ca4b;
}
.switcher.switcher-indigo input:checked + label:before {
  background: #8753de;
  border-color: #8753de;
}
.switcher.switcher-purple input:checked + label:before {
  background: #727cb6;
  border-color: #727cb6;
}
.switcher.switcher-inverse input:checked + label:before {
  background: #2d353c;
  border-color: #2d353c;
}
.switcher.switcher-warning input:checked + label:before {
  background: #f59c1a;
  border-color: #f59c1a;
}
.switcher.switcher-danger input:checked + label:before {
  background: #702963;
  border-color: #702963;
}
.switcher.switcher-info input:checked + label:before {
  background: #49b6d6;
  border-color: #49b6d6;
}
.switcher.switcher-pink input:checked + label:before {
  background: #fb5597;
  border-color: #fb5597;
}
.switcher.switcher-yellow input:checked + label:before {
  background: #ffd900;
  border-color: #ffd900;
}
.switcher input:checked + label:after {
  left: 20px;
}

/* -------------------------------
   5.0 Plugins
------------------------------- */

/* 5.1 Plugin - jQuery Draggable */

.row > [class^="col-"].ui-sortable {
  min-height: 50px;
}
.panel [class^="col-"].ui-sortable,
.theme-panel [class^="col-"].ui-sortable {
  min-height: 0px;
}
.ui-sortable .panel-heading {
  cursor: move;
}
.ui-sortable.ui-sortable-disabled .panel-heading {
  cursor: default;
}
.ui-sortable-placeholder {
  border: 1px dashed #82868b !important;
  visibility: visible !important;
  background: none;
  margin: 0 !important;
}
.panel.ui-sortable-placeholder {
  margin: -1px -1px 19px !important;
  box-shadow: none !important;
}

/* 5.2 Plugin - Slimscrollbar */

.slimScrollBar {
  width: 6px !important;
  right: 2px !important;
}
.slimScrollRail {
  width: 6px !important;
  right: 2px !important;
}
.sidebar .slimScrollBar,
.content-inverse-mode .slimScrollBar,
.inverse-mode .slimScrollBar {
  background: rgba(255, 255, 255, 0.5) !important;
}

/* 5.3 Plugin - Gritter Notification */

#gritter-notice-wrapper {
  width: 371px !important;
  z-index: 1040 !important;
}
.gritter-item-wrapper {
  background: none !important;
  overflow: hidden !important;
  border-radius: 8px !important;
}
.gritter-top,
.gritter-item,
.gritter-bottom {
  background: url(images/transparent/black-0.8.png) !important;
  background: rgba(0, 0, 0, 0.8) !important;
}
.gritter-with-image {
  width: 280px !important;
}
.gritter-with-image,
.gritter-without-image {
  padding-right: 70px !important;
}
.gritter-image {
  margin: 2px 10px 0 0 !important;
  border-radius: 4px;
}
.gritter-title {
  font-size: 12px !important;
  line-height: 16px !important;
  padding-bottom: 0px !important;
  font-weight: 600 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.gritter-light .gritter-title {
  color: #333 !important;
  font-weight: 600 !important;
}
.gritter-item {
  font-family: inherit !important;
  color: #aaa !important;
  font-size: 10px !important;
  padding: 2px 15px 5px !important;
}
.gritter-close:hover:before,
.gritter-close:focus:before {
  color: #fff;
}
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top {
  background: url(images/transparent/white-0.98.png) !important;
  background: rgba(255, 255, 255, 0.98) !important;
  color: #999 !important;
}
.gritter-light .gritter-close {
  border-left: 1px solid #ccd0d4;
}
.gritter-light .gritter-close:hover:before,
.gritter-light .gritter-close:focus:before {
  color: #2d353c;
}
.gritter-close,
.gritter-light .gritter-close {
  left: auto !important;
  right: 0 !important;
  top: 0 !important;
  background: 0 0 !important;
  width: 70px !important;
  height: auto !important;
  display: block !important;
  bottom: 0;
  border-left: 1px solid #444;
}
.gritter-close:before,
.gritter-light .gritter-close:before {
  content: "Close" !important;
  position: absolute !important;
  text-align: center !important;
  right: 0 !important;
  color: #999;
  left: 0;
  z-index: 10;
  text-indent: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 9px;
  top: 50% !important;
  margin-top: -5px;
  font-family: inherit;
}

/* 5.4 Plugin - Data Table */

.dataTables_wrapper.form-inline {
  display: block;
}
.dataTables_length .form-control {
  margin: 0 5px;
}
div.dataTables_wrapper div.dataTables_filter label {
  display: inline;
}
.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr
  > td:first-child:before,
.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr
  > th:first-child:before {
  background: #cfd5dc !important;
  border-radius: 2px !important;
  border: none !important;
  top: 10px !important;
  left: 10px !important;
  line-height: 16px !important;
  box-shadow: none !important;
  color: #2d353c !important;
  font-weight: bold !important;
  height: 16px !important;
  width: 16px !important;
  text-align: center !important;
  text-indent: 0 !important;
  font-size: 14px !important;
}
table.dataTable > tbody > tr.child td.child {
  padding-left: 15px !important;
}
.table.dataTable.table-bordered.collapsed {
  border-right: none !important;
}
.table.dataTable.table-bordered.collapsed > tbody > tr.child td.child {
  border-right: 1px solid #e2e7ec !important;
}
table.dataTable > tbody > tr.child ul {
  display: block !important;
}
table.dataTable > tbody > tr.child span.dtr-title {
  font-weight: 600 !important;
  color: #242a30;
}
.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  background: #b6c2c9;
}
body div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 5px 0;
}
body div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: inline-block;
}
body div.dataTables_wrapper div.dataTables_paginate ul.pagination > li {
  display: inline;
}
body .dataTables_wrapper table.dataTable {
  margin: 10px 0 !important;
  border-collapse: collapse !important;
}
body table.table-bordered.dataTable {
  border-collapse: collapse !important;
}
.dt-buttons.btn-group {
  float: left;
}
.dataTables_length,
div.dataTables_info {
  float: left;
  margin-right: 15px;
}
.dataTables_empty {
  text-align: center;
}
table.DTFC_Cloned tr.even {
  background: none !important;
}
div.dataTables_scrollHead .table,
.DTFC_LeftWrapper div.DTFC_LeftHeadWrapper table,
.DTFC_RightWrapper div.DTFC_RightHeadWrapper table {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}
.DTFC_LeftBodyLiner .table thead > tr > th,
.dataTables_scrollBody .table thead > tr > th {
  border-bottom: none !important;
}
.dataTables_scrollBody,
.DTFC_LeftBodyLiner {
  border-bottom: 2px solid #ebeced;
}
.dataTables_scroll + .dataTables_info,
.dataTables_wrapper .DTFC_LeftBodyLiner table.dataTable,
.dataTables_wrapper .dataTables_scrollBody table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}
.dataTables_wrapper .DTFC_LeftWrapper table.dataTable > tbody > tr > td,
.dataTables_wrapper .dataTables_scrollBody table.dataTable > tbody > tr > td {
  border-bottom: none !important;
}
.table > tbody + thead > tr > th,
.table.fixedHeader-floating thead > tr > th {
  border-top: none;
  border-bottom-width: 1px !important;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected {
  background: #ffc !important;
  color: #707478;
}
table.dataTable th.focus,
table.dataTable td.focus {
  position: relative;
  z-index: 1000;
  outline: 2px solid #242a30 !important;
  box-shadow: none !important;
}
.table.table-bordered.dataTable tbody tr:first-child td {
  border-top: none !important;
}
div.dt-autofill-handle {
  background: #348fe2 !important;
  border-color: #348fe2 !important;
  margin-top: -10px;
  margin-left: -10px;
}
div.dt-autofill-select {
  background: #348fe2 !important;
}
div.dt-autofill-select.left {
  margin-left: -10px !important;
  margin-top: -10px !important;
}
div.dt-autofill-select.top {
  margin-left: -9px !important;
  margin-top: -10px !important;
}
div.dt-autofill-select.right {
  margin-left: -12px !important;
  margin-top: -10px !important;
}
div.dt-autofill-select.bottom {
  margin-left: -9px !important;
  margin-top: -13px !important;
}
div.dt-autofill-background {
  z-index: 1080 !important;
}
div.dt-autofill-list {
  z-index: 1090 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
div.dt-autofill-list ul li {
  display: table !important;
  width: 100% !important;
}
div.dt-autofill-list ul > li:first-child {
  border-radius: 6px 6px 0 0 !important;
}
div.dt-autofill-list ul > li:last-child {
  border-radius: 0 0 6px 6px !important;
}
div.dt-autofill-list div.dt-autofill-question {
  padding: 15px !important;
  border-color: #f2f3f4 !important;
}
div.dt-autofill-list div.dt-autofill-button {
  width: 140px !important;
  padding: 15px !important;
  border-color: #f2f3f4 !important;
}
div.dt-autofill-list .dt-autofill-button .btn {
  display: block;
  width: 100%;
  position: relative;
  color: transparent;
  background: #b6c2c9;
  border-color: #b6c2c9;
}
div.dt-autofill-list .dt-autofill-button .btn:hover,
div.dt-autofill-list .dt-autofill-button .btn:focus {
  background: #889197;
  border-color: #889197;
}
div.dt-autofill-list .dt-autofill-button .btn:before {
  content: "Fill This";
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
}
div.dt-button-info {
  border: none !important;
  box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  padding: 20px !important;
}
div.dt-button-info h2 {
  border: none !important;
  background: none !important;
  padding: 0 !important;
}
div.dt-button-info > div {
  padding: 0 !important;
  margin-top: 10px !important;
}
div.DTCR_pointer {
  width: 2px !important;
  background: #348fe2 !important;
  margin-left: -1px;
}
table.dataTable.fixedHeader-floating {
  z-index: 1030;
}
tr.dt-rowReorder-moving {
  outline: 2px solid #2e353c !important;
}
table.dt-rowReorder-float {
  outline: 2px solid #348fe2 !important;
}
table.dt-rowReorder-float tr td {
  background: #fff !important;
}
table.dataTable thead .sorting_asc:after {
  content: "\f0dd" !important;
}
table.dataTable thead .sorting_desc:after {
  content: "\f0de" !important;
}
table.dataTable thead .sorting:after {
  content: "\f0dc" !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc:before {
  display: none !important;
}

/* 5.5 Plugin - Datepicker */

.datepicker {
  color: #2d353c;
  min-width: 250px !important;
  border-radius: 6px !important;
}
.datepicker table {
  width: 100%;
}
.datepicker .next,
.datepicker .prev {
  color: transparent;
}
.datepicker .next:before {
  content: "\f105";
  color: #2d353c;
  position: absolute;
  font-size: 14px;
}
.datepicker .prev:before {
  content: "\f104";
  color: #2d353c;
  position: absolute;
  font-size: 14px;
}
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover,
.datepicker .datepicker-switch:hover {
  background: #f2f3f4 !important;
}
.datepicker.datepicker-dropdown {
  width: 320px;
}
.datepicker table tr td,
.datepicker table tr th {
  padding-left: 14px;
  padding-right: 14px;
}
.datepicker .datepicker-months table > thead > tr > th.prev,
.datepicker .datepicker-months table > thead > tr > th.next {
  width: 45px;
}
.datepicker .datepicker-months table > thead > tr > th,
.datepicker .datepicker-months table > tbody > tr > td {
  padding: 5px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 15px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 15px;
}
.datepicker.datepicker-dropdown > div {
  display: block;
}
.datepicker.dropdown-menu {
  min-width: 250px;
  border: none !important;
  box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25) !important;
}
.datepicker-dropdown:before {
  display: none !important;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  background: #575d63 !important;
  text-shadow: none !important;
}
.datepicker.datepicker-inline {
  display: block;
  width: auto;
}
.datepicker-full-width .datepicker-inline {
  width: 100%;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #eee !important;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td span.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background: #348fe2 !important;
  color: #fff !important;
}
.input-daterange .input-group-addon {
  background: #d6d8dd !important;
  border: none !important;
  text-shadow: none !important;
  padding: 6px 12px !important;
  border-radius: 0 !important;
}

/* 5.6 Plugin - Colorpicker */

.colorpicker {
  border: none !important;
  border-radius: 4px !important;
  padding: 5px;
  z-index: 1010 !important;
}
.colorpicker:before {
  display: none !important;
}
.colorpicker:after {
  border-width: 7px !important;
  top: -7px !important;
}
.colorpicker-left:after {
  left: 12px !important;
}
.colorpicker-right:after {
  right: 12px !important;
}
.colorpicker-hue,
.colorpicker-alpha {
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}
.input-group.colorpicker-component {
  display: table;
  width: 100%;
}
.input-group.colorpicker-component .form-control {
  display: table-cell;
  width: 100%;
}
.input-group.colorpicker-component .input-group-addon {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
}
.colorpicker-element .add-on i,
.colorpicker-element .input-group-addon i {
  display: block !important;
}

/* 5.7 Plugin - Bootstrap Timepicker */

.bootstrap-timepicker.input-group {
  display: table;
  width: 100%;
}
.bootstrap-timepicker.input-group > .form-control {
  display: table-cell;
  width: 100%;
}
.bootstrap-timepicker.input-group .input-group-addon {
  display: table-cell;
  width: 20px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  padding: 5px !important;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  border: none !important;
  max-width: 240px;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  display: none !important;
}
.bootstrap-timepicker
  .bootstrap-timepicker-widget.dropdown-menu
  + .form-control {
  border-radius: 4px 0 0 4px;
}
.bootstrap-timepicker-widget .form-control,
.bootstrap-timepicker-widget input {
  width: 40px !important;
  border: 1px solid #d3d8de;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.42857143;
  height: 34px;
  padding: 0;
  display: block;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #495057;
}
.bootstrap-timepicker-widget .form-control:focus,
.bootstrap-timepicker-widget input:focus {
  border-color: #5db0ff;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.bootstrap-timepicker-widget table td {
  padding: 5px !important;
}
.bootstrap-timepicker-widget table td a {
  padding: 5px 0 !important;
  margin: -5px 0 !important;
  border-radius: 4px !important;
}
.bootstrap-timepicker-widget table td a:hover,
.bootstrap-timepicker-widget table td a:focus {
  background: #348fe2 !important;
  border: none !important;
  color: #fff;
}
.bootstrap-timepicker-widget table td input {
  border-radius: 4px !important;
}
.bootstrap-timepicker-widget .glyphicon.glyphicon-chevron-up:before {
  content: "\f077";
}
.bootstrap-timepicker-widget .glyphicon.glyphicon-chevron-down:before {
  content: "\f078";
}

/* 5.8 Plugin - jQuery Autocomplete */

.ui-widget {
  font-family: inherit;
  font-size: 12px;
}
.ui-autocomplete {
  padding: 5px 0 !important;
  box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid #ccc !important;
  border: none !important;
  font-size: 12px !important;
}
.ui-menu .ui-menu-item a {
  padding: 5px 10px;
}
body .ui-autocomplete.ui-menu .ui-menu-item-wrapper {
  padding: 5px 15px;
}
.ui-stats-hover,
.ui-widget-header .ui-stats-hover,
.ui-widget-content .ui-stats-hover,
.ui-stats-focus,
.ui-widget-header .ui-stats-focus,
.ui-widget-content .ui-stats-focus {
  border: none !important;
  background: #348fe2 !important;
  color: #fff !important;
  margin: 0 !important;
  border-radius: 0 !important;
}
.ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-widget-content .ui-state-focus {
  background: #edf0f5;
  border: none;
  margin: 0 !important;
  border-radius: 0;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #348fe2;
  border-color: #348fe2;
}

/* 5.9 Plugin - Combobox */

.combobox-container {
  display: table;
  border-collapse: separate;
  width: 100%;
}
.combobox-container .input-group {
  display: table;
  width: 100%;
}
.combobox-container .input-group .input-group-addon {
  display: table-cell;
  width: 35px;
}
.combobox-container .caret {
  margin: 0;
}
.combobox-container .glyphicon-remove:before {
  content: "\f00d";
}
.combobox-container .dropdown-toggle:after {
  display: none;
}
.combobox-container input,
.combobox-container .uneditable-input {
  border: 1px solid #edf0f5;
  background: #edf0f5;
  font-size: 12px;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  border-radius: 4px 0 0 4px !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.combobox-container input:focus,
.combobox-container .uneditable-input:focus {
  outline: 0;
  border-color: #5db0ff !important;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3) !important;
}
.combobox-container input,
.combobox-container .add-on {
  display: table-cell !important;
}
.combobox-container .add-on {
  width: 1% !important;
  padding: 5px 10px !important;
  white-space: nowrap;
  float: none !important;
  background: #d6d8dd !important;
  color: #555 !important;
}
.btn .combobox-clear {
  margin: 0 !important;
  width: 8px !important;
  position: relative;
  opacity: 1;
}
.combobox-container .icon-remove:before {
  content: "\f00d";
  font-size: 14px;
  font-style: normal;
  position: absolute;
  right: -2px;
  top: 1px;
  text-shadow: none;
}
.combobox-container input,
.combobox-container .uneditable-input {
  border: 1px solid #ccd0d4 !important;
  background: #fff !important;
}

/* 5.10 Plugin - Bootstrap Select */

body .bootstrap-select .btn:focus {
  outline: none !important;
}
body .bootstrap-select.show .dropdown-menu {
  display: block;
  z-index: 1010 !important;
}
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn,
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn:active,
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn:focus,
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn:hover {
  color: #fff;
}
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn.btn-light,
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn.btn-default,
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn.btn-white {
  color: #2e353c;
}
body .bootstrap-select > .dropdown-toggle.bs-placeholder.btn.btn-yellow {
  color: #000;
}

/* 5.11 Plugin - TagIt */

.tagit.ui-widget {
  border: 1px solid #ccd0d4 !important;
  background: #fff !important;
  padding: 3px 12px 2px !important;
  margin-bottom: 0 !important;
}
ul.tagit input[type="text"] {
  background: #fff !important;
  width: auto !important;
}
ul.tagit li.tagit-choice-editable {
  background: #b6c2c9 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 2px 24px 3px 7px !important;
}
.tagit .ui-icon-close {
  background: none !important;
  height: 22px !important;
  width: 16px !important;
  text-indent: 0 !important;
}
.tagit.white .ui-icon-close:before {
  color: #000;
}
.tagit .ui-icon-close:before {
  content: "\f00d";
  font-size: 10px;
  color: #fff;
}
ul.tagit.inverse li.tagit-choice-editable {
  background: #2d353c !important;
}
ul.tagit.white li.tagit-choice-editable {
  background: #fff !important;
  border: 1px solid #ddd !important;
  color: #000 !important;
}
ul.tagit.primary li.tagit-choice-editable {
  background: #348fe2 !important;
}
ul.tagit.info li.tagit-choice-editable {
  background: #49b6d6 !important;
}
ul.tagit.success li.tagit-choice-editable {
  background: #00acac !important;
}
ul.tagit.warning li.tagit-choice-editable {
  background: #f59c1a !important;
}
ul.tagit.danger li.tagit-choice-editable {
  background: #702963 !important;
}
ul.tagit li.tagit-choice .tagit-close {
  right: 2px !important;
  line-height: 22px !important;
  text-align: center !important;
  top: 0 !important;
  margin: 0 !important;
}
ul.tagit li.tagit-choice .tagit-close:hover,
ul.tagit li.tagit-choice .tagit-close:focus {
  text-decoration: none !important;
}
ul.tagit li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit li.tagit-choice .tagit-close:focus .ui-icon-close:before {
  color: #666;
  color: rgba(0, 0, 0, 0.4);
}
ul.tagit.inverse li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit.inverse li.tagit-choice .tagit-close:focus .ui-icon-close:before {
  color: #666;
  color: rgba(255, 255, 255, 0.4);
}

/* 5.12 Plugin - Parsley */

.parsley-error {
  background: #fff !important;
  border-color: #702963 !important;
}
.parsley-error:focus {
  border-color: #cc4946 !important;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(204, 73, 70, 0.3) !important;
}
.parsley-success {
  background: #fff !important;
  border-color: #00acac !important;
}
.parsley-success:focus {
  border-color: #008a8a !important;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(0, 172, 172, 0.3) !important;
}
.parsley-error,
.parsley-success {
  color: #2d353c !important;
  box-shadow: none !important;
}
.parsley-error:focus,
.parsley-success:focus {
  color: #2d353c !important;
}
.parsley-errors-list {
  padding: 0 !important;
  list-style-type: none !important;
  margin: 0 !important;
  color: #e5603b;
  font-size: 12px !important;
  line-height: inherit !important;
}
.parsley-errors-list.filled {
  margin-top: 5px !important;
}

/* 5.13 Plugin - CKEditor */

.panel-form .cke_chrome {
  border: none;
  box-shadow: none;
}

/* 5.14 Plugin - Bootstrap Wizard */

.bwizard-steps {
  font-size: 14px !important;
  border-radius: 3px !important;
  overflow: hidden !important;
  display: table !important;
  width: 100% !important;
}
.bwizard-steps li:before,
.bwizard-steps li:after {
  border: none !important;
}
.bwizard-steps li {
  float: none !important;
  display: table-cell !important;
  padding: 15px !important;
  line-height: 20px !important;
  background: #f0f3f4 !important;
  border-radius: 0 !important;
}
.bwizard-steps li:hover,
.bwizard-steps li:focus {
  background: #d3dadf !important;
}
.bwizard-steps.clickable li:hover:not(.active) a,
.bwizard-steps.clickable li:focus:not(.active) a {
  color: #333 !important;
}
.bwizard-steps li small {
  font-size: 11px !important;
  color: #999 !important;
  display: block !important;
  line-height: 14px !important;
}
.bwizard-steps li a {
  margin-left: 30px !important;
  display: block !important;
  text-decoration: none !important;
}
.bwizard-steps .label {
  float: left !important;
  padding: 0 !important;
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
  text-align: center !important;
  margin-right: 10px !important;
  top: 0 !important;
  background: #6d7479 !important;
  border-radius: 50% !important;
}
.bwizard-steps li.active,
.bwizard-steps li.active:hover,
.bwizard-steps li.active:focus {
  background: #00acac !important;
}
.bwizard-steps li.active small {
  color: #b2e6e6 !important;
}
.bwizard-steps li.active .label {
  background: rgba(0, 0, 0, 0.2) !important;
}

/* 5.15 Plugin - Fullcalendar */

.fc-event,
.fc .fc-event {
  border: none;
  background: #2d353c;
  border-radius: 0;
}
.fc .fc-highlight {
  background: #cdd3de;
}
.fc .fc-day-grid-event {
  padding: 1px 2px;
}
.fc .fc-view-container {
  background: #fff;
}
.fc.fc-unthemed .fc-today {
  background: #fffce4;
}
.fc a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}
.fc-row .fc-content-skeleton td.fc-today,
.fc-row .fc-helper-skeleton td.fc-today {
  background: none;
}
.fc-event-list .fc-event {
  background: none;
  border: none;
  color: #242a30;
  padding: 7px 10px 7px 35px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  background: #fff;
  margin-bottom: 5px;
  border-radius: 6px;
}
.fc-event-list .fc-event:hover,
.fc-event-list .fc-event:focus {
  color: #242a30;
  cursor: pointer;
  background: #fff;
}
.fc-event-list .fc-event:hover:before,
.fc-event-list .fc-event:focus:before {
  background: #cdd5da;
  color: #2e353c;
}
.fc-event-list .fc-event i {
  float: left;
  line-height: 18px;
}
.fc-event-list .fc-event + .fc-event {
  margin-top: -1px;
}
.fc-event-list .fc-event.ui-draggable:before {
  content: "\f0b2";
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome;
  font-weight: 900;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 25px;
  text-align: center;
  line-height: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  background: #e6eaec;
  border-radius: 6px 0 0 6px;
  color: #a2a7ab;
  font-size: 14px;
}
.fc-event-list .fc-event.ui-draggable-dragging {
  border: 1px dashed #d2d6da;
}
.fc-event-list .fc-event-icon {
  float: right;
  margin-left: 10px;
}
body .fc button {
  font-weight: 400;
  color: #242a30;
  background: #fff;
  padding: 5px 10px;
  height: inherit;
  font-size: 12px;
  line-height: 1.5;
  border: none;
  text-shadow: none;
  position: initial;
  box-shadow: none;
}
body .fc button + button {
  border-left: 1px solid #e2e7eb;
}
body .fc button:active,
body .fc button:focus,
body .fc button:hover {
  background: #fff;
  outline: none;
}
body .fc button:hover {
  color: #348ee3;
}
body .fc button.fc-state-disabled:active,
body .fc button.fc-state-disabled:focus,
body .fc button.fc-state-disabled:hover {
  background: #fff;
}
body .fc button.fc-state-active {
  color: #fff;
  background: #2e353c;
  border-color: #2e353c;
  box-shadow: none;
}
.fc.fc-unthemed .fc-content,
.fc.fc-unthemed .fc-divider,
.fc.fc-unthemed .fc-popover,
.fc.fc-unthemed .fc-row,
.fc.fc-unthemed tbody,
.fc.fc-unthemed td,
.fc.fc-unthemed th,
.fc.fc-unthemed thead {
  border-color: #d9e0e7;
}
.fc th {
  padding: 2px 15px !important;
  color: #242a30;
  font-weight: 600;
  background: #e8ecf1;
}
.fc th.fc-axis {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.fc button .fc-icon {
  margin: 0;
  top: 0;
  width: auto;
  height: auto;
  font-size: 12px;
}
.fc .fc-icon-left-single-arrow:after,
.fc .fc-icon-right-single-arrow:after {
  content: "\f0d9";
  font-size: 12px;
  margin: 0;
  top: 1px;
}
.fc .fc-icon-right-single-arrow:after {
  content: "\f0da";
}
.fc .fc-unthemed .fc-today {
  background: #fff8f0;
}
.fc .fc-scroller {
  overflow: initial;
  height: auto !important;
}
.fc .fc-toolbar {
  margin-bottom: 0 !important;
}
.fc .fc-toolbar h2 {
  font-size: 22px;
  line-height: 28px;
}
.fc .fc-toolbar > * > * {
  margin-bottom: 15px !important;
}

/* 5.16 Plugin - jVectorMap */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  line-height: 16px !important;
  left: 15px !important;
  color: #000 !important;
  background: #fff !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
.jvectormap-label,
.jvectormap-tip {
  padding: 3px 6px !important;
  border: none !important;
  background: rgba(0, 0, 0, 0.6) !important;
}

/* 5.17 Plugin - Flot Chart */

.legendColorBox > div {
  margin-right: 5px !important;
  border-radius: 5px !important;
  margin-top: 1px !important;
  border: none !important;
}
.legend > table tr td.legendColorBox {
  padding-left: 5px !important;
}
.legend > table tr td {
  padding: 4px 0 5px 0 !important;
}
.legend > div {
  border-radius: 4px !important;
  background: #000 !important;
}
.legend > table tr td.legendLabel {
  padding-right: 10px !important;
  color: #fff;
}
.legend > table tr + tr td {
  padding-top: 0px !important;
}
.legendColorBox > div > div {
  border-radius: 5px !important;
  border-width: 4px !important;
}
.tickLabel,
.legendLabel {
  font-size: 11px !important;
}
.tickLabel {
  color: #2d353c !important;
  font-size: 12px !important;
  font-weight: 600;
}
.flot-tooltip {
  position: absolute;
  display: none;
  border: 0;
  padding: 6px 10px 7px;
  color: #fff;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  opacity: 1;
  z-index: 10000 !important;
}

/* 5.18 Plugin - Sparkline */

.jqstooltip {
  border: none !important;
  border-radius: 4px;
  background: #333 !important;
  width: auto !important;
  height: auto !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

/* 5.19 Plugin - Iris Range Slider */

.irs-line-left,
.irs-bar-edge {
  border-radius: 6px 0 0 6px !important;
}
.irs-line-right {
  border-radius: 0 6px 6px 0 !important;
}
.irs-to,
.irs-from,
.irs-single {
  background: #2d353c !important;
}
.irs-bar-edge {
  background: #b9c0c3 !important;
  border-right: 1px solid #fff;
  top: 27px !important;
  height: 4px !important;
}
.irs-line-mid,
.irs-line-left,
.irs-line-right {
  background: #f2f3f4 !important;
}
.irs-bar {
  background: #929ba1 !important;
}
.irs-bar,
.irs-line {
  height: 4px !important;
  top: 27px !important;
}
.irs-min,
.irs-max {
  background: #e9edef !important;
  color: #5b6165 !important;
}
.irs-slider.to,
.irs-slider.from,
.irs-slider.single {
  background: #929ba1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: 6px solid #fff;
  cursor: pointer;
}

/* 5.20 Plugin - Bootstrap Colorpalette */

.bootstrap-colorpalette .btn-color {
  border-radius: 4px;
  width: 19px !important;
  height: 19px !important;
}
.bootstrap-colorpalette .btn-color:focus {
  outline: none !important;
  border: 1px solid #000 !important;
}

/* 5.21 Plugin - X-Editable */

.editable-date {
  width: 300px;
}
.editable-input {
  font-size: 12px;
}
.editable-error-block {
  font-size: 12px;
}
.editable-date .datetimepicker {
  width: auto !important;
  color: #2e353c !important;
}
.editable-input .date .form-control,
.editable-input .datetimepicker.datetimepicker-inline {
  width: 240px;
}
.editable-input .input-sm,
.editable-input select.form-control:not([size]):not([multiple]).input-sm {
  height: 30px;
}
.editable-input .date .add-on {
  display: none;
}
.editable-input .datetimepicker.datetimepicker-inline table > thead > tr > th,
.editable-input .datetimepicker.datetimepicker-inline table thead th,
.editable-input .datetimepicker.datetimepicker-inline table tfoot th,
.editable-input .datetimepicker.datetimepicker-inline table tbody td {
  border: none !important;
  vertical-align: middle;
}
.editable-input .tt-dropdown-menu {
  border: none !important;
  font-size: 12px;
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.2) !important;
}
.editable-input .tt-suggestion.tt-is-under-cursor {
  background: #348fe2 !important;
}
.editable-input .twitter-typeahead .tt-hint {
  padding: 5px 9px !important;
}
.editable-checklist {
  font-size: 12px;
  min-width: 200px;
}
.editableform textarea.form-control {
  min-width: 240px;
}
.editable-checklist label {
  display: block;
}
.editable-checklist label input[type="checkbox"].form-control {
  box-shadow: none !important;
  height: 20px !important;
}
.editable-checklist label input[type="checkbox"] + span {
  margin-left: 5px;
}
.editable-input .select2-container .select2-choice > .select2-chosen {
  color: #2e353c;
}
.editable-buttons .glyphicon-ok:before {
  content: "\f00c";
}
.editable-buttons .glyphicon-remove:before {
  content: "\f00d";
}

/* 5.22 Plugin - Password Indicator */

.input-append .icon-eye-open:before,
.input-prepend .icon-eye-open:before {
  content: "\f06e";
}
.input-append .icon-eye-close:before,
.input-prepend .icon-eye-close:before {
  content: "\f070";
}

/* 5.23 Plugin - Powerange Slider (Added in V1.2) */

.slider-wrapper {
  padding: 15px 30px !important;
}
.slider-wrapper.slider-without-range {
  padding: 15px 0 !important;
}
.slider-wrapper.slider-vertical {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.range-min,
.range-max {
  color: #242a30 !important;
}
.range-handle {
  cursor: pointer !important;
}
.range-quantity {
  background: #00acac !important;
}
.red .range-quantity {
  background: #702963 !important;
}
.blue .range-quantity {
  background: #348fe2 !important;
}
.purple .range-quantity {
  background: #727cb6 !important;
}
.orange .range-quantity {
  background: #f59c1a !important;
}
.black .range-quantity {
  background: #2d353c !important;
}

/* 5.24 Plugin - DateTimepicker (Added in V1.2) */

.datetimepicker table {
  width: 100%;
}
.datetimepicker th,
.datetimepicker td {
  padding: 0px 5px !important;
  width: 30px !important;
  height: 30px !important;
}
.datetimepicker table tr td.active,
.datetimepicker table tr span.active {
  background-image: none !important;
  background: #348fe2 !important;
  color: #fff !important;
  text-shadow: none !important;
}

/* 5.25 Plugin - Wysihtml5 Sandbox (Added in V1.2) */

.wysihtml5-sandbox {
  width: 100% !important;
}
.panel-form .wysihtml5-toolbar {
  padding: 15px 15px 5px;
}
.panel-form .wysihtml5-sandbox {
  border-radius: 0 0 4px 4px !important;
}
.wysihtml5-toolbar .glyphicon-quote:before {
  display: none;
}
.wysihtml5-toolbar .glyphicon-quote:after {
  content: "\f10d";
}
.wysihtml5-toolbar .glyphicon-list:before {
  content: "\f03a";
}
.wysihtml5-toolbar .glyphicon-indent-right:before {
  content: "\f038";
}
.wysihtml5-toolbar .glyphicon-indent-left:before {
  content: "\f036";
}
.wysihtml5-toolbar .glyphicon-th-list:before {
  content: "\f00b";
}
.wysihtml5-toolbar .glyphicon-share:before {
  content: "\f064";
}
.wysihtml5-toolbar .glyphicon-picture:before {
  content: "\f03e";
}
.wysihtml5-toolbar .dropdown-toggle:after {
  display: none;
}

/* 5.26 Plugin - Typeahead (Added in V1.2) */

.twitter-typeahead .tt-hint {
  line-height: 22px;
  padding: 5px 11px;
  opacity: 0.6;
}
.tt-suggestion.tt-is-under-cursor {
  background: #edf0f5;
  color: #222;
}

/* 5.27 Plugin - Select 2 Choice (Added in V1.2) */

.select2-container.form-control .select2-choice,
.select2-container.form-control .select2-choice {
  background: none !important;
  margin: -6px -12px !important;
  padding: 6px 12px !important;
  line-height: 20px !important;
  border: none !important;
  box-shadow: none !important;
  height: 32px !important;
}
.select2-container.form-control.input-sm .select2-choice,
.select2-container.form-control.input-sm .select2-choice {
  height: 30px !important;
}
.select2-container .select2-choice .select2-arrow {
  background: none !important;
  border-left: none !important;
  width: 22px !important;
}
.select2-container.form-control .select2-choice abbr {
  top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice abbr {
  top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice .select2-arrow b {
  background-position: -3px 4px !important;
}
.select2-container .select2-choice .select2-arrow b {
  background-position: 0 3px !important;
}
.select2-drop-active {
  border-color: #e2e7eb !important;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
}
.select2-drop.select2-drop-above {
  box-shadow: 0 -2px 2px -1px rgba(0, 0, 0, 0.2) !important;
}
.select2-search {
  padding: 5px !important;
}
.select2-search input {
  border-color: #e2e7eb !important;
  border-radius: 4px !important;
}
.select2-drop {
  border: none !important;
  border-top: 1px solid #d3d8df !important;
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.2) !important;
  margin-top: -2px !important;
}
.select2-results {
  margin: 0 !important;
  padding: 5px 0 !important;
}
.select2-results .select2-result-label {
  padding: 5px 15px !important;
}
.select2-results .select2-highlighted {
  background: #f2f3f4 !important;
  color: #333 !important;
}
.select2-container.select2-container-multi.form-control {
  height: auto !important;
  width: 240px !important;
}
.select2-container.select2-container-multi.form-control .select2-choices {
  margin: -6px -12px !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 22px !important;
  height: auto !important;
  background: none !important;
}
.select2-container.select2-container-multi.form-control.select2-container-active {
  border-color: #5db0ff !important;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.select2-container-multi .select2-choices .select2-search-choice {
  box-shadow: none !important;
  border-color: #f2f3f4 !important;
  background: #f2f3f4 !important;
  margin-top: 4px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  padding: 0 !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li.select2-selection__choice {
  padding: 0 5px !important;
}

/* 5.28 Plugin - Bootstrap Calendar (Added in V1.3) */

.bootstrap-calendar .calendar {
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 10px 0;
}
.bootstrap-calendar .calendar a {
  line-height: 24px;
}
.bootstrap-calendar .calendar td {
  border: none !important;
}
.bootstrap-calendar .calendar .table td {
  padding: 3px 5px;
}
.bootstrap-calendar .calendar .week_days td {
  background: none;
  color: #a9acae;
}
.bootstrap-calendar .calendar .visualmonthyear {
  color: #2d353c;
}
.bootstrap-calendar .calendar .table.header {
  margin-bottom: 5px;
  position: relative;
  z-index: 10;
}
.bootstrap-calendar .calendar .table.header td {
  color: #707478;
  vertical-align: middle;
}
.bootstrap-calendar .calendar .table.header .year {
  width: 72%;
}
.bootstrap-calendar .calendar .table.header td.year {
  font-size: 18px;
}
.bootstrap-calendar .calendar .icon-arrow-right,
.bootstrap-calendar .calendar .icon-arrow-left {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
}
.bootstrap-calendar .calendar .icon-arrow-right:hover,
.bootstrap-calendar .calendar .icon-arrow-left:hover,
.bootstrap-calendar .calendar .icon-arrow-right:focus,
.bootstrap-calendar .calendar .icon-arrow-left:focus {
  color: #2d353c;
}
.bootstrap-calendar .calendar .icon-arrow-left:before {
  content: "\f060";
}
.bootstrap-calendar .calendar .icon-arrow-right:before {
  content: "\f061";
}
.bootstrap-calendar .calendar .table {
  margin-bottom: 0;
}
.bootstrap-calendar .calendar .event a {
  display: block;
  border-radius: 30px;
  background: #fff;
  color: #fff;
  margin: 0 auto;
}
.bootstrap-calendar .calendar .event .popover a {
  color: #428bca;
}
.bootstrap-calendar .calendar .event .popover a:hover,
.bootstrap-calendar .calendar .event .popover a:focus {
  color: #2a6496;
  text-decoration: underline;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus,
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
  text-shadow: none;
  color: #2d353c;
  box-shadow: none;
  font-weight: normal;
}
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
  color: #fff;
  opacity: 0.8;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus {
  background: none;
  text-align: center;
}
.bootstrap-calendar .calendar .fa-circle {
  position: absolute;
  right: 0;
  top: 0;
}

/* 5.29 Plugin - Superbox (Added in V1.3) */

.superbox {
  font-size: 0;
  margin: -1px -1px 0 0;
}
.superbox-list {
  display: inline-block;
  position: relative;
}
.superbox-list.superbox-O {
  z-index: 10;
}
.superbox-list.superbox-O:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1px;
  border: 10px solid transparent;
  border-bottom-color: #d9e0e7;
  margin-left: -10px;
  z-index: 10;
}
.superbox-list.superbox-O:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -3px;
  border: 10px solid transparent;
  border-bottom-color: #242a31;
  margin-left: -10px;
  z-index: 20;
}
.superbox-show {
  background: #242a30;
  border-top: 1px solid #d9e0e7 !important;
}
.superbox-img {
  width: 100%;
  cursor: pointer;
  display: block;
  padding-top: 75%;
  position: relative;
}
.superbox-img:hover {
  opacity: 0.8;
}
.superbox-img img {
  display: none;
}
.superbox-img span {
  position: absolute;
  top: 1px;
  left: 0;
  right: 1px;
  bottom: 0;
  background-size: cover !important;
  background-position: center !important;
}
.superbox-current-img {
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
  max-width: 100%;
}
.superbox-close {
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 35px;
  height: 35px;
}

/* 5.30 Plugin - Pace Loader (Added in V1.5) */

.pace-inactive {
  opacity: 0;
}
.pace.pace-inactive {
  z-index: 0;
}
.ie8 .pace-inactive,
.ie8 .pace .pace-activity {
  display: none;
}
.pace {
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 1s;
  z-index: 1020;
}
.pace-progress {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  text-align: center;
  height: 3px;
  background: #00acac;
  transition: width 1s;
  z-index: 2000;
}
.pace:before {
  content: "";
  background: none;
  position: fixed;
  top: 50px;
  right: 0;
  left: 0;
  height: 3px;
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 65px;
  right: 8px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #00acac;
  border-left-color: #00acac;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.pace-top .pace:before,
.pace-top .pace-progress {
  top: 0;
}
.pace-top .pace .pace-activity {
  top: 11px;
}

/* 5.31 Plugin - Jstree (Added in V1.7) */

.jstree-default .jstree-clicked,
.jstree-default .jstree-wholerow-clicked {
  background: #e4e9ec !important;
  box-shadow: none !important;
}
.jstree-default .jstree-hovered,
.jstree-default .jstree-wholerow-hovered {
  background: #f0f3f4 !important;
  box-shadow: none !important;
}
.jstree-open > .jstree-anchor > .fa-folder:before {
  content: "\f07c";
  color: #242a30;
}
.jstree-default .jstree-checkbox {
  background: none;
}
.jstree-default .jstree-checkbox:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px solid #b4b6b7;
  display: block;
  background: #fff;
}
.jstree-default .jstree-anchor > .jstree-checkbox.jstree-undetermined:after {
  content: "";
  position: absolute;
  left: 7px;
  right: 7px;
  bottom: 7px;
  top: 7px;
  display: block;
  background: #b4b6b7;
}
.jstree-default.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox:after,
.jstree-default .jstree-checked > .jstree-checkbox:after {
  content: "\f00c";
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  display: block;
  line-height: 16px;
  font-size: 12px;
  font-style: initial;
  color: #242a30;
}

/* 5.32 Plugin - Bootstrap Date Range Picker (Added in V1.7)  */

body .daterangepicker {
  z-index: 1020 !important;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}
.daterangepicker:before {
  display: none !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.active.available,
.daterangepicker td.active.available:hover {
  background: #348fe2 !important;
  text-shadow: none !important;
  color: #fff !important;
}
.daterangepicker table {
  color: #2d353c;
}
.daterangepicker .calendar th {
  color: #2d353c !important;
}
.daterangepicker .calendar th,
.daterangepicker .calendar td {
  padding: 7px !important;
  font-weight: 600;
}
.daterangepicker .ranges li {
  color: #242a30 !important;
  font-size: 12px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #2e353c !important;
  border-color: #2e353c !important;
  color: #fff !important;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #e9ecef !important;
  color: #2d353c !important;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background: none !important;
  color: #d3d8df !important;
}
.daterangepicker td.off:hover,
.daterangepicker td.off:focus {
  background: #fff !important;
  color: #d3d8df !important;
}
body .daterangepicker td.in-range {
  background: #c8e9f3;
}
body .daterangepicker .input-mini.active {
  border-color: #5db0ff;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
body .daterangepicker .calendar-table th,
body .daterangepicker .calendar-table td {
  padding: 0;
}

/* 5.33 Plugin - Select 2 (Added in V1.7) */

.select2-dropdown {
  border: none !important;
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.15) !important;
  z-index: 1020 !important;
}
.select2-dropdown.select2-dropdown--above {
  box-shadow: 0 -10px 20px 0px rgba(0, 0, 0, 0.15) !important;
}
.select2-container .select2-selection--single:focus {
  outline: 0 !important;
}
.select2.select2-container--focus .select2-selection--single:focus {
  border-color: #5db0ff;
  outline: 0 !important;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #348fe2 !important;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  min-height: 34px !important;
  border-color: #e2e7eb !important;
  outline: none !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 12px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 34px !important;
  font-size: 12px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 5px !important;
  height: 32px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 4px !important;
  border-color: #2e353c transparent transparent transparent !important;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #2e353c transparent !important;
  margin-top: -6px;
}
.select2-container--default .select2-results__group {
  color: #242a30 !important;
}
.select2-container--default .select2-search--inline .select2-search__field {
  height: 23px;
  padding-left: 5px;
  border-color: #d3d8de !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 6px 12px !important;
  height: 34px;
  border-color: #d3d8de !important;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple,
.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  border-color: #5db0ff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3) !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-top: 6px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 12px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  border: none !important;
  margin-right: 8px !important;
  padding: 2px 6px !important;
}

/* 5.34 Plugin - Bootstrap Date Timepicker (Added in V1.8) */

body .bootstrap-datetimepicker-widget .glyphicon {
  font-size: 14px;
}
body .bootstrap-datetimepicker-widget .glyphicon.glyphicon-chevron-up:before {
  content: "\f106";
}
body .bootstrap-datetimepicker-widget .glyphicon.glyphicon-chevron-down:before {
  content: "\f107";
}
body .bootstrap-datetimepicker-widget .glyphicon.glyphicon-chevron-left:before {
  content: "\f104";
}
body
  .bootstrap-datetimepicker-widget
  .glyphicon.glyphicon-chevron-right:before {
  content: "\f105";
}
body .bootstrap-datetimepicker-widget .glyphicon.glyphicon-time:before {
  content: "\f017";
}
body .bootstrap-datetimepicker-widget .glyphicon.glyphicon-calendar:before {
  content: "\f133";
}
body .bootstrap-datetimepicker-widget .timepicker-hour,
body .bootstrap-datetimepicker-widget .timepicker-minute,
body .bootstrap-datetimepicker-widget .timepicker-second {
  border: 1px solid #d3d8df;
  border-radius: 6px;
  display: block;
  width: 80px;
  margin: 0 auto;
  line-height: 22px;
  height: auto;
  padding: 6px 15px;
}
body .bootstrap-datetimepicker-widget .picker-switcher {
  width: 100% !important;
}
body .bootstrap-datetimepicker-widget .datepicker > div {
  display: block;
}
body .bootstrap-datetimepicker-widget.dropdown-menu {
  width: 320px;
  padding: 0;
  border-radius: 6px;
}
body .bootstrap-datetimepicker-widget.dropdown-menu,
body .bootstrap-datetimepicker-widget a[data-action] {
  color: #242a30;
}
body .bootstrap-datetimepicker-widget.dropdown-menu .datepicker {
  padding: 0;
}
body .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  top: -7px;
  left: 15px;
}
body .bootstrap-datetimepicker-widget.dropdown-menu.bottom.pull-right:after {
  left: auto;
  right: 15px;
}
body .bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  display: none;
}
body .bootstrap-datetimepicker-widget .collapse.in {
  display: block;
}
body .bootstrap-datetimepicker-widget .collapse.in + .picker-switch td span {
  margin-top: 0;
  border-radius: 0 0 6px 6px;
}
body .bootstrap-datetimepicker-widget .picker-switch td span {
  display: block;
  width: auto;
  margin: -1px 0 0;
  background: #f2f3f4;
  height: 36px;
  line-height: 36px;
  border-radius: 6px 6px 0 0;
}
body .bootstrap-datetimepicker-widget .picker-switch td a {
  text-decoration: none;
}
body .bootstrap-datetimepicker-widget .picker-switch td span:hover {
  background: #e9ecef;
}
body .bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
body .bootstrap-datetimepicker-widget table td span:hover,
body .bootstrap-datetimepicker-widget table td.day:hover,
body .bootstrap-datetimepicker-widget table td.hour:hover,
body .bootstrap-datetimepicker-widget table td.minute:hover,
body .bootstrap-datetimepicker-widget table td.second:hover {
  background: #f2f3f4;
}
body .bootstrap-datetimepicker-widget .datepicker-days,
body .bootstrap-datetimepicker-widget .datepicker-months,
body .bootstrap-datetimepicker-widget .timepicker .timepicker-picker,
body .bootstrap-datetimepicker-widget .timepicker .timepicker-hours,
body .bootstrap-datetimepicker-widget .timepicker .timepicker-minutes,
body .bootstrap-datetimepicker-widget .timepicker .datepicker-seconds,
body .bootstrap-datetimepicker-widget .datepicker-years,
body .bootstrap-datetimepicker-widget .datepicker-decades,
body .bootstrap-datetimepicker-widget .table-condensed > thead > tr > th,
body .bootstrap-datetimepicker-widget .table-condensed > tbody > tr > td {
  padding: 5px;
}
body .bootstrap-datetimepicker-widget .datepicker-months table tbody tr td,
body .bootstrap-datetimepicker-widget .datepicker-years table tbody tr td,
body
  .bootstrap-datetimepicker-widget
  .picker-switch
  .table-condensed
  > tbody
  > tr
  > td {
  padding: 0;
}
body .bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 16px !important;
  padding: 11px;
}
body .bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px 15px;
}
body .bootstrap-datetimepicker-widget table td {
  height: 32px;
  width: 32px;
  line-height: 32px;
}
body .bootstrap-datetimepicker-widget .timepicker-picker table td.separator {
  width: 20px;
}
body .bootstrap-datetimepicker-widget .timepicker-picker table td,
body .bootstrap-datetimepicker-widget .timepicker-picker table td .btn span {
  width: auto;
  height: auto;
  line-height: inherit;
}
body .bootstrap-datetimepicker-widget .timepicker-picker table td {
  padding: 0 5px;
}
body .bootstrap-datetimepicker-widget .timepicker-picker table td .btn,
body .bootstrap-datetimepicker-widget .timepicker-picker table td .btn span {
  display: block;
}
body
  .bootstrap-datetimepicker-widget
  .timepicker-picker
  table
  td
  .btn.btn-primary {
  width: 60px;
  margin-right: 10px;
}
body .bootstrap-datetimepicker-widget .timepicker-picker table td:first-child {
  padding-left: 10px;
}

/* 5.35 Plugin - Nvd3 Chart (Added in v1.9) */

.nvtooltip {
  font-family: inherit !important;
  font-size: 12px !important;
  border: none !important;
  padding: 5px 10px !important;
  border-radius: 8px !important;
  background: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}
.nvtooltip table td.legend-color-guide div {
  border: none !important;
  border-radius: 4px !important;
}
.nvtooltip table thead strong {
  text-decoration: underline !important;
}
.nvd3-inverse-mode .nvd3 line.nv-guideline {
  stroke: rgba(255, 255, 255, 0.5) !important;
}
.nvd3-inverse-mode .nvd3 .nv-bars text {
  fill: rgba(255, 255, 255, 1) !important;
}
.nvd3-inverse-mode .nvd3 .nv-axis line,
.nvd3-inverse-mode .nvd3 .nv-axis path {
  fill: none;
  stroke: rgba(255, 255, 255, 0.15) !important;
}
.nvd3-inverse-mode .nvd3 .nv-legend-text,
.nvd3-inverse-mode .nvd3 .nv-x text,
.nvd3-inverse-mode .nvd3 .nv-y text {
  font-family: inherit !important;
  fill: rgba(255, 255, 255, 0.75) !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}
.nvd3-inverse-mode .nvd3.nv-pie .nv-pie-title,
.nvd3-inverse-mode .nv-label text {
  fill: rgba(255, 255, 255, 0.75) !important;
}
.nvd3-inverse-mode .nvd3 .nv-legend-symbol {
  r: 3;
}
.nvd3-inverse-mode .nvd3.nv-pie path {
  stroke: rgba(0, 0, 0, 0.2) !important;
}

/* 5.36 Plugin - Intro Js Setting (Added in V2.1) */

.introjs-hint {
  width: 10px !important;
  height: 10px !important;
}
.introjs-helperNumberLayer {
  font-size: 12px;
  background: #702963 !important;
  font-family: inherit !important;
  border: none !important;
  text-shadow: none !important;
  padding: 0 !important;
  text-align: center !important;
}

/* 5.37 Plugin - Summernote (Added in V2.1) */

.note-editor.note-frame {
  border: none !important;
  margin: 0 !important;
  background: none;
}
.note-popover.popover {
  display: none;
}
.note-popover .popover-content,
.panel-heading.note-toolbar {
  padding: 10px 15px !important;
  border-radius: 0;
  background: #242a31;
}
.note-editor.note-frame .note-placeholder,
.note-editor.note-frame .note-editing-area .note-editable {
  padding: 15px !important;
}
.note-toolbar.panel-heading .btn-group .btn {
  margin-top: 0 !important;
}
.note-toolbar .dropdown-toggle:after {
  display: none !important;
}
.note-btn-group .dropdown-menu .btn-group {
  display: inline-block;
}

/* 5.38 Plugin - jQuery Simple Colorpicker (Added in V2.1) */

.simplecolorpicker {
  z-index: 1010 !important;
}
.simplecolorpicker.icon,
.simplecolorpicker span.color {
  border-radius: 4px;
  width: 19px;
}
.simplecolorpicker.fontawesome span.color[data-selected]:after {
  display: block;
  text-align: center;
}
.simplecolorpicker.glyphicons span.color[data-selected]:after {
  content: "\f00c" !important;
  margin-left: 2px !important;
  top: 0 !important;
}
.simplecolorpicker span.color:focus,
.simplecolorpicker.icon:focus {
  outline: none !important;
}
.simplecolorpicker.inline {
  padding: 5px 0 0 !important;
}

/* 5.39 Plugin - Dropzone (Added in V2.1) */

.dropzone {
  border: 2px dashed #b6c2c9 !important;
  background: none !important;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer !important;
}
.dropzone.dz-clickable:hover,
.dropzone.dz-clickable:focus {
  background: #f2f3f4 !important;
}
.dropzone.dz-clickable.dz-started:hover,
.dropzone.dz-clickable.dz-started:focus {
  background: none !important;
}
.dropzone .dz-message {
  font-weight: 300 !important;
  letter-spacing: 0.5px;
  color: inherit !important;
  font-size: 28px !important;
}
.dropzone .dz-message {
  text-align: center !important;
  margin: 45px 0 !important;
}
.dropzone .dz-message .dz-note {
  font-size: 14px !important;
  font-weight: normal !important;
  display: block !important;
  margin-top: 20px !important;
  color: #707478 !important;
}
.dropzone .dz-preview.dz-image-preview {
  background: none !important;
}
.dropzone .dz-preview .dz-error-message {
  background: #702963 !important;
}
.dropzone .dz-preview .dz-error-message:after {
  border-bottom-color: #702963 !important;
}
.inverse-mode .dropzone {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.inverse-mode .dropzone.dz-clickable:hover,
.inverse-mode .dropzone.dz-clickable:focus {
  background: rgba(255, 255, 255, 0.15) !important;
}
.inverse-mode .dropzone .dz-message {
  color: inherit !important;
}
.inverse-mode .dropzone .dz-message .dz-note {
  color: rgba(255, 255, 255, 0.75) !important;
}

/* 5.40 Plugin - Bootstrap SweetAlert (Added in V3.0) */

.swal-modal .btn:focus {
  box-shadow: none !important;
}
.swal-icon--success,
.swal-icon--success__ring {
  border-color: rgba(0, 172, 172, 0.2) !important;
}
.swal-icon--success__line--long,
.swal-icon--success__line--tip {
  background-color: #00acac !important;
}
.swal-icon--info {
  border-color: #49b6d6 !important;
}
.swal-icon--info:after,
.swal-icon--info:before {
  background-color: #49b6d6 !important;
}
.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #f59c1a !important;
}
.swal-icon--warning {
  border-color: #f59c1a !important;
}
.swal-icon--error__line {
  background-color: #702963 !important;
}
.swal-icon--error {
  border-color: #702963 !important;
}

/* 5.41 Plugin - Highlight JS (Added in V4.0) */

.hljs-wrapper {
  background: #f2f3f4;
  border-radius: 3px;
  position: relative;
}
.hljs-wrapper pre {
  border: none;
  padding: 0;
  background: none;
  border-radius: 0;
}
.hljs-wrapper .hljs {
  padding: 15px;
  border-radius: 0;
  display: block;
  overflow-x: auto;
  color: #242a31;
  border: none;
  font-size: 12px;
  background: none;
  border-radius: 0;
}
.hljs-wrapper .hljs-tag,
.hljs-wrapper .hljs-section,
.hljs-wrapper .hljs-name,
.hljs-wrapper .hljs-selector-tag,
.hljs-wrapper .hljs-deletion,
.hljs-wrapper .hljs-subst {
  color: #2b71b5;
}
.hljs-wrapper .hljs-attr,
.hljs-wrapper .hljs-variable,
.hljs-wrapper .hljs-template-variable,
.hljs-wrapper .hljs-type,
.hljs-wrapper .hljs-selector-class,
.hljs-wrapper .hljs-selector-attr,
.hljs-wrapper .hljs-selector-pseudo,
.hljs-wrapper .hljs-number {
  color: #4ea2d4;
}
.hljs-wrapper .hljs-string,
.hljs-wrapper .hljs-regexp,
.hljs-wrapper .hljs-addition,
.hljs-wrapper .hljs-attribute,
.hljs-wrapper .hljs-meta-string {
  color: #ea4b47;
}
.hljs-comment {
  color: #728088;
}
.panel-body + .hljs-wrapper {
  border-radius: 0 0 3px 3px;
}
.hljs-wrapper .btn-clipboard {
  position: absolute;
  right: 10px;
  top: 10px;
}
.modal .hljs-wrapper {
  border-radius: 0;
  margin-top: -1px;
}
.modal .hljs-wrapper + .modal-footer {
  border-top: none;
}

/* 5.42 Plugin - jQuery Smart Wizard (Added in V4.0) */

.sw-main.sw-theme-default {
  box-shadow: none;
}
.sw-main .nav-tabs {
  background: #f2f3f4;
  border-radius: 0;
  display: flex;
  width: 100%;
}
.sw-main .nav-tabs > li {
  display: inline-block;
  float: none;
  padding: 0;
  margin: 0 !important;
}
.sw-main > ul.step-anchor.nav-tabs > li > a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 15px 15px;
  margin: 0;
  border-radius: 0;
  color: #2e353c;
}
.sw-main > ul.step-anchor.nav-tabs > li.active > a {
  color: #fff !important;
  background: #348ee3 !important;
}
.sw-main > ul.step-anchor.nav-tabs > li.active > a .number {
  background: rgba(0, 0, 0, 0.2);
}
.sw-main > ul.step-anchor.nav-tabs > li > a .number {
  float: left;
  padding: 0;
  height: 20px;
  width: 20px;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
  top: 0;
  background: #bec1c3;
  color: #fff;
  border-radius: 40px;
  position: relative;
}
.sw-main > ul.step-anchor.nav-tabs > li > a .number + .info {
  margin-left: 30px;
  padding-right: 15px;
  display: block;
}
.sw-theme-default > ul.step-anchor.nav-tabs > li > a small {
  display: block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.75;
}
.sw-theme-default > ul.step-anchor > li > a::after {
  display: none !important;
}
.sw-main .sw-container .step-content {
  background: #fff;
  padding: 30px;
  border: none !important;
}
.sw-main .sw-toolbar {
  background: #f2f3f4 !important;
  padding: 15px !important;
}
.sw-main .sw-toolbar:before,
.sw-main .sw-toolbar:after {
  content: "";
  display: table;
  clear: both;
}
.sw-main .sw-toolbar .btn {
  border-radius: 4px !important;
  min-width: 100px;
}
.sw-main .sw-toolbar .sw-btn-next,
.sw-main .sw-toolbar .sw-btn-next.disabled,
.sw-main .sw-toolbar .sw-btn-next.disabled:hover,
.sw-main .sw-toolbar .sw-btn-next.disabled:focus {
  color: #fff;
  background: #348fe2;
  border-color: #348fe2;
}
.sw-main .sw-toolbar .sw-btn-next:hover,
.sw-main .sw-toolbar .sw-btn-next:focus {
  background: #2a72b5;
  border-color: #2a72b5;
}
.sw-main .sw-toolbar .sw-btn-prev,
.sw-main .sw-toolbar .sw-btn-prev.disabled,
.sw-main .sw-toolbar .sw-btn-prev.disabled:hover,
.sw-main .sw-toolbar .sw-btn-prev.disabled:focus {
  background: #fff;
  border-color: #fff;
  color: #2e353c;
}
.sw-main .sw-toolbar .btn + .btn {
  margin-left: 10px;
  border-left: none;
}
.sw-main .sw-btn-group {
  margin: 0;
}
.sw-main.sw-theme-default > ul.step-anchor > li.done > a {
  background: #e9edef !important;
  color: #5b6165 !important;
}
.sw-main > ul.step-anchor.nav-tabs > li.done > a .number,
.sw-main > ul.step-anchor.nav-tabs > li.done > a .number:before {
  background: #358de4 !important;
}
.sw-main > ul.step-anchor.nav-tabs > li.done > a .number:before {
  content: "\f00c";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  color: #fff;
}

/* -------------------------------
   6.0 Widgets
------------------------------- */

/* 6.1 Widget - General Setting (Added in v4.2) */

.widget {
  overflow: hidden;
  margin-bottom: 20px;
  background: #fff;
  color: inherit;
  padding: 0;
  border-radius: 0;
}
.widget.widget-rounded {
  border-radius: 4px;
}
.widget-header {
  display: table;
  width: 100%;
}
.widget-header-title,
.widget-header-icon {
  display: table-cell;
  padding: 10px 15px;
}
.widget-header-title {
  font-size: 12px;
  margin: 0;
  line-height: 20px;
  width: 100%;
}
.widget-header-icon {
  width: 1%;
}
.widget-header-icon + .widget-header-icon {
  padding-left: 0;
}
.widget-body {
  padding: 15px;
}
.widget.inverse-mode {
  background: #2d353c;
}
.widget.inverse-mode .vertical-box.with-border-top,
.widget.inverse-mode
  .vertical-box.with-grid
  > .vertical-box-column
  + .vertical-box-column {
  border-color: #575d63;
}
.widget.inverse-mode hr {
  background: #575d63;
}

/* 6.2 Widget - Media Message */

.media-messaging .media {
  padding: 15px;
}
.media-messaging .media:first-child {
  padding-top: 0;
}
.media-messaging .media:last-child {
  padding-bottom: 0;
}
.media-messaging .media p {
  margin: 0;
}
.media-messaging .media + .media {
  margin-top: 0;
  padding-top: 15px;
}

/* 6.3 Widget - Stats */

.widget.widget-stats {
  position: relative;
  color: #fff;
  padding: 15px;
  border-radius: 3px;
}
.widget-stats .stats-info h4 {
  font-size: 12px;
  margin: 5px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
}
.widget-stats .stats-icon {
  font-size: 42px;
  height: 56px;
  width: 56px;
  text-align: center;
  line-height: 56px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0.15;
}
.widget-stats .stats-content {
  position: relative;
}
.widget-stats .stats-info p {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.widget-stats .stats-link a {
  display: block;
  margin: 15px -15px -15px;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.widget-stats .stats-link a:hover,
.widget-stats .stats-link a:focus {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.widget-stats .stats-icon.stats-icon-lg {
  font-size: 128px;
  top: 15px;
  right: 0px;
  color: #fff;
  width: 128px;
  height: 50px;
  line-height: 50px;
  text-shadow: 3px 7px rgba(0, 0, 0, 0.25);
}
.widget-stats .stats-icon.stats-icon-lg i {
  position: relative;
}
.widget-stats .stats-icon.stats-icon-square {
  opacity: 1;
  border-radius: 12px;
  font-size: 42px;
  top: 15px;
  right: 15px;
  height: 56px;
  width: 56px;
  text-align: center;
  line-height: 56px;
  margin-left: 15px;
}
.widget-stats .stats-title {
  position: relative;
  margin: 0 0 2px;
  font-size: 12px;
}
.widget-stats .stats-title,
.widget-stats .stats-desc {
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
}
.widget-stats .stats-desc {
  margin: -5px 0 -3px;
  font-weight: 300;
}
.widget-stats .stats-number {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}
.widget-stats .stats-progress {
  background: rgba(0, 0, 0, 0.5);
  height: 2px;
  margin: 0 0 15px;
  border-radius: 3px;
}
.widget-stats .stats-progress.stats-progress-light {
  background: rgba(255, 255, 255, 0.3);
}
.widget-stats .stats-progress .progress-bar {
  background: #fff;
  border-radius: 0 3px 3px 0;
}
.widget-stats.text-inverse .stats-desc,
.widget-stats.text-inverse .stats-title {
  color: rgba(0, 0, 0, 0.85);
}
.widget-stats.text-inverse .stats-number,
.widget-stats.text-inverse .stats-desc {
  letter-spacing: 0;
  font-weight: 400;
}
.widget-stats.text-inverse .stats-title {
  font-weight: 700;
}
.widget-stats.bg-white .stats-progress {
  background: rgba(0, 0, 0, 0.1);
}
.widget-stats.bg-white .stats-progress .progress-bar {
  background: #2d353c;
}

/* 6.4 Widget - Todolist */

.todolist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.todolist > li {
  border-bottom: 1px solid #ddd;
}
.todolist > li > a {
  display: block;
  color: #333;
}
.todolist > li > a:hover,
.todolist > li > a:focus {
  background: #fafafa;
  text-decoration: none;
}
.todolist > li > a:hover .todolist-input i:before,
.todolist > li > a:focus .todolist-input i:before {
  content: "\f14a";
  opacity: 0.4;
  color: #2d353c;
}
.todolist > li.active > a .todolist-input i:before,
.todolist > li.active > a:hover .todolist-input i:before,
.todolist > li.active > a:focus .todolist-input i:before {
  content: "\f14a";
  color: #00acac;
  opacity: 1;
}
.todolist > li.active > a .todolist-title {
  text-decoration: line-through;
}
.todolist-title {
  padding-right: 15px;
}
.todolist-input {
  text-align: center;
  font-size: 14px;
  border-right: 1px solid #ddd;
  padding-left: 15px;
  color: #f2f3f4;
}
.todolist-input i {
  display: block;
  width: 14px;
  text-align: center;
}
.todolist-input,
.todolist-title {
  display: table-cell;
  padding: 10px;
}

/* 6.5 Widget - Theme Panel (Added in V1.2) */

.theme-panel {
  position: fixed;
  right: -175px;
  top: 150px;
  z-index: 1020;
  background: #fff;
  padding: 15px;
  width: 175px;
  transition: right 0.2s linear;
  border-radius: 4px 0 0 4px;
}
.theme-panel.active {
  right: 0;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.3);
}
.theme-panel .theme-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.theme-panel .theme-list + .theme-list {
  margin-top: 10px;
}
.theme-panel .theme-list > li {
  float: left;
}
.theme-panel .theme-list > li + li {
  margin-left: 5px;
}
.theme-panel .theme-list > li > a {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  display: block;
  transition: all 0.2s linear;
  text-decoration: none;
}
.theme-panel .theme-list > li > a:hover,
.theme-panel .theme-list > li > a:focus {
  opacity: 0.6;
}
.theme-panel .theme-list > li.active > a {
  position: relative;
}
.theme-panel .theme-list > li.active > a:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 14px;
  color: #fff;
  opacity: 0.4;
  text-align: center;
}
.theme-panel .theme-list > li.active > a.bg-white:before {
  color: #666;
}
.theme-panel .theme-collapse-btn {
  position: absolute;
  left: -30px;
  top: 10%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  color: #333;
  background: #fff;
  border-radius: 4px 0 0 4px;
  text-align: center;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}
.theme-panel .theme-panel-content {
  margin: -15px;
  padding: 15px;
  background: #fff;
  position: relative;
  z-index: 1020;
  border-radius: 5px 0 0 5px;
}
.theme-panel.active .theme-collapse-btn .fa:before {
  content: "\f00d";
}
.theme-panel .theme-list a.bg-white {
  border: 1px solid #e2e7eb;
}
.theme-panel .control-label {
  line-height: 30px;
}
.theme-panel .control-label.double-line {
  line-height: 15px;
}
.theme-panel .divider {
  height: 1px;
  margin: 15px 0;
  background: #f2f2f2;
}
.theme-panel.theme-panel-lg {
  top: 0;
  bottom: 0;
  width: 260px;
  right: -260px;
}
.theme-panel.theme-panel-lg.active {
  right: 0;
}
.theme-panel.theme-panel-lg .theme-panel-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  margin: 0;
  padding: 20px;
}
.theme-panel.theme-panel-lg .theme-collapse-btn {
  top: 30%;
}
.theme-panel.theme-panel-lg .theme-version:before,
.theme-panel.theme-panel-lg .theme-version:after {
  content: "";
  display: table;
  clear: both;
}
.theme-panel.theme-panel-lg .theme-list {
  margin-bottom: -3px;
}
.theme-panel.theme-panel-lg .theme-list > li > a {
  width: 28px;
  height: 28px;
  border-radius: 28px;
}
.theme-panel.theme-panel-lg .theme-list > li.active > a:before {
  line-height: 28px;
}
.theme-panel.theme-panel-lg .theme-list > li + li {
  margin-left: 0;
}
.theme-panel.theme-panel-lg .theme-list > li {
  margin-right: 3px;
  margin-bottom: 3px;
}
.theme-panel .theme-version {
  margin: 0 -14px 5px;
}
.theme-panel.theme-panel-lg .theme-version {
  margin: 0 -20px 5px;
}
.theme-panel .theme-version a {
  float: left;
  width: calc(50% - 2.5px);
  padding-top: 35%;
  position: relative;
  text-decoration: none;
}
.theme-panel .theme-version a + a {
  margin-left: 5px;
}
.theme-panel .theme-version a span {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}
.theme-version a.active:before,
.theme-version a:hover:before {
  content: "";
  position: absolute;
  border: 15px solid transparent;
  border-left-color: #999;
  border-top-color: #999;
  left: 0;
  top: 0;
  z-index: 10;
}
.theme-version a.active:before {
  border-left-color: #007aff;
  border-top-color: #007aff;
}
.theme-version a.active:after,
.theme-version a:hover:after {
  content: "\f00c";
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  color: #fff;
  z-index: 10;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

/* 6.6 Widget - Chart (Added in V1.3) */

.widget-chart {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 20px;
}
.widget-chart .chart-title {
  font-size: 16px !important;
  line-height: 20px;
  color: #fff;
  font-weight: 600 !important;
  margin: 2px 5px 5px;
}
.widget-chart .chart-title small {
  display: block;
  margin-top: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #616d72;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}
.widget-chart .widget-chart-content {
  padding: 15px;
  margin-right: 230px;
}
.widget-chart .widget-chart-full-width {
  margin: 0 -15px;
}
.widget-chart .widget-chart-sidebar {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 230px;
  padding: 15px 20px;
  border-radius: 0 4px 4px 0;
}
.widget-chart-sidebar .chart-number {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;
  margin-bottom: 15px;
}
.widget-chart-sidebar .chart-number small {
  font-size: 12px;
  line-height: 16px;
  margin-top: 3px;
  color: #616d72;
  color: rgba(255, 255, 255, 0.5);
  display: block;
  font-weight: normal;
}
.widget-chart-sidebar .chart-legend {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}
.widget-chart-sidebar .chart-legend > li {
  margin-bottom: 5px;
  color: #fff;
}
.widget-chart-sidebar .chart-legend > li span {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 5px;
}
body .morris-hover.morris-default-style {
  border-radius: 6px;
  padding: 6px 10px;
  color: #242a31;
  background: #f2f3f4;
  border: none;
  font-family: inherit;
  font-size: 12px;
  text-align: center;
}
.morris-inverse .morris-hover {
  background: rgba(25, 30, 35, 0.85) !important;
  border: none !important;
  padding: 10px !important;
  color: #fff !important;
  border-radius: 4px !important;
}
.morris-hover {
  z-index: 0 !important;
}

/* 6.7 Widget - Registered Users List (Added in V1.3) */

.registered-users-list {
  list-style-type: none;
  margin: 7.5px;
  padding: 0;
}
.registered-users-list > li {
  width: 25%;
  font-size: 12px;
  float: left;
  padding: 7.5px;
  color: #2d353c;
}
.registered-users-list > li > a {
  display: block;
  margin-bottom: 10px;
}
.registered-users-list > li img {
  border-radius: 3px;
  max-width: 100%;
}
.registered-users-list > li .username {
  font-size: 12px;
  color: #2d353c;
  margin-bottom: 0;
  font-weight: 600;
}
.registered-users-list > li .username small {
  display: block;
  font-size: 11px;
  color: #707478;
  line-height: 15px;
}

/* 6.8 Widget - Chat List (Added in V1.3) */

.chats {
  list-style-type: none;
  margin: 0;
  padding: 12px 15px 15px;
}
.chats > li + li,
.chats > div + div {
  margin-top: 15px;
}
.chats > li:before,
.chats > li:after,
.chats > div:before,
.chats > div:after {
  content: "";
  display: table;
  clear: both;
}
.chats .name {
  color: #333;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  text-decoration: none;
}
.chats .name .label {
  font-size: 8px;
  padding: 1px 4px 2px;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.chats .date-time {
  font-size: 10px;
  display: block;
  float: right;
  color: #999;
  margin-top: 3px;
}
.chats .image {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
}
.chats .image img {
  max-width: 100%;
}
.chats .image + .message {
  margin-left: 75px;
}
.chats .message {
  padding: 7px 12px;
  font-size: 12px;
  position: relative;
  background: #fff;
  border-radius: 14px;
}
.chats .message:before {
  content: "";
  position: absolute;
  top: 15px;
  left: -13px;
  border: 7px solid transparent;
  border-right-color: #fff;
}
.chats .right .name {
  text-align: right;
}
.chats .right .date-time {
  float: left;
}
.chats .right .image {
  float: right;
}
.chats .right .image + .message {
  margin-right: 75px;
  margin-left: 0;
}
.chats .right .message:before {
  left: auto;
  right: -13px;
  border-right-color: transparent;
  border-left-color: #fff;
}
.panel-body .chats {
  padding: 0;
}
.slimScrollDiv .chats {
  padding-right: 15px;
}

/* 6.9 Widget - Unlimited Nav Tabs (Added in V1.6) */

.tab-overflow {
  position: relative;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs {
  white-space: nowrap;
  overflow: hidden;
  display: table;
  margin: 0;
  border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs > li,
.tab-overflow.nav.nav-tabs > li {
  display: table-cell;
  float: none;
}
.tab-overflow .nav.nav-tabs > li > a {
  margin-left: -1px;
}
.tab-overflow .next-button,
.tab-overflow .prev-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  display: none !important;
  opacity: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: all 0.2s linear;
}
.tab-overflow .prev-button {
  left: 0;
  right: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .next-button,
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
  box-shadow: 0 0 15px #000;
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
  box-shadow: 0 0 15px #000;
}
.tab-overflow .next-button a,
.tab-overflow .prev-button a {
  margin: 0;
  background: #c1ccd1;
}
.flat-black .tab-overflow .next-button a,
.flat-black .tab-overflow .prev-button a {
  background: #d2d2d2;
}
.tab-overflow .prev-button > a {
  border-radius: 3px 0 3px;
}
.tab-overflow .nav-tabs-inverse .next-button > a,
.tab-overflow .nav-tabs-inverse .prev-button > a,
.nav.nav-tabs.nav-tabs-inverse > li > a,
.nav.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li > a:focus {
  background: #242a30;
}
.flat-black .tab-overflow .nav-tabs-inverse .next-button > a,
.flat-black .tab-overflow .nav-tabs-inverse .prev-button > a,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a:hover,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a:focus {
  background: #2a2a2a;
}
.nav.nav-tabs.nav-tabs-inverse > li.active > a,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:focus {
  background: #fff;
  color: #242a30;
}
.tab-overflow.overflow-right .next-button,
.tab-overflow.overflow-left .prev-button {
  display: block !important;
  opacity: 1;
  background: none;
}
.tab-overflow .nav-tabs > li.prev-button + li.active > a {
  border-radius: 0 3px 0 0;
}

/* 6.10 Widget - Widget List (Added in V4.2) */

.widget-list-item,
a.widget-list-item {
  display: table;
  width: 100%;
  background: #fff;
  text-decoration: none;
  color: #242a30;
}
.widget-list-rounded .widget-list-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.widget-list-rounded .widget-list-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.widget-list-item .widget-list-media {
  width: 70px;
  height: 60px;
}
.widget-list-item .widget-list-media.icon {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.widget-list-item .widget-list-media.icon i {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
}
.widget-list-item .widget-list-media img {
  max-width: 100%;
  max-height: 100%;
}
.widget-list-item .widget-list-media,
.widget-list-item .widget-list-content,
.widget-list-item .widget-list-action {
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}
.widget-list-item + .widget-list-item .widget-list-content,
.widget-list-item + .widget-list-item .widget-list-action {
  border-top: 1px solid #f0f3f4;
}
.widget-list-item > div:first-child {
  padding-left: 15px;
}
.widget-list-item > div:last-child {
  padding-right: 15px;
}
.widget-list-item .widget-list-media + div,
.widget-list-item .widget-list-content + div,
.widget-list-item .widget-list-action + div {
  padding-left: 0;
}
.widget-list-item .widget-list-title {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
.widget-list-item .widget-list-desc {
  font-size: 11px;
  margin: 0;
  color: #575d63;
}
.widget-list-item .widget-list-action {
  width: 10%;
  padding-left: 15px;
  text-align: center;
}
.panel > .widget-list .widget-list-item {
  border-radius: 0;
}
.inverse-mode .widget-list-item,
.inverse-mode a.widget-list-item,
.inverse-mode.widget-list-item,
a.inverse-mode.widget-list-item {
  background: #2d353c;
}
.inverse-mode .widget-list-item .widget-list-title,
.inverse-mode.widget-list-item .widget-list-title {
  color: #fff;
}
.inverse-mode .widget-list-item .widget-list-desc,
.inverse-mode.widget-list-item .widget-list-desc {
  color: #a8acb1;
}
.inverse-mode .widget-list-item + .widget-list-item .widget-list-content,
.inverse-mode .widget-list-item + .widget-list-item .widget-list-action,
.inverse-mode.widget-list-item + .widget-list-item .widget-list-content,
.inverse-mode.widget-list-item + .widget-list-item .widget-list-action {
  border-color: #46505a;
}

/* 6.11 Widget - Widget Chat (Added in V4.2) */

.widget-chat {
  background: #fff;
}
.widget-chat.widget-chat-rounded {
  border-radius: 4px;
}
.widget-chat.widget-chat-rounded.widget-chat-with-input {
  border-radius: 4px 4px 0 0;
}
.widget-chat .widget-chat-header {
  display: table;
  width: 100%;
  border-bottom: 1px solid #f0f3f4;
}
.widget-chat .widget-chat-header-icon,
.widget-chat .widget-chat-header-content {
  padding: 12px 15px;
  display: table-cell;
  vertical-align: middle;
}
.widget-chat .widget-chat-header-icon {
  width: 1%;
}
.widget-chat .widget-chat-header > div + div {
  padding-left: 0;
}
.widget-chat .widget-chat-header-title {
  margin: 0;
  font-size: 14px;
}
.widget-chat .widget-chat-header-desc {
  font-size: 12px;
  margin: 0;
}
.widget-chat .widget-chat-body {
  padding: 15px;
  background: #f0f3f4;
}
.widget-chat .widget-chat-item {
  color: #242a30;
  position: relative;
  min-height: 38px;
  padding: 3px 0;
}
.widget-chat .widget-chat-media {
  width: 38px;
  height: 38px;
  position: absolute;
  bottom: 3px;
  left: 0px;
  border-radius: 38px;
  overflow: hidden;
  text-align: center;
  line-height: 38px;
}
.widget-chat .widget-chat-media img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.widget-chat .right .widget-chat-media {
  left: auto;
  right: 0;
}
.widget-chat .with-media .widget-chat-info {
  margin-left: 53px;
  min-width: 60%;
}
.widget-chat .right .widget-chat-info {
  margin-left: 0;
}
.widget-chat .with-media.right .widget-chat-info {
  margin-right: 53px;
}
.widget-chat .widget-chat-info-container {
  background: #fff;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 18px;
  position: relative;
  color: #fff;
}
.widget-chat .widget-chat-message:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: -24px;
  border: 0 solid transparent;
  border-top-width: 15px;
  border-right-width: 10px;
  border-right-color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  transform: rotate(10deg);
}
.widget-chat .widget-chat-message:after {
  content: "";
  display: inline-block;
  width: 60px;
  height: 12px;
}
.widget-chat .widget-chat-name {
  font-weight: 600;
}
.widget-chat .widget-chat-message {
  font-weight: 600;
  color: #242a30;
}
.widget-chat .widget-chat-time {
  position: absolute;
  right: 12px;
  bottom: 8px;
  font-style: italic;
  color: #929ba1;
  font-weight: 500;
  font-size: 11px;
}
.widget-chat .right .widget-chat-time {
  right: auto;
  left: 12px;
}
.widget-chat .right .widget-chat-message:after {
  float: left;
}
.widget-chat .right .widget-chat-message:before {
  left: auto;
  right: -24px;
  bottom: -5px;
  border-right-width: 0px;
  border-right-color: transparent;
  border-left-width: 10px;
  border-left-color: #fff;
}
.widget-chat-item.right .widget-chat-info {
  text-align: right;
}
.inverse-mode.widget-chat {
  background: #2d353c;
}
.inverse-mode.widget-chat .widget-chat-header {
  border-color: #242a31;
}
.inverse-mode.widget-chat .widget-chat-header-title {
  color: #fff;
}
.inverse-mode.widget-chat .widget-chat-header-desc {
  color: #a8acb1;
}
.inverse-mode.widget-chat .widget-chat-body {
  background: #242a31;
}

/* 6.12 Widget - Widget Input (Added in V4.2) */

div + .widget-input {
  border-top: 1px solid #f0f3f4;
}
div + .widget-input.widget-input-rounded {
  border-radius: 0 0 4px 4px;
}
.widget-input.widget-input-rounded {
  border-radius: 4px;
}
.widget-input {
  position: relative;
  background: #fff;
}
.widget-input-container {
  display: table;
  width: 100%;
}
.widget-input-icon,
.widget-input-box,
.widget-input-divider {
  display: table-cell;
  vertical-align: middle;
}
.widget-input-divider {
  width: 1px;
}
.widget-input-divider:after {
  content: "";
  width: 1px;
  height: 26px;
  background: #f2f3f4;
  display: block;
}
.widget-input-icon {
  width: 1%;
  padding: 0 5px;
}
.widget-input-icon a {
  display: block;
  padding: 10px 10px;
  font-size: 18px;
}
.widget-input-box {
  padding: 10px 0;
}
.widget-input-box .form-control {
  border-color: #f2f3f4;
  background: #f2f3f4;
  border-radius: 40px;
}
.widget-input-box .form-control:focus {
  box-shadow: none;
}
.inverse-mode .widget-input,
.inverse-mode.widget-input {
  background: #2d353c;
}
.inverse-mode div + .widget-input,
div + .widget-input.inverse-mode {
  border-color: #242a31;
}
.inverse-mode .widget-input-box .form-control {
  border-color: #575d63;
  background: #575d63;
  color: #fff;
}
.inverse-mode .widget-input-box .form-control::-webkit-input-placeholder {
  color: #a8acb1;
}
.inverse-mode .widget-input-box .form-control::-moz-placeholder {
  color: #a8acb1;
}
.inverse-mode .widget-input-box .form-control:-ms-input-placeholder {
  color: #a8acb1;
}
.inverse-mode .widget-input-box .form-control:-moz-placeholder {
  color: #a8acb1;
}
.inverse-mode .widget-input-divider:after {
  background: #575d63;
}

/* 6.13 Widget - Widget Todolist (Added in V4.2) */

.widget-todolist {
  background: #fff;
}
.widget-todolist:before,
.widget-todolist:after {
  content: "";
  display: table;
  clear: both;
}
.widget-todolist.widget-todolist-rounded {
  border-radius: 4px;
}
.widget-todolist-header {
  display: table;
  width: 100%;
}
.widget-todolist-body {
  padding: 5px 0;
  border-top: 1px solid #f0f3f4;
  border-bottom: 1px solid #f0f3f4;
  margin-bottom: 20px;
}
.widget-todolist-header-left,
.widget-todolist-header-right {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 15px;
}
.widget-todolist-header-right {
  width: 1%;
  text-align: center;
}
.widget-todolist-header .widget-todolist-header-title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}
.widget-todolist-header .widget-todolist-header-total {
  font-size: 18px;
  font-weight: 600;
  color: #2d353c;
}
.widget-todolist-header .widget-todolist-header-total small {
  display: block;
  font-size: 10px;
  line-height: 10px;
}
.widget-todolist-item {
  display: table;
  width: 100%;
}
.widget-todolist-item .widget-todolist-input,
.widget-todolist-item .widget-todolist-content,
.widget-todolist-item .widget-todolist-icon {
  display: table-cell;
  padding: 10px 15px;
  vertical-align: middle;
}
.widget-todolist-item .widget-todolist-input,
.widget-todolist-item .widget-todolist-icon {
  width: 1%;
  text-align: center;
}
.widget-todolist-item .widget-todolist-input {
  width: 45px;
}
.widget-todolist-item .widget-todolist-input + div {
  padding-left: 0;
}
.widget-todolist-item + .widget-todolist-item .widget-todolist-content,
.widget-todolist-item + .widget-todolist-item .widget-todolist-icon {
  border-top: 1px solid #f0f3f4;
}
.widget-todolist-item .widget-todolist-title {
  font-size: 12px;
  margin: 0;
}
.widget-todolist-item .widget-todolist-desc {
  margin: 0;
  font-size: 12px;
}
.widget-todolist-item .widget-todolist-content .form-control {
  margin: -5px 0;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding-left: 0;
  background: none;
}
.inverse-mode.widget-todolist {
  background: #2d353c;
}
.inverse-mode .widget-todolist-body,
.inverse-mode
  .widget-todolist-item
  + .widget-todolist-item
  .widget-todolist-content,
.inverse-mode
  .widget-todolist-item
  + .widget-todolist-item
  .widget-todolist-icon {
  border-color: #575d63;
}
.inverse-mode .widget-todolist-header .widget-todolist-header-title,
.inverse-mode .widget-todolist-header .widget-todolist-header-total,
.inverse-mode .widget-todolist-item .widget-todolist-title,
.inverse-mode .widget-todolist-item .widget-todolist-content .form-control {
  color: #fff;
}
.inverse-mode .widget-todolist-item .widget-todolist-desc {
  color: #a8acb1;
}

/* 6.14 Widget - Widget Map (Added in V4.2) */

.widget-map {
  background: #fff;
}
.widget-map.widget-map-rounded {
  border-radius: 4px;
}
.inverse-mode.widget-map {
  background: #2d353c;
}

/* 6.15 Widget - Widget Icon (Added in V4.2) */

.widget-icon {
  position: relative;
  overflow: hidden;
}
.widget-icon,
.widget-icon i {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
}
.widget-icon.user i {
  font-size: 36px;
  line-height: 56px;
}
.widget-icon.widget-icon-xs,
.widget-icon.widget-icon-xs i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
}
.widget-icon.widget-icon-xs.user i {
  font-size: 18px;
  line-height: 28px;
}
.widget-icon.widget-icon-sm,
.widget-icon.widget-icon-sm i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 21px;
}
.widget-icon.widget-icon-sm.user i {
  font-size: 27px;
  line-height: 42px;
}
.widget-icon.widget-icon-lg,
.widget-icon.widget-icon-lg i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 42px;
}
.widget-icon.widget-icon-lg.user i {
  font-size: 54px;
  line-height: 84px;
}
.widget-icon.widget-icon-xl,
.widget-icon.widget-icon-xl i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 56px;
}
.widget-icon.widget-icon-xl.user i {
  font-size: 72px;
  line-height: 112px;
}

/* 6.16 Widget - Widget Image (Added in V4.2) */

.widget-img {
  overflow: hidden;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.widget-img.widget-img-xl {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.widget-img.widget-img-lg {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.widget-img.widget-img-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.widget-img.widget-img-xs {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

/* 6.17 Widget - Widget Card (Added in V4.2) */

.widget-card,
a.widget-card {
  position: relative;
  margin-bottom: 15px;
  display: block;
  text-decoration: none;
}
.widget-card.square {
  padding-top: 100%;
  overflow: hidden;
}
.widget-card .widget-card-content {
  padding: 15px;
  position: relative;
}
.widget-card .widget-card-cover,
.widget-card.square .widget-card-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.widget-card.widget-card-rounded .widget-card-content,
.widget-card.widget-card-rounded .widget-card-cover {
  border-radius: 4px;
}
.widget-card .widget-card-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.widget-card .widget-card-cover .widget-card-cover-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  margin: -25% 0 0 -25%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.75);
  color: rgba(0, 0, 0, 0.75);
  text-align: center;
}
.widget-card .widget-card-cover .widget-card-cover-icon .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.85vw;
  margin-left: -1vw;
  font-size: 2vw;
  line-height: 2vw;
  height: 2vw;
  width: 2vw;
}
.widget-card.square .widget-card-content.bottom {
  top: auto;
}
.inverse-mode .widget-card,
.inverse-mode.widget-card,
.inverse-mode a.widget-card,
a.inverse-mode.widget-card {
  color: #fff;
}
.inverse-mode .widget-card .widget-card-cover,
.inverse-mode.widget-card .widget-card-cover {
  background-color: #2d353c;
}

/* 6.18 Widget - Widget Chart (Added in V4.2) */

.widget-chart-info {
  color: #2d353c;
}
.widget-chart-info-title {
  margin: 0 0 5px;
  font-size: 14px;
}
.widget-chart-info-desc {
  font-size: 11px;
  color: #707478;
  margin-bottom: 20px;
}
.widget-chart-info-progress {
  font-size: 10px;
  margin-bottom: 5px;
}
.widget-chart-info .progress {
  margin-bottom: 0;
}
.widget-chart-content {
  background: #f0f3f4;
  padding: 10px;
}
.inverse-mode .widget-chart-content {
  background: #242a31;
}
.inverse-mode .widget-chart-info {
  color: #a8acb1;
}
.inverse-mode .nvd3 text {
  fill: rgba(255, 255, 255, 0.75);
}
.inverse-mode .nvd3 .nv-axis line,
.inverse-mode .nvd3 .nv-axis path {
  stroke: rgba(255, 255, 255, 0.15);
}

/* 6.19 Widget - Widget Table (Added in V4.2) */

.table.widget-table {
  background: #fff;
  border-radius: 4px;
}
.table.table-bordered.widget-table {
  border: none;
}
.table.table-bordered.widget-table > thead:first-child > tr:first-child > td,
.table.table-bordered.widget-table > thead:first-child > tr:first-child > th {
  border-top: none;
}
.table.table-bordered.widget-table > thead > tr > th:first-child,
.table.table-bordered.widget-table > tbody > tr > td:first-child {
  border-left: none;
}
.table.table-bordered.widget-table > thead > tr > th:last-child,
.table.table-bordered.widget-table > tbody > tr > td:last-child {
  border-right: none;
}
.table.table-bordered.widget-table > tbody > tr:last-child > td {
  border-bottom: none;
}
.widget-table-img {
  width: 100px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}
.widget-table-title {
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}
.widget-table-desc {
  margin: 0;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
}
.inverse-mode .table.widget-table,
.inverse-mode.table.widget-table {
  background: #2d353c;
  color: #a8acb1;
}
.inverse-mode .table thead th,
.inverse-mode .table > thead > tr > th,
.inverse-mode.table thead th,
.inverse-mode.table > thead > tr > th {
  color: #fff;
}
.inverse-mode h1,
.inverse-mode h2,
.inverse-mode h3,
.inverse-mode h4,
.inverse-mode h5,
.inverse-mode h6 {
  color: #fff;
}
.inverse-mode.table.widget-table > thead > tr > th,
.inverse-mode.table.widget-table > tbody > tr > th,
.inverse-mode.table.widget-table > tfoot > tr > th,
.inverse-mode.table.widget-table > thead > tr > td,
.inverse-mode.table.widget-table > tbody > tr > td,
.inverse-mode.table.widget-table > tfoot > tr > td {
  border-color: #575d63 !important;
}

/* -------------------------------
   7.0 Pages
------------------------------- */

/* 7.1 Page - Search Result */

.result-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.result-list:before,
.result-list:after {
  content: "";
  display: table;
  clear: both;
}
.result-list > li {
  background: #fff;
  box-shadow: 0 1px #ccc;
  border-radius: 3px;
  overflow: hidden;
}
.result-list > li:before,
.result-list > li:after {
  content: "";
  display: table;
  clear: both;
}
.result-list > li + li {
  margin-top: 10px;
}
.result-list > li > div {
  display: table-cell;
  vertical-align: top;
  padding: 20px;
  position: relative;
}
.result-list .result-image {
  width: 240px;
  padding: 0;
  overflow: hidden;
  background: #2d353c;
  vertical-align: middle;
  display: table-cell;
  background-size: cover;
  background-position: center;
}
.result-list .result-image a {
  display: block;
}
.result-list .result-image a:hover,
.result-list .result-image a:focus {
  opacity: 0.8;
}
.result-list .result-image img {
  width: 100%;
}
.result-list .title {
  margin: 0 0 0px;
  font-size: 18px;
  line-height: 26px;
}
.result-list .title a {
  color: #2e353c;
}
.result-list .location {
  color: #929ba1;
  margin-bottom: 15px;
}
.result-list .desc {
  margin-bottom: 20px;
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}
.result-list .btn-row:before,
.result-list .btn-row:after {
  content: "";
  display: table;
  clear: both;
}
.result-list .btn-row a {
  color: #2e353c;
  font-size: 14px;
  line-height: 18px;
  float: left;
  padding: 8px 10px;
  border-radius: 4px;
  background: #f2f3f4;
}
.result-list .btn-row a:hover,
.result-list .btn-row a:focus {
  opacity: 1;
}
.result-list .btn-row a + a {
  margin-left: 5px;
}
.result-list .result-price {
  width: 240px;
  font-size: 28px;
  text-align: center;
  background: #f2f3f4;
  color: #2e353c;
  vertical-align: middle;
}
.result-list .result-price small {
  display: block;
  font-size: 10px;
  font-weight: 600;
  color: #929ba1;
}
.result-list .result-price .btn {
  margin-top: 30px;
}

/* 7.2 Page - Vector / Google Map */

.map {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 220px;
  right: 0;
  z-index: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.map .jvectormap-zoomin {
  top: 70px;
}
.map .jvectormap-zoomout {
  top: 100px;
}
.map .jvectormap-zoomin,
.map .jvectormap-zoomout {
  width: 20px !important;
  height: 20px !important;
  font-size: 14px;
  line-height: 20px !important;
  left: 20px !important;
}
.map-content {
  padding: 0 25px;
  z-index: 100;
  position: relative;
}
.map-float-table {
  position: absolute;
  right: 25px;
  bottom: 25px;
  background: rgba(28, 31, 37, 0.8);
}
.flat-black .map-float-table {
  background: rgba(28, 28, 28, 0.8);
}
.page-with-wide-sidebar .map {
  left: 250px;
}
.page-with-right-sidebar .map {
  right: 220px;
  left: 0;
}
.page-with-wide-sidebar.page-with-right-sidebar .map {
  right: 250px;
  left: 0;
}
.page-sidebar-minified .map {
  left: 60px;
}
.page-with-right-sidebar.page-sidebar-minified .map {
  right: 60px;
}

/* 7.3 Page - Invoice */

.invoice {
  background: #fff;
  padding: 20px;
}
.invoice-company {
  font-size: 20px;
}
.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px;
}
.invoice-from,
.invoice-to,
.invoice-date {
  display: table-cell;
  width: 1%;
}
.invoice-from,
.invoice-to {
  padding-right: 20px;
}
.invoice-from strong,
.invoice-to strong,
.invoice-date .date {
  font-size: 16px;
  font-weight: 600;
}
.invoice-date {
  text-align: right;
  padding-left: 20px;
}
.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%;
}
.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle;
}
.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
}
.invoice-price small {
  font-size: 12px;
  font-weight: normal;
  display: block;
}
.invoice-price .invoice-price-row {
  display: table;
  float: left;
}
.invoice-price .invoice-price-right {
  width: 25%;
  background: #2d353c;
  color: #fff;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 300;
}
.invoice-price .invoice-price-right small {
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}
.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px;
}
.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%;
}
.invoice > div:not(.invoice-footer) {
  margin-bottom: 20px;
}

/* 7.4 Page - Login */

.login {
  margin: -168px 0 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.login label {
  color: #707478;
}
.login .login-header {
  position: absolute;
  top: -80px;
  left: 50%;
  right: 0;
  width: 450px;
  padding: 0 40px;
  margin-left: -225px;
  font-weight: 300;
}
.login .login-header .brand {
  padding: 0;
  font-size: 28px;
  color: #2d353c;
}
.login .login-header .brand .logo {
  border: 14px solid transparent;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3);
  width: 28px;
  height: 28px;
  position: relative;
  font-size: 0;
  margin-right: 10px;
  border-radius: 6px;
  top: -11px;
}
.login .login-header .brand small {
  font-size: 14px;
  display: block;
  color: #707478;
}
.login .login-header .icon {
  position: absolute;
  right: 40px;
  top: -2px;
  opacity: 0.1;
}
.login .login-header .icon i {
  font-size: 60px;
}
.login .login-content {
  padding: 30px 40px;
  color: #999;
  width: 450px;
  margin: 0 auto;
}

.register .register-header {
  position: absolute;
  top: -40px;
  left: 50%;
  right: 0;
  width: 450px;
  padding: 0 40px;
  margin-left: -225px;
  font-weight: 300;
}
.register .register-header .brand {
  padding: 0;
  font-size: 28px;
  color: #702963;
}
.register .register-header .brand .logo {
  border: 14px solid transparent;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3);
  width: 28px;
  height: 28px;
  position: relative;
  font-size: 0;
  margin-right: 10px;
  border-radius: 6px;
  top: -11px;
}
.register .register-header .brand small {
  font-size: 14px;
  display: block;
  color: #707478;
}
.register .register-header .icon {
  position: absolute;
  right: 40px;
  top: -2px;
  opacity: 0.1;
}
.register .register-header .icon i {
  font-size: 60px;
}
.register .register-content {
  padding: 30px 40px;
  color: #999;
  width: 450px;
  margin: 0 auto;
}

/* 7.5 Page - Gallery */

.gallery {
  margin: -5px;
}
.gallery .image {
  width: 25%;
  display: block;
  overflow: hidden;
  padding: 5px;
}
.gallery .image a {
  transition: all 0.2s linear;
}
.gallery .image a:hover,
.gallery .image a:focus {
  opacity: 0.8;
}
.gallery .image img {
  width: 100%;
  height: 200px;
  border-radius: 3px 3px 0 0;
}
.gallery .image-inner {
  position: relative;
  background: #fff;
  border-radius: 3px 3px 0 0;
}
.gallery .image-caption {
  position: absolute;
  top: 15px;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 15px;
  margin: 0;
}
.gallery .image-info {
  background: #fff;
  padding: 15px;
  border-radius: 0 0 3px 3px;
}
.gallery .image-info .title {
  margin: 0 0 10px;
  line-height: 18px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gallery .image-info .rating {
  line-height: 20px;
  margin: 0 0 3px;
}
.gallery .image-info .desc {
  line-height: 16px;
  font-size: 12px;
  height: 48px;
  overflow: hidden;
}
.gallery .rating span.star {
  display: inline-block;
}
.gallery .rating span.star:before {
  content: "\f005";
  color: #999;
}
.gallery .rating span.star.active:before {
  color: #ff8500;
}
.gallery-option-set {
  display: block;
  margin-bottom: -5px;
}
.gallery-option-set .btn {
  margin: 0 5px 5px 0;
}

/* 7.6 Page - 404 Error */

.error {
  margin: 0 auto;
  text-align: center;
}
.error-code,
.error-content {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.error-code {
  bottom: 45%;
  color: #2d353c;
  font-size: 200px;
  line-height: 200px;
  text-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
}
.error-content {
  top: 55%;
  bottom: 0;
  padding-top: 25px;
  background: #2d353c;
  background-image: url(images/error-page-pattern.png);
}
.error-message {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.error-desc {
  font-size: 12px;
  color: #fff;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
}

/* 7.7 Page - Timeline (Added in V1.0) */

.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 5px;
  background: #2d353c;
  left: 20%;
  margin-left: -2.5px;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  padding: 20px 0;
}
.timeline .timeline-time {
  position: absolute;
  left: 0;
  width: 18%;
  text-align: right;
  top: 30px;
}
.timeline .timeline-time .date,
.timeline .timeline-time .time {
  display: block;
  font-weight: 600;
}
.timeline .timeline-time .date {
  line-height: 16px;
  font-size: 12px;
}
.timeline .timeline-time .time {
  line-height: 24px;
  font-size: 20px;
  color: #242a30;
}
.timeline .timeline-icon {
  left: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  top: 40px;
}
.timeline .timeline-icon a {
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 20px;
  background: #d9e0e7;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  border: 5px solid #2d353c;
  transition: border-color 0.2s linear;
}
.timeline .timeline-body {
  margin-left: 23%;
  margin-right: 17%;
  background: #fff;
  position: relative;
  padding: 20px 25px;
  border-radius: 6px;
}
.timeline .timeline-body:before {
  content: "";
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #fff;
  left: -20px;
  top: 20px;
}
.timeline .timeline-body > div + div {
  margin-top: 15px;
}
.timeline .timeline-body > div + div:last-child {
  margin-bottom: -20px;
  padding-bottom: 20px;
  border-radius: 0 0 6px 6px;
}
.timeline-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e7eb;
  line-height: 30px;
}
.timeline-header .userimage {
  float: left;
  width: 34px;
  height: 34px;
  border-radius: 40px;
  overflow: hidden;
  margin: -2px 10px -2px 0;
}
.timeline-header .username {
  font-size: 16px;
  font-weight: 600;
}
.timeline-header .username,
.timeline-header .username a {
  color: #2d353c;
}
.timeline img {
  max-width: 100%;
  display: block;
}
.timeline-content {
  letter-spacing: 0.25px;
  line-height: 18px;
  font-size: 13px;
}
.timeline-content:before,
.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.timeline-title {
  margin-top: 0;
}
.timeline-footer {
  background: #fff;
  border-top: 1px solid #e2e7ec;
  padding-top: 15px;
}
.timeline-footer a:not(.btn) {
  color: #575d63;
}
.timeline-footer a:not(.btn):hover,
.timeline-footer a:not(.btn):focus {
  color: #2d353c;
}
.timeline-likes {
  color: #6d767f;
  font-weight: 600;
  font-size: 12px;
}
.timeline-likes .stats-right {
  float: right;
}
.timeline-likes .stats-total {
  display: inline-block;
  line-height: 20px;
}
.timeline-likes .stats-icon {
  float: left;
  margin-right: 5px;
  font-size: 9px;
}
.timeline-likes .stats-icon + .stats-icon {
  margin-left: -2px;
}
.timeline-likes .stats-text {
  line-height: 20px;
}
.timeline-likes .stats-text + .stats-text {
  margin-left: 15px;
}
.timeline-comment-box {
  background: #f2f3f4;
  margin-left: -25px;
  margin-right: -25px;
  padding: 20px 25px;
}
.timeline-comment-box .user {
  float: left;
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 30px;
}
.timeline-comment-box .user img {
  max-width: 100%;
  max-height: 100%;
}
.timeline-comment-box .user + .input {
  margin-left: 44px;
}

/* 7.8 Page - Coming Soon (Added in V1.0) */

.coming-soon .brand {
  line-height: 40px;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
}
.coming-soon .brand .logo {
  border: 25px solid transparent;
  border-color: #394449 #2a3237 #1b2123;
  position: relative;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto 5px;
  vertical-align: middle;
  border-radius: 8px;
}
.coming-soon .coming-soon-header .desc,
.coming-soon .coming-soon-header .timer,
.coming-soon .coming-soon-header .brand {
  position: relative;
  z-index: 1020;
}
.coming-soon .coming-soon-header {
  background: url(images/coming-soon.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 50px;
  position: relative;
}
.coming-soon .coming-soon-header:before {
  background: #000;
}
.coming-soon .coming-soon-header .bg-cover {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#bf000000',GradientType=0 );
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.coming-soon .coming-soon-header .desc {
  text-align: center;
  font-size: 13px;
  color: #fff;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}
.coming-soon .coming-soon-header .timer {
  margin-bottom: 0px;
  padding: 10px 0;
}
.coming-soon .coming-soon-header .timer:before,
.coming-soon .coming-soon-header .timer:after {
  content: "";
  display: table;
  clear: both;
}
.coming-soon .timer .is-countdown {
  background: none;
  border: none;
  width: 720px;
  margin: 0 auto;
}
.coming-soon .timer .is-countdown .countdown-section {
  padding: 0 10px;
}
.coming-soon .timer .is-countdown .countdown-section + .countdown-section {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.coming-soon .timer .countdown-amount {
  font-size: 36px;
  color: #fff;
}
.coming-soon .timer .countdown-period {
  font-size: 14px;
  color: #999;
}
.coming-soon a {
  color: #00acac;
}
.coming-soon .coming-soon-content {
  color: #333;
  text-align: center;
  padding: 25px 0;
}
.coming-soon .coming-soon-content .desc {
  margin-bottom: 25px;
  font-size: 13px;
}
.coming-soon .coming-soon-content .input-group {
  width: 480px;
  margin: 0 auto;
}

/* 7.9 Page - Login V2 (Added in V1.2)  */

.login.login-v2 {
  color: #ccc;
  width: 400px;
  margin: 168px 0 0 -200px;
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 4px;
}
.login.login-v2 a {
  color: #fff;
}
.login.login-v2 label {
  color: #ccc;
}
.login.login-v2 .login-content {
  padding: 20px;
  width: 400px;
}
.login.login-v2 .login-header {
  width: 400px;
  padding: 0 20px;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
}
.login.login-v2 .login-header,
.login.login-v2 .login-header .brand,
.login.login-v2 .login-header .brand small {
  color: #fff;
}
.login.login-v2 .login-header .brand small {
  color: rgba(255, 255, 255, 0.5);
}
.login.login-v2 .login-header .icon {
  opacity: 0.4;
  right: 20px;
  top: 2px;
}
.login.login-v2 .login-header .icon i {
  font-size: 56px;
}
.login.login-v2 .form-control {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  color: #fff;
}
.login.login-v2 .form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.3);
}
.login-cover,
.login-cover-bg,
.login-cover-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background 0.2s linear;
}
.login-cover-image img {
  max-width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.login-cover-bg {
  background: rgba(0, 0, 0, 0.5);
}
.login-bg-list {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1020;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 20px;
}
.login-bg-list > li {
  width: 66px;
  height: 66px;
  overflow: hidden;
  float: left;
  transition: all 0.2s linear;
  border: 3px solid transparent;
  border-radius: 66px;
}
.login-bg-list > li:hover,
.login-bg-list > li:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.login-bg-list > li a {
  display: block;
  height: 60px;
  height: 60px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login-bg-list > li.active {
  border-color: #fff;
  z-index: 1020;
  position: relative;
}
.login-bg-list > li img {
  max-height: 100%;
}

/* 7.10 Page - Email Inbox (Added in V1.4) */

.list-email {
  margin: -1px 0;
  border-top: 1px solid #e2e7eb;
  border-bottom: 1px solid #e2e7eb;
}
.list-email > li.list-group-item {
  border-radius: 0;
  border: none;
  margin-bottom: 0px;
}
.list-email > li.list-group-item {
  padding: 15px 15px 15px 20px;
  position: relative;
}
.list-email > li.list-group-item + li.list-group-item {
  border-top: 1px solid #e2e7eb;
}
.list-email > li.list-group-item:before,
.list-email > li.list-group-item:after {
  content: "";
  display: table;
  clear: both;
}
.list-email .email-info,
.list-email .email-sender,
.list-email .email-info > a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.list-email .email-info {
  position: relative;
  padding-right: 100px;
}
.list-email .email-user {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  overflow: hidden;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #6e7179;
  background: #f4f6f7;
  margin: -5px 0;
}
.list-email .email-user img {
  max-width: 100%;
  display: block;
}
.list-email .email-user + .email-info {
  margin-left: 45px;
}
.list-email .email-checkbox + .email-user + .email-info {
  margin-left: 68px;
}
.list-email .email-checkbox + .email-info {
  margin-left: 23px;
}
.list-email .email-title {
  line-height: 20px;
}
.list-email .unread .email-title {
  color: #2b71b5;
  font-weight: 600;
}
.list-email .email-title + .email-desc {
  padding-left: 20px;
}
.list-email .email-title + .email-desc:before {
  content: "";
  position: absolute;
  left: 6px;
  top: 7px;
  width: 6px;
  height: 6px;
  background: #d9dfe3;
  border-radius: 6px;
}
.list-email .email-desc {
  position: relative;
  color: #5c656d;
}
.list-email .email-sender {
  width: 230px;
  float: left;
  padding-right: 15px;
}
.list-email .unread .email-sender {
  font-weight: 600;
}
.list-email .email-title .label {
  position: relative;
  top: -1px;
}
.list-email a {
  color: #242a30;
  text-decoration: none;
}
.list-email .email-info,
.list-email .email-info > a {
  display: block;
  margin-top: -15px;
  margin-bottom: -15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.list-email .email-info > a {
  margin-right: -115px;
  padding-right: 100px;
  position: relative;
}
.list-email .email-time {
  position: absolute;
  width: 100px;
  padding: 15px;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 11px;
  color: #9ba3ab;
  text-align: right;
}
.list-email .email-checkbox {
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: -5px 5px -5px -12px;
}
.list-email .email-checkbox label {
  color: #e2e7eb;
  display: block;
  cursor: pointer;
}
.list-email .email-checkbox label input[type="checkbox"] {
  display: none;
}
.list-email .email-checkbox label i {
  font-size: 16px;
  display: block;
  line-height: 30px;
  transition: all 0.2s linear;
}
.list-email .email-checkbox label.active {
  color: #2e353c;
}
.list-email .email-checkbox label.active i:before {
  content: "\f14a";
}
.list-email > li.selected {
  background: #e2e7ec;
}

/* 7.11 Page - Email Detail (Added in V1.4) */

.attached-document {
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;
}
.attached-document > li {
  width: 180px;
  float: left;
  background: #fff;
  color: #2e353c;
  font-weight: 600;
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #d9dfe5;
}
.attached-document > li:before {
  position: absolute;
  top: -1px;
  right: -1px;
  font-size: 12px;
  background: #d9dfe5;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
.attached-document > li img {
  max-width: 100%;
}
.attached-document > li .document-name {
  padding: 5px 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f1f3f4;
}
.attached-document > li .document-name a {
  color: #2d353c;
}
.attached-document > li .document-file {
  height: 70px;
  background: none;
  overflow: hidden;
  text-align: center;
  line-height: 70px;
  font-size: 32px;
  margin: -1px;
}

/* 7.12 Page - Email Compose (Added in V1.4) */

.inbox .nav-title {
  font-size: 10px;
  color: #929ba1;
  padding: 15px 13px 7px;
}
.inbox .nav-inbox {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.inbox .nav-inbox > li {
  display: block;
}
.inbox .nav-inbox > li > a {
  display: block;
  text-decoration: none;
  border-radius: 0;
  padding: 8px 20px;
  color: #702963;
  font-weight: 600;
}
.inbox .nav-inbox > li > a i {
  width: 18px;
}
.inbox .nav-inbox > li + li {
  margin-top: 0;
}
.inbox .nav-inbox > li.active > a,
.inbox .nav-inbox > li.active > a:hover,
.inbox .nav-inbox > li.active > a:focus {
  background: #d9dfe3;
  color: #2d353c;
}
.inbox .nav-inbox > li > a .badge,
.inbox .nav-inbox > li.active > a .badge {
  background: #2d353c;
  color: #fff;
  padding: 4px 8px;
  margin-top: -1px;
  font-size: 10px;
  line-height: 11px;
}
.inbox .float-right-link {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 13px;
}
.inbox .email-to {
  position: relative;
}
.inbox .email-to .tagit,
.inbox .email-to .form-control {
  border: none !important;
  border-bottom: 1px solid #ccd0d4 !important;
  padding: 9px 12px 9px 45px !important;
  border-radius: 0;
}
.inbox .tagit input[type="text"] {
  font-weight: 500;
}
.inbox .email-subject .form-control {
  border: none !important;
  border-bottom: 1px solid #ccd0d4 !important;
  padding: 9px 12px 9px 12px !important;
  border-radius: 0;
}
.inbox .email-subject .form-control:focus {
  box-shadow: none;
}
.inbox .email-to .control-label {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 13px;
  padding-left: 10px;
  line-height: 16px;
  width: 45px;
}
.inbox .email-content {
  padding: 0;
  margin: 0;
}
.inbox .email-content .wysihtml5-toolbar {
  padding: 10px 0 0;
}
.inbox .email-content .wysihtml5-toolbar .btn {
  font-size: 12px;
  margin-bottom: 5px;
}
.inbox .email-content .wysihtml5-toolbar > li {
  margin: 0 5px 5px 0;
}
.inbox .email-content .wysihtml5-sandbox {
  border-radius: 0 !important;
  padding: 15px !important;
  border: 1px solid #ccd0d4 !important;
}

/* 7.13 Page - Login & Register V3 (Added in V1.7) */

.login.login-with-news-feed,
.register.register-with-news-feed {
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
}
.login.login-with-news-feed .news-feed,
.register.register-with-news-feed .news-feed {
  position: fixed;
  left: 0;
  right: 500px;
  top: 0;
  bottom: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  overflow: hidden;
}
.login.login-with-news-feed .news-image,
.register.register-with-news-feed .news-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login.login-with-news-feed .news-image img,
.register.register-with-news-feed .news-image img {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  max-height: 100%;
  min-width: 100%;
  top: -1960px;
  bottom: -1960px;
  left: -1960px;
  right: -1960px;
  margin: auto;
}
.login.login-with-news-feed .news-caption,
.register.register-with-news-feed .news-caption {
  color: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 60px 30px;
  font-size: 18px;
  z-index: 20;
  font-weight: 300;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}
.login.login-with-news-feed .news-caption .caption-title,
.register.register-with-news-feed .news-caption .caption-title {
  color: #fff;
  font-weight: 300;
  font-size: 36px;
}
.login.login-with-news-feed .right-content,
.register.register-with-news-feed .right-content {
  float: right;
  width: 500px;
}
.login.login-with-news-feed .login-content,
.register.register-with-news-feed .register-content {
  width: auto;
  padding: 30px 60px;
}
.register.register-with-news-feed .register-content {
  padding: 20px 60px 30px;
}
.login.login-with-news-feed .login-header,
.register.register-with-news-feed .register-header {
  position: relative;
  top: 0;
  margin: 0;
  left: 0;
  right: 0;
  padding: 100px 60px 0;
}
.register.register-with-news-feed .register-header {
  padding: 0px 60px 0;
  font-size: 32px;
}
.register.register-with-news-feed .register-header small {
  color: #707478;
  display: block;
  font-size: 14px;
  margin-top: 10px;
  line-height: 20px;
}
.login.login-with-news-feed .login-header .brand {
  color: #242a30;
}
.login.login-with-news-feed .login-header .brand small {
  color: #707478;
}
.login.login-with-news-feed .login-header .icon {
  top: 98px;
  right: 10px;
}

/* 7.14 Page - Profile (Added in V1.7) */

.profile-header {
  position: relative;
  overflow: hidden;
}
.profile-header .profile-header-cover {
  background-image: url(images/profile-cover.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.profile-header .profile-header-cover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}
.profile-header .profile-header-content {
  color: #fff;
  padding: 25px;
  font-size: 1.5em;
}
.profile-header-img {
  float: left;
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin: 0 0 -20px 0;
  padding: 3px;
  border-radius: 4px;
  background: #fff;
}
.profile-header-img img {
  max-width: 100%;
}
.profile-header-info h4 {
  font-weight: 500;
  color: #fff;
}
.profile-header-img + .profile-header-info {
  margin-left: 140px;
}
.profile-header .profile-header-content,
.profile-header .profile-header-tab {
  position: relative;
}
.profile-header .profile-header-tab {
  background: #fff;
  list-style-type: none;
  margin: -10px 0 0;
  padding: 0 0 0 140px;
  white-space: nowrap;
  border-radius: 0;
}
.profile-header .profile-header-tab > li {
  display: inline-block;
  margin: 0;
}
.profile-header .profile-header-tab > li > a {
  display: block;
  color: #929ba1;
  line-height: 20px;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  border: none;
}
.profile-header .profile-header-tab > li.active > a,
.profile-header .profile-header-tab > li > a.active {
  color: #242a30;
}
.profile-content {
  padding: 25px;
  border-radius: 4px;
}
.profile-content:before,
.profile-content:after {
  content: "";
  display: table;
  clear: both;
}
.profile-content .tab-content,
.profile-content .tab-pane {
  background: none;
}
.profile-left {
  width: 200px;
  float: left;
}
.profile-right {
  margin-left: 240px;
  padding-right: 20px;
}
.profile-image {
  height: 175px;
  line-height: 175px;
  text-align: center;
  font-size: 72px;
  margin-bottom: 10px;
  border: 2px solid #e2e7eb;
  overflow: hidden;
  border-radius: 4px;
}
.profile-image img {
  display: block;
  max-width: 100%;
}
.profile-highlight {
  padding: 12px 15px;
  background: #fefde1;
  border-radius: 4px;
}
.profile-highlight h4 {
  margin: 0 0 7px;
  font-size: 12px;
  font-weight: bold;
}
.table.table-profile > thead > tr > th {
  border-bottom: none !important;
}
.table.table-profile > thead > tr > th h4 {
  font-size: 20px;
  margin-top: 0;
}
.table.table-profile > thead > tr > th h4 small {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
}
.table.table-profile > thead > tr > th,
.table.table-profile > tbody > tr > td {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #242a30;
  background: none;
}
.table.table-profile > tbody > tr > td.field {
  width: 20%;
  text-align: right;
  font-weight: 600;
  color: #702963;
}
.table.table-profile > tbody > tr.highlight > td {
  border-top: 1px solid #b9c3ca;
  border-bottom: 1px solid #b9c3ca;
}
.table.table-profile > tbody > tr.divider > td {
  padding: 0 !important;
  height: 10px;
}
.profile-section + .profile-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #b9c3ca;
}
.profile-section:before,
.profile-section:after {
  content: "";
  display: table;
  clear: both;
}
.profile-section .title {
  font-size: 20px;
  margin: 0 0 15px;
}
.profile-section .title small {
  font-weight: normal;
}

/* -------------------------------
   8.0 Content Theme Flat Black
------------------------------- */

/* 8.1 Flat Black - Body Setting */

body.flat-black {
  background: #e7e7e7;
}

/* 8.2 Flat Black -  Navbar Inverse Setting */

.flat-black .navbar.navbar-inverse {
  background: #212121;
}
.flat-black .navbar.navbar-inverse .navbar-form .form-control {
  background: #4a4a4a;
  border-color: #4a4a4a;
}

/* 8.3 Flat Black - Sidebar Setting */

.flat-black .sidebar,
.flat-black .sidebar-bg {
  background: #3a3a3a;
}
.flat-black .page-with-light-sidebar .sidebar,
.flat-black .page-with-light-sidebar .sidebar-bg {
  background: #fff;
}
.flat-black .sidebar .nav > li > a {
  color: #b2b2b2;
}
.flat-black .sidebar.sidebar-grid .nav > li > a {
  border-bottom: 1px solid #474747;
  border-top: 1px solid #474747;
}
.sidebar .nav > li.nav-profile > a,
.flat-black .sidebar .nav > li > a:focus,
.flat-black .sidebar .nav > li > a:hover,
.flat-black .sidebar .nav > li.active > a,
.flat-black .sidebar .sub-menu > li > a:hover,
.flat-black .sidebar .sub-menu > li > a:focus,
.flat-black .sidebar .active .sub-menu > li.active > a {
  color: #fff;
}
.flat-black .sidebar .sub-menu > li > a,
.flat-black .sidebar .sub-menu > li > a:before {
  color: #999;
}
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.flat-black .page-with-light-sidebar .sidebar .active .sub-menu > li.active > a,
.flat-black
  .page-with-light-sidebar
  .sidebar
  .active
  .sub-menu
  > li.active
  > a:hover,
.flat-black
  .page-with-light-sidebar
  .sidebar
  .active
  .sub-menu
  > li.active
  > a:focus {
  color: #000;
}
.flat-black .page-sidebar-minified .sidebar .nav > li.has-sub:hover > a,
.flat-black .page-sidebar-minified .sidebar .nav > li.has-sub:focus > a {
  background: #323232;
}
.flat-black .sidebar .nav > li.active > a,
.flat-black .sidebar .nav > li.active > a:focus,
.flat-black .sidebar .nav > li.active > a:hover,
.flat-black .sidebar .sub-menu > li:before,
.flat-black .sidebar .sub-menu > li > a:after,
.flat-black .sidebar .sub-menu > li.has-sub > a:before,
.flat-black .page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu,
.flat-black .sidebar .nav > li.nav-profile {
  background: #2a2a2a;
}
.flat-black .page-sidebar-minified .sidebar .sub-menu > li:before,
.flat-black .page-sidebar-minified .sidebar .sub-menu > li > a:after,
.flat-black .page-sidebar-minified .sidebar .sub-menu > li > a:after {
  background: #3e3e3e;
}
.flat-black .sidebar .nav > li.nav-profile .cover.with-shadow:before {
  background: rgba(42, 42, 42, 0.75);
}

/* 8.4 Flat Black - Page Footer Setting */

.flat-black .footer {
  border-color: #d7d7d7;
}

/* 8.5 Flat Black - List Group item Setting */

.flat-black .list-group-item.list-group-item-inverse {
  background: #3a3a3a;
  border-color: #242424;
}
.flat-black .list-group-item.list-group-item-inverse:hover,
.flat-black .list-group-item.list-group-item-inverse:focus {
  background: #323232;
}

/* 8.6 Flat Black - Nav Pills Setting */

.flat-black .nav-pills > li.active > a,
.flat-black .nav-pills > li.active > a:focus,
.flat-black .nav-pills > li.active > a:hover {
  background: #2a2a2a;
}

/* 8.7 Flat Black - Badge Setting */

.flat-black .badge.badge-inverse,
.flat-black .label.label-inverse {
  background: #3a3a3a;
}
.flat-black .badge.badge-default,
.flat-black .label.label-default {
  background: #c3c3c3;
}

/* 8.8 Flat Black - Tabs Setting */

.flat-black .nav-tabs {
  background: #d2d2d2;
}
.flat-black .nav-tabs-inverse > .nav-tabs,
.flat-black .nav-tabs.nav-tabs-inverse {
  background: #2a2a2a;
}

/* 8.9 Flat Black - Panel Setting */

.flat-black .panel-with-tabs .panel-heading {
  background: #d2d2d2;
}
.flat-black .panel-inverse > .panel-heading {
  background: #2a2a2a;
}

/* 8.10 Flat Black - Table Setting */

.flat-black .table > tbody > tr > td,
.flat-black .table > tbody > tr > th,
.flat-black .table > tfoot > tr > td,
.flat-black .table > tfoot > tr > th,
.flat-black .table > thead > tr > td,
.flat-black .table > thead > tr > th {
  border-color: #e0e0e0;
}
.flat-black .table > thead > tr > th {
  border-color: #e0e0e0 !important;
}
.flat-black .dataTables_filter input {
  background: #f7f7f7;
  border-color: #f7f7f7;
}
.flat-black .dataTables_filter input:focus {
  border-color: #d7d7d7;
}
.flat-black .table-striped > tbody > tr:nth-child(odd) > td,
.flat-black .table-striped > tbody > tr:nth-child(odd) > th {
  background: #f7f7f7;
}

/* 8.11 Flat Black - Button Inverse Setting */

.flat-black .btn.btn-inverse {
  background: #3a3a3a;
  border-color: #242424;
}
.flat-black .btn-inverse:hover,
.flat-black .btn-inverse:focus,
.flat-black .btn-inverse:active,
.flat-black .btn-inverse.active,
.flat-black .open .dropdown-toggle.btn-inverse {
  background: #2a2a2a;
}

/* 8.12 Flat Black - Button Default Setting */

.flat-black .btn.btn-default {
  background: #c8c8c8;
  border-color: #c8c8c8;
}
.flat-black .btn.btn-default:hover,
.flat-black .btn.btn-default:focus,
.flat-black .btn.btn-default:active,
.flat-black .btn.btn-default.active,
.flat-black .open .dropdown-toggle.btn.btn-default {
  background: #a3a3a3;
  border-color: #a3a3a3;
}

/* 8.13 Flat Black - Button White Setting */

.flat-black .btn.btn-white,
.flat-black .btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.flat-black .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
  border-color: #d2d2d2;
}
.flat-black .btn.btn-white:hover,
.flat-black .btn.btn-white:focus,
.flat-black .btn.btn-white:active,
.flat-black .btn.btn-white.active,
.flat-black .open .dropdown-toggle.btn.btn-white {
  background: #e0e0e0;
  border-color: #d2d2d2;
}

/* 8.14 Flat Black - Pagination Setting */

.flat-black .pager li > a,
.flat-black .pager li > span,
.flat-black .pagination > li > a {
  border-color: #d2d2d2;
}
.flat-black .pagination > .active > a,
.flat-black .pagination > .active > a:focus,
.flat-black .pagination > .active > a:hover,
.flat-black .pagination > .active > span,
.flat-black .pagination > .active > span:focus,
.flat-black .pagination > .active > span:hover {
  background: #323232 !important;
  border-color: #323232 !important;
}
.flat-black .pager li > a:focus,
.flat-black .pager li > a:hover,
.flat-black .pager li > span:focus,
.flat-black .pager li > span:hover,
.flat-black .pagination > li > a:focus,
.flat-black .pagination > li > a:hover {
  background: #e0e0e0;
}

/* 8.15 Flat Black -  Progress Bar Setting */

.flat-black .progress {
  background: #eaeaea;
}
.flat-black .widget-stats .stats-progress {
  background: rgba(0, 0, 0, 0.2);
}

/* 8.16 Flat Black - Vector Map Setting */

.flat-black .jvectormap-container {
  background: #2d2d2d !important;
}

/* 8.17 Flat Black - Helper Classes Setting */

.flat-black .bg-black {
  background: #3a3a3a !important;
}
.flat-black .bg-silver {
  background: #f7f7f7 !important;
}
.flat-black .bg-black-darker {
  background: #2d2d2d !important;
}

/* 8.18 Flat Black - Bootstrap Wizard Setting */

.flat-black .bwizard-steps li.active,
.flat-black .bwizard-steps li.active:hover,
.flat-black .bwizard-steps li.active:focus {
  background: #00acac !important;
}
.flat-black .bwizard-steps li:hover,
.flat-black .bwizard-steps li:focus {
  background: #dfdfdf !important;
}
.flat-black .bwizard-steps li {
  background: #f7f7f7 !important;
}

/* 8.19 Flat Black - Timeline */

.flat-black .timeline-footer {
  background: #f2f2f2;
}

/* 8.20 Flat Black - Invoice */

.flat-black .invoice-header,
.flat-black .invoice-price {
  background: #f5f5f5;
}
.flat-black .invoice-price .invoice-price-right {
  background: #3a3a3a;
}

/* 8.21 Flat Black - Error Page */

.flat-black .error-content {
  background: #3a3a3a;
}
.flat-black .error-code {
  color: #3a3a3a;
}
.flat-black .error-desc {
  color: #888;
}

/* -------------------------------
   9.0 Predefined CSS Setting
------------------------------- */

/* 9.1 Predefined Classes */

.row {
  margin-left: -10px;
  margin-right: -10px;
}
.row > [class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.row.row-space-0 {
  margin-left: 0;
  margin-right: 0;
}
.row.row-space-2 {
  margin-left: -1px;
  margin-right: -1px;
}
.row.row-space-4 {
  margin-left: -2px;
  margin-right: -2px;
}
.row.row-space-6 {
  margin-left: -3px;
  margin-right: -3px;
}
.row.row-space-8 {
  margin-left: -4px;
  margin-right: -4px;
}
.row.row-space-10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row.row-space-12 {
  margin-left: -6px;
  margin-right: -6px;
}
.row.row-space-14 {
  margin-left: -7px;
  margin-right: -7px;
}
.row.row-space-16 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.row-space-18 {
  margin-left: -9px;
  margin-right: -9px;
}
.row.row-space-20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.row-space-22 {
  margin-left: -11px;
  margin-right: -11px;
}
.row.row-space-24 {
  margin-left: -12px;
  margin-right: -12px;
}
.row.row-space-26 {
  margin-left: -13px;
  margin-right: -13px;
}
.row.row-space-28 {
  margin-left: -14px;
  margin-right: -14px;
}
.row.row-space-30 {
  margin-left: -15px;
  margin-right: -15px;
}
.row.row-space-0 > [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.row.row-space-2 > [class^="col-"] {
  padding-left: 1px;
  padding-right: 1px;
}
.row.row-space-4 > [class^="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}
.row.row-space-6 > [class^="col-"] {
  padding-left: 3px;
  padding-right: 3px;
}
.row.row-space-8 > [class^="col-"] {
  padding-left: 4px;
  padding-right: 4px;
}
.row.row-space-10 > [class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.row.row-space-12 > [class^="col-"] {
  padding-left: 6px;
  padding-right: 6px;
}
.row.row-space-14 > [class^="col-"] {
  padding-left: 7px;
  padding-right: 7px;
}
.row.row-space-16 > [class^="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}
.row.row-space-18 > [class^="col-"] {
  padding-left: 9px;
  padding-right: 9px;
}
.row.row-space-20 > [class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.row.row-space-22 > [class^="col-"] {
  padding-left: 11px;
  padding-right: 11px;
}
.row.row-space-24 > [class^="col-"] {
  padding-left: 12px;
  padding-right: 12px;
}
.row.row-space-26 > [class^="col-"] {
  padding-left: 13px;
  padding-right: 13px;
}
.row.row-space-28 > [class^="col-"] {
  padding-left: 14px;
  padding-right: 14px;
}
.row.row-space-30 > [class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}

.m-auto {
  margin: 0 auto !important;
}
.m-0 {
  margin: 0px !important;
}
.m-1 {
  margin: 1px !important;
}
.m-2 {
  margin: 2px !important;
}
.m-3 {
  margin: 3px !important;
}
.m-4 {
  margin: 4px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-40 {
  margin: 40px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}
.m-t-1 {
  margin-top: 1px !important;
}
.m-t-2 {
  margin-top: 2px !important;
}
.m-t-3 {
  margin-top: 3px !important;
}
.m-t-4 {
  margin-top: 4px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}
.m-r-1 {
  margin-right: 1px !important;
}
.m-r-2 {
  margin-right: 2px !important;
}
.m-r-3 {
  margin-right: 3px !important;
}
.m-r-4 {
  margin-right: 4px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-1 {
  margin-bottom: 1px !important;
}
.m-b-2 {
  margin-bottom: 2px !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-b-4 {
  margin-bottom: 4px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}
.m-l-1 {
  margin-left: 1px !important;
}
.m-l-2 {
  margin-left: 2px !important;
}
.m-l-3 {
  margin-left: 3px !important;
}
.m-l-4 {
  margin-left: 4px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}

.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 1px !important;
}
.p-2 {
  padding: 2px !important;
}
.p-3 {
  padding: 3px !important;
}
.p-4 {
  padding: 4px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.wrapper {
  padding: 15px;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}
.p-t-1 {
  padding-top: 1px !important;
}
.p-t-2 {
  padding-top: 2px !important;
}
.p-t-3 {
  padding-top: 3px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}
.p-r-1 {
  padding-right: 1px !important;
}
.p-r-2 {
  padding-right: 2px !important;
}
.p-r-3 {
  padding-right: 3px !important;
}
.p-r-4 {
  padding-right: 4px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}
.p-b-1 {
  padding-bottom: 1px !important;
}
.p-b-2 {
  padding-bottom: 2px !important;
}
.p-b-3 {
  padding-bottom: 3px !important;
}
.p-b-4 {
  padding-bottom: 4px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}
.p-l-1 {
  padding-left: 1px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.p-l-3 {
  padding-left: 3px !important;
}
.p-l-4 {
  padding-left: 4px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}

.f-s-0 {
  font-size: 0px !important;
}
.f-s-1 {
  font-size: 1px !important;
}
.f-s-2 {
  font-size: 2px !important;
}
.f-s-3 {
  font-size: 3px !important;
}
.f-s-4 {
  font-size: 4px !important;
}
.f-s-5 {
  font-size: 5px !important;
}
.f-s-6 {
  font-size: 6px !important;
}
.f-s-7 {
  font-size: 7px !important;
}
.f-s-8 {
  font-size: 8px !important;
}
.f-s-9 {
  font-size: 9px !important;
}
.f-s-10 {
  font-size: 10px !important;
}
.f-s-11 {
  font-size: 11px !important;
}
.f-s-12 {
  font-size: 12px !important;
}
.f-s-13 {
  font-size: 13px !important;
}
.f-s-14 {
  font-size: 14px !important;
}
.f-s-15 {
  font-size: 15px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.f-s-17 {
  font-size: 17px !important;
}
.f-s-18 {
  font-size: 18px !important;
}
.f-s-19 {
  font-size: 19px !important;
}
.f-s-20 {
  font-size: 20px !important;
}

.l-h-0 {
  line-height: 0px !important;
}
.l-h-1 {
  line-height: 1px !important;
}
.l-h-2 {
  line-height: 2px !important;
}
.l-h-3 {
  line-height: 3px !important;
}
.l-h-4 {
  line-height: 4px !important;
}
.l-h-5 {
  line-height: 5px !important;
}
.l-h-6 {
  line-height: 6px !important;
}
.l-h-7 {
  line-height: 7px !important;
}
.l-h-8 {
  line-height: 8px !important;
}
.l-h-9 {
  line-height: 9px !important;
}
.l-h-10 {
  line-height: 10px !important;
}
.l-h-11 {
  line-height: 11px !important;
}
.l-h-12 {
  line-height: 12px !important;
}
.l-h-13 {
  line-height: 13px !important;
}
.l-h-14 {
  line-height: 14px !important;
}
.l-h-15 {
  line-height: 15px !important;
}
.l-h-16 {
  line-height: 16px !important;
}
.l-h-17 {
  line-height: 17px !important;
}
.l-h-18 {
  line-height: 18px !important;
}
.l-h-19 {
  line-height: 19px !important;
}
.l-h-20 {
  line-height: 20px !important;
}
.l-h-20 {
  line-height: 20px !important;
}
.l-h-21 {
  line-height: 21px !important;
}
.l-h-22 {
  line-height: 22px !important;
}
.l-h-23 {
  line-height: 23px !important;
}
.l-h-24 {
  line-height: 24px !important;
}
.l-h-25 {
  line-height: 25px !important;
}
.l-h-26 {
  line-height: 26px !important;
}
.l-h-27 {
  line-height: 27px !important;
}
.l-h-28 {
  line-height: 28px !important;
}
.l-h-29 {
  line-height: 29px !important;
}
.l-h-30 {
  line-height: 30px !important;
}

.t-plus-1 {
  position: relative !important;
  top: 1px !important;
}
.t-plus-2 {
  position: relative !important;
  top: 2px !important;
}
.t-plus-3 {
  position: relative !important;
  top: 3px !important;
}
.t-plus-4 {
  position: relative !important;
  top: 4px !important;
}
.t-plus-5 {
  position: relative !important;
  top: 5px !important;
}
.t-plus-6 {
  position: relative !important;
  top: 6px !important;
}
.t-plus-7 {
  position: relative !important;
  top: 7px !important;
}
.t-plus-8 {
  position: relative !important;
  top: 8px !important;
}
.t-plus-9 {
  position: relative !important;
  top: 9px !important;
}
.t-plus-10 {
  position: relative !important;
  top: 10px !important;
}

.t-minus-1 {
  position: relative !important;
  top: -1px !important;
}
.t-minus-2 {
  position: relative !important;
  top: -2px !important;
}
.t-minus-3 {
  position: relative !important;
  top: -3px !important;
}
.t-minus-4 {
  position: relative !important;
  top: -4px !important;
}
.t-minus-5 {
  position: relative !important;
  top: -5px !important;
}
.t-minus-6 {
  position: relative !important;
  top: -6px !important;
}
.t-minus-7 {
  position: relative !important;
  top: -7px !important;
}
.t-minus-8 {
  position: relative !important;
  top: -8px !important;
}
.t-minus-9 {
  position: relative !important;
  top: -9px !important;
}
.t-minus-10 {
  position: relative !important;
  top: -10px !important;
}

.l-plus-1 {
  position: relative !important;
  left: 1px !important;
}
.l-plus-2 {
  position: relative !important;
  left: 2px !important;
}
.l-plus-3 {
  position: relative !important;
  left: 3px !important;
}
.l-plus-4 {
  position: relative !important;
  left: 4px !important;
}
.l-plus-5 {
  position: relative !important;
  left: 5px !important;
}
.l-plus-6 {
  position: relative !important;
  left: 6px !important;
}
.l-plus-7 {
  position: relative !important;
  left: 7px !important;
}
.l-plus-8 {
  position: relative !important;
  left: 8px !important;
}
.l-plus-9 {
  position: relative !important;
  left: 9px !important;
}
.l-plus-10 {
  position: relative !important;
  left: 10px !important;
}

.l-minus-1 {
  position: relative !important;
  left: -1px !important;
}
.l-minus-2 {
  position: relative !important;
  left: -2px !important;
}
.l-minus-3 {
  position: relative !important;
  left: -3px !important;
}
.l-minus-4 {
  position: relative !important;
  left: -4px !important;
}
.l-minus-5 {
  position: relative !important;
  left: -5px !important;
}
.l-minus-6 {
  position: relative !important;
  left: -6px !important;
}
.l-minus-7 {
  position: relative !important;
  left: -7px !important;
}
.l-minus-8 {
  position: relative !important;
  left: -8px !important;
}
.l-minus-9 {
  position: relative !important;
  left: -9px !important;
}
.l-minus-10 {
  position: relative !important;
  left: -10px !important;
}

.r-plus-1 {
  position: relative !important;
  right: 1px !important;
}
.r-plus-2 {
  position: relative !important;
  right: 2px !important;
}
.r-plus-3 {
  position: relative !important;
  right: 3px !important;
}
.r-plus-4 {
  position: relative !important;
  right: 4px !important;
}
.r-plus-5 {
  position: relative !important;
  right: 5px !important;
}
.r-plus-6 {
  position: relative !important;
  right: 6px !important;
}
.r-plus-7 {
  position: relative !important;
  right: 7px !important;
}
.r-plus-8 {
  position: relative !important;
  right: 8px !important;
}
.r-plus-9 {
  position: relative !important;
  right: 9px !important;
}
.r-plus-10 {
  position: relative !important;
  right: 10px !important;
}

.r-minus-1 {
  position: relative !important;
  right: -1px !important;
}
.r-minus-2 {
  position: relative !important;
  right: -2px !important;
}
.r-minus-3 {
  position: relative !important;
  right: -3px !important;
}
.r-minus-4 {
  position: relative !important;
  right: -4px !important;
}
.r-minus-5 {
  position: relative !important;
  right: -5px !important;
}
.r-minus-6 {
  position: relative !important;
  right: -6px !important;
}
.r-minus-7 {
  position: relative !important;
  right: -7px !important;
}
.r-minus-8 {
  position: relative !important;
  right: -8px !important;
}
.r-minus-9 {
  position: relative !important;
  right: -9px !important;
}
.r-minus-10 {
  position: relative !important;
  right: -10px !important;
}

.b-plus-1 {
  position: relative !important;
  bottom: 1px !important;
}
.b-plus-2 {
  position: relative !important;
  bottom: 2px !important;
}
.b-plus-3 {
  position: relative !important;
  bottom: 3px !important;
}
.b-plus-4 {
  position: relative !important;
  bottom: 4px !important;
}
.b-plus-5 {
  position: relative !important;
  bottom: 5px !important;
}
.b-plus-6 {
  position: relative !important;
  bottom: 6px !important;
}
.b-plus-7 {
  position: relative !important;
  bottom: 7px !important;
}
.b-plus-8 {
  position: relative !important;
  bottom: 8px !important;
}
.b-plus-9 {
  position: relative !important;
  bottom: 9px !important;
}
.b-plus-10 {
  position: relative !important;
  bottom: 10px !important;
}

.b-minus-1 {
  position: relative !important;
  bottom: -1px !important;
}
.b-minus-2 {
  position: relative !important;
  bottom: -2px !important;
}
.b-minus-3 {
  position: relative !important;
  bottom: -3px !important;
}
.b-minus-4 {
  position: relative !important;
  bottom: -4px !important;
}
.b-minus-5 {
  position: relative !important;
  bottom: -5px !important;
}
.b-minus-6 {
  position: relative !important;
  bottom: -6px !important;
}
.b-minus-7 {
  position: relative !important;
  bottom: -7px !important;
}
.b-minus-8 {
  position: relative !important;
  bottom: -8px !important;
}
.b-minus-9 {
  position: relative !important;
  bottom: -9px !important;
}
.b-minus-10 {
  position: relative !important;
  bottom: -10px !important;
}

.hide {
  display: none !important;
}

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.pull-none {
  float: none !important;
}

.f-w-100 {
  font-weight: 100 !important;
}
.f-w-200 {
  font-weight: 200 !important;
}
.f-w-300 {
  font-weight: 300 !important;
}
.f-w-400 {
  font-weight: 400 !important;
}
.f-w-500 {
  font-weight: 500 !important;
}
.f-w-600,
.semi-bold {
  font-weight: 600 !important;
}
.f-w-700 {
  font-weight: 700 !important;
}

.table-valign-middle th,
.table-valign-middle td {
  vertical-align: middle !important;
}
.table-th-valign-middle th,
.table-td-valign-middle td {
  vertical-align: middle !important;
}
.table-no-border-left th:first-child,
.table-no-border-left td:first-child {
  border-left: none !important;
}
.table-no-border-bottom tr:last-child td {
  border-bottom: none !important;
}
.table-no-border-top tr:first-child td {
  border-top: none !important;
}
.table-no-border-right th:last-child,
.table-no-border-right td:last-child {
  border-right: none !important;
}
.table-valign-top th,
.table-valign-top td {
  vertical-align: top !important;
}
.table-th-valign-top th,
.table-td-valign-top td {
  vertical-align: top !important;
}
.table-valign-bottom th,
.table-valign-bottom td {
  vertical-align: bottom !important;
}
.table-th-valign-bottom th,
.table-td-valign-bottom td {
  vertical-align: bottom !important;
}
.valign-top {
  vertical-align: top !important;
}
.valign-bottom {
  vertical-align: bottom !important;
}
.valign-middle {
  vertical-align: middle !important;
}
.vertical-box {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  height: 100%;
  width: 100%;
}
.vertical-box-column {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}
.vertical-box-row {
  display: table-row;
  height: 100%;
}
.vertical-box-row > .vertical-box-cell {
  position: relative;
  height: 100%;
  width: 100%;
  float: none;
}
.vertical-box-row > .vertical-box-cell > .vertical-box-inner-cell {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.vertical-box.with-grid > .vertical-box-column + .vertical-box-column {
  border-left: 1px solid #d9dfe2;
}
.vertical-box.with-grid .vertical-box-row + .vertical-box-row > div,
.vertical-box.with-grid .vertical-box-row + .wrapper,
.vertical-box.with-grid .wrapper + .vertical-box-row > div,
.vertical-box.with-grid .wrapper + .wrapper {
  border-top: 1px solid #d9dfe2;
}
.vertical-box.with-border-top {
  border-top: 1px solid #d9dfe2;
}
.vertical-box.with-border-bottom {
  border-bottom: 1px solid #d9dfe2;
}
.no-rounded-corner {
  border-radius: 0 !important;
}
.rounded-corner {
  border-radius: 50px !important;
}
.no-box-shadow {
  box-shadow: none !important;
}

.no-border {
  border: 0 !important;
}
.b-0 {
  border: 0 !important;
}
.b-t-0 {
  border-top: 0 !important;
}
.b-r-0 {
  border-right: 0 !important;
}
.b-b-0 {
  border-bottom: 0 !important;
}
.b-l-0 {
  border-left: 0 !important;
}
.b-1 {
  border: 1px solid #f2f3f4 !important;
}
.b-t-1 {
  border-top: 1px solid #f2f3f4 !important;
}
.b-r-1 {
  border-right: 1px solid #f2f3f4 !important;
}
.b-b-1 {
  border-bottom: 1px solid #f2f3f4 !important;
}
.b-l-1 {
  border-left: 1px solid #f2f3f4 !important;
}
.border-top-1 {
  border-top: 1px solid #f2f3f4 !important;
}
.border-right-1 {
  border-right: 1px solid #f2f3f4 !important;
}
.border-bottom-1 {
  border-bottom: 1px solid #f2f3f4 !important;
}
.border-left-1 {
  border-left: 1px solid #f2f3f4 !important;
}

.text-inverse,
.text-black {
  color: #2d353c !important;
}
.text-black-transparent-9 {
  color: rgba(45, 53, 60, 0.9) !important;
}
.text-black-transparent-8 {
  color: rgba(45, 53, 60, 0.8) !important;
}
.text-black-transparent-7 {
  color: rgba(45, 53, 60, 0.7) !important;
}
.text-black-transparent-6 {
  color: rgba(45, 53, 60, 0.6) !important;
}
.text-black-transparent-5 {
  color: rgba(45, 53, 60, 0.5) !important;
}
.text-black-transparent-4 {
  color: rgba(45, 53, 60, 0.4) !important;
}
.text-black-transparent-3 {
  color: rgba(45, 53, 60, 0.3) !important;
}
.text-black-transparent-2 {
  color: rgba(45, 53, 60, 0.2) !important;
}
.text-black-transparent-1 {
  color: rgba(45, 53, 60, 0.1) !important;
}
.text-black-darker {
  color: #242a30 !important;
}
.text-black-lighter {
  color: #575d63 !important;
}
a.text-inverse:hover,
a.text-inverse:focus {
  color: #575d63 !important;
}
.text-success,
.text-teal {
  color: #00acac !important;
}
.text-teal-transparent-9 {
  color: rgba(0, 172, 172, 0.9) !important;
}
.text-teal-transparent-8 {
  color: rgba(0, 172, 172, 0.8) !important;
}
.text-teal-transparent-7 {
  color: rgba(0, 172, 172, 0.7) !important;
}
.text-teal-transparent-6 {
  color: rgba(0, 172, 172, 0.6) !important;
}
.text-teal-transparent-5 {
  color: rgba(0, 172, 172, 0.5) !important;
}
.text-teal-transparent-4 {
  color: rgba(0, 172, 172, 0.4) !important;
}
.text-teal-transparent-3 {
  color: rgba(0, 172, 172, 0.3) !important;
}
.text-teal-transparent-2 {
  color: rgba(0, 172, 172, 0.2) !important;
}
.text-teal-transparent-1 {
  color: rgba(0, 172, 172, 0.1) !important;
}
.text-teal-darker {
  color: #008a8a !important;
}
.text-teal-lighter {
  color: #33bdbd !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #33bdbd !important;
}
.text-info,
.text-aqua {
  color: #49b6d6 !important;
}
.text-aqua-transparent-9 {
  color: rgba(73, 182, 214, 0.9) !important;
}
.text-aqua-transparent-8 {
  color: rgba(73, 182, 214, 0.8) !important;
}
.text-aqua-transparent-7 {
  color: rgba(73, 182, 214, 0.7) !important;
}
.text-aqua-transparent-6 {
  color: rgba(73, 182, 214, 0.6) !important;
}
.text-aqua-transparent-5 {
  color: rgba(73, 182, 214, 0.5) !important;
}
.text-aqua-transparent-4 {
  color: rgba(73, 182, 214, 0.4) !important;
}
.text-aqua-transparent-3 {
  color: rgba(73, 182, 214, 0.3) !important;
}
.text-aqua-transparent-2 {
  color: rgba(73, 182, 214, 0.2) !important;
}
.text-aqua-transparent-1 {
  color: rgba(73, 182, 214, 0.1) !important;
}
.text-aqua-darker {
  color: #3a92ab !important;
}
.text-aqua-lighter {
  color: #6dc5de !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #6dc5de !important;
}
.text-primary,
.text-blue {
  color: #348fe2 !important;
}
.text-blue-transparent-9 {
  color: rgba(52, 143, 226, 0.9) !important;
}
.text-blue-transparent-8 {
  color: rgba(52, 143, 226, 0.8) !important;
}
.text-blue-transparent-7 {
  color: rgba(52, 143, 226, 0.7) !important;
}
.text-blue-transparent-6 {
  color: rgba(52, 143, 226, 0.6) !important;
}
.text-blue-transparent-5 {
  color: rgba(52, 143, 226, 0.5) !important;
}
.text-blue-transparent-4 {
  color: rgba(52, 143, 226, 0.4) !important;
}
.text-blue-transparent-3 {
  color: rgba(52, 143, 226, 0.3) !important;
}
.text-blue-transparent-2 {
  color: rgba(52, 143, 226, 0.2) !important;
}
.text-blue-transparent-1 {
  color: rgba(52, 143, 226, 0.1) !important;
}
.text-blue-darker {
  color: #2a72b5 !important;
}
.text-blue-lighter {
  color: #5da5e8 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #5da5e8 !important;
}
.text-warning,
.text-orange {
  color: #f59c1a !important;
}
.text-orange-transparent-9 {
  color: rgba(245, 156, 26, 0.9) !important;
}
.text-orange-transparent-8 {
  color: rgba(245, 156, 26, 0.8) !important;
}
.text-orange-transparent-7 {
  color: rgba(245, 156, 26, 0.7) !important;
}
.text-orange-transparent-6 {
  color: rgba(245, 156, 26, 0.6) !important;
}
.text-orange-transparent-5 {
  color: rgba(245, 156, 26, 0.5) !important;
}
.text-orange-transparent-4 {
  color: rgba(245, 156, 26, 0.4) !important;
}
.text-orange-transparent-3 {
  color: rgba(245, 156, 26, 0.3) !important;
}
.text-orange-transparent-2 {
  color: rgba(245, 156, 26, 0.2) !important;
}
.text-orange-transparent-1 {
  color: rgba(245, 156, 26, 0.1) !important;
}
.text-orange-darker {
  color: #c47d15 !important;
}
.text-orange-lighter {
  color: #f7b048 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #f7b048 !important;
}
.text-danger,
.text-red {
  color: #702963 !important;
}
.text-red-transparent-9 {
  color: rgba(255, 91, 87, 0.9) !important;
}
.text-red-transparent-8 {
  color: rgba(255, 91, 87, 0.8) !important;
}
.text-red-transparent-7 {
  color: rgba(255, 91, 87, 0.7) !important;
}
.text-red-transparent-6 {
  color: rgba(255, 91, 87, 0.6) !important;
}
.text-red-transparent-5 {
  color: rgba(255, 91, 87, 0.5) !important;
}
.text-red-transparent-4 {
  color: rgba(255, 91, 87, 0.4) !important;
}
.text-red-transparent-3 {
  color: rgba(255, 91, 87, 0.3) !important;
}
.text-red-transparent-2 {
  color: rgba(255, 91, 87, 0.2) !important;
}
.text-red-transparent-1 {
  color: rgba(255, 91, 87, 0.1) !important;
}
.text-red-darker {
  color: #cc4946 !important;
}
.text-red-lighter {
  color: #ff7c79 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ff7c79 !important;
}
.text-white {
  color: #fff !important;
}
.text-white-transparent-9 {
  color: rgba(255, 255, 255, 0.9) !important;
}
.text-white-transparent-8 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.text-white-transparent-7 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-white-transparent-6 {
  color: rgba(255, 255, 255, 0.6) !important;
}
.text-white-transparent-5 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-white-transparent-4 {
  color: rgba(255, 255, 255, 0.4) !important;
}
.text-white-transparent-3 {
  color: rgba(255, 255, 255, 0.3) !important;
}
.text-white-transparent-2 {
  color: rgba(255, 255, 255, 0.2) !important;
}
.text-white-transparent-1 {
  color: rgba(255, 255, 255, 0.1) !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #f0f3f4 !important;
}
.text-silver {
  color: #f0f3f4 !important;
}
.text-silver-transparent-9 {
  color: rgba(240, 243, 244, 0.9) !important;
}
.text-silver-transparent-8 {
  color: rgba(240, 243, 244, 0.8) !important;
}
.text-silver-transparent-7 {
  color: rgba(240, 243, 244, 0.7) !important;
}
.text-silver-transparent-6 {
  color: rgba(240, 243, 244, 0.6) !important;
}
.text-silver-transparent-5 {
  color: rgba(240, 243, 244, 0.5) !important;
}
.text-silver-transparent-4 {
  color: rgba(240, 243, 244, 0.4) !important;
}
.text-silver-transparent-3 {
  color: rgba(240, 243, 244, 0.3) !important;
}
.text-silver-transparent-2 {
  color: rgba(240, 243, 244, 0.2) !important;
}
.text-silver-transparent-1 {
  color: rgba(240, 243, 244, 0.1) !important;
}
.text-silver-lighter {
  color: #f4f6f7 !important;
}
.text-silver-darker {
  color: #b4b6b7 !important;
}
a.text-silver:hover,
a.text-silver:focus {
  color: #b4b6b7 !important;
}
.text-grey,
.text-muted {
  color: #b6c2c9 !important;
}
.text-grey-transparent-9 {
  color: rgba(182, 194, 201, 0.9) !important;
}
.text-grey-transparent-8 {
  color: rgba(182, 194, 201, 0.8) !important;
}
.text-grey-transparent-7 {
  color: rgba(182, 194, 201, 0.7) !important;
}
.text-grey-transparent-6 {
  color: rgba(182, 194, 201, 0.6) !important;
}
.text-grey-transparent-5 {
  color: rgba(182, 194, 201, 0.5) !important;
}
.text-grey-transparent-4 {
  color: rgba(182, 194, 201, 0.4) !important;
}
.text-grey-transparent-3 {
  color: rgba(182, 194, 201, 0.3) !important;
}
.text-grey-transparent-2 {
  color: rgba(182, 194, 201, 0.2) !important;
}
.text-grey-transparent-1 {
  color: rgba(182, 194, 201, 0.1) !important;
}
.text-grey-darker {
  color: #929ba1 !important;
}
.text-grey-lighter {
  color: #c5ced4 !important;
}
a.text-grey:hover,
a.text-grey:focus {
  color: #c5ced4 !important;
}
.text-yellow {
  color: #ffd900 !important;
}
.text-yellow-transparent-9 {
  color: rgba(255, 217, 0, 0.9) !important;
}
.text-yellow-transparent-8 {
  color: rgba(255, 217, 0, 0.8) !important;
}
.text-yellow-transparent-7 {
  color: rgba(255, 217, 0, 0.7) !important;
}
.text-yellow-transparent-6 {
  color: rgba(255, 217, 0, 0.6) !important;
}
.text-yellow-transparent-5 {
  color: rgba(255, 217, 0, 0.5) !important;
}
.text-yellow-transparent-4 {
  color: rgba(255, 217, 0, 0.4) !important;
}
.text-yellow-transparent-3 {
  color: rgba(255, 217, 0, 0.3) !important;
}
.text-yellow-transparent-2 {
  color: rgba(255, 217, 0, 0.2) !important;
}
.text-yellow-transparent-1 {
  color: rgba(255, 217, 0, 0.1) !important;
}
.text-yellow-darker {
  color: #bfa300 !important;
}
.text-yellow-lighter {
  color: #fde248 !important;
}
a.text-yellow:hover,
a.text-yellow:focus {
  color: #e9fb65 !important;
}
.text-purple {
  color: #727cb6 !important;
}
.text-purple-transparent-9 {
  color: rgba(114, 124, 182, 0.9) !important;
}
.text-purple-transparent-8 {
  color: rgba(114, 124, 182, 0.8) !important;
}
.text-purple-transparent-7 {
  color: rgba(114, 124, 182, 0.7) !important;
}
.text-purple-transparent-6 {
  color: rgba(114, 124, 182, 0.6) !important;
}
.text-purple-transparent-5 {
  color: rgba(114, 124, 182, 0.5) !important;
}
.text-purple-transparent-4 {
  color: rgba(114, 124, 182, 0.4) !important;
}
.text-purple-transparent-3 {
  color: rgba(114, 124, 182, 0.3) !important;
}
.text-purple-transparent-2 {
  color: rgba(114, 124, 182, 0.2) !important;
}
.text-purple-transparent-1 {
  color: rgba(114, 124, 182, 0.1) !important;
}
.text-purple-darker {
  color: #5b6392 !important;
}
.text-purple-lighter {
  color: #8e96c5 !important;
}
a.text-purple:hover,
a.text-purple:focus {
  color: #8e96c5 !important;
}
.text-lime {
  color: #90ca4b !important;
}
.text-lime-transparent-9 {
  color: rgba(144, 202, 75, 0.9) !important;
}
.text-lime-transparent-8 {
  color: rgba(144, 202, 75, 0.8) !important;
}
.text-lime-transparent-7 {
  color: rgba(144, 202, 75, 0.7) !important;
}
.text-lime-transparent-6 {
  color: rgba(144, 202, 75, 0.6) !important;
}
.text-lime-transparent-5 {
  color: rgba(144, 202, 75, 0.5) !important;
}
.text-lime-transparent-4 {
  color: rgba(144, 202, 75, 0.4) !important;
}
.text-lime-transparent-3 {
  color: rgba(144, 202, 75, 0.3) !important;
}
.text-lime-transparent-2 {
  color: rgba(144, 202, 75, 0.2) !important;
}
.text-lime-transparent-1 {
  color: rgba(144, 202, 75, 0.1) !important;
}
.text-lime-darker {
  color: #6c9738 !important;
}
.text-lime-lighter {
  color: #acd778 !important;
}
a.text-lime:hover,
a.text-lime:focus {
  color: #6c9738 !important;
}
.text-indigo {
  color: #8753de !important;
}
.text-indigo-transparent-9 {
  color: rgba(135, 83, 222, 0.9) !important;
}
.text-indigo-transparent-8 {
  color: rgba(135, 83, 222, 0.8) !important;
}
.text-indigo-transparent-7 {
  color: rgba(135, 83, 222, 0.7) !important;
}
.text-indigo-transparent-6 {
  color: rgba(135, 83, 222, 0.6) !important;
}
.text-indigo-transparent-5 {
  color: rgba(135, 83, 222, 0.5) !important;
}
.text-indigo-transparent-4 {
  color: rgba(135, 83, 222, 0.4) !important;
}
.text-indigo-transparent-3 {
  color: rgba(135, 83, 222, 0.3) !important;
}
.text-indigo-transparent-2 {
  color: rgba(135, 83, 222, 0.2) !important;
}
.text-indigo-transparent-1 {
  color: rgba(135, 83, 222, 0.1) !important;
}
.text-indigo-darker {
  color: #6c42b2 !important;
}
.text-indigo-lighter {
  color: #9f75e5 !important;
}
a.text-indigo:hover,
a.text-indigo:focus {
  color: #6c42b2 !important;
}
.text-pink {
  color: #fb5597 !important;
}
.text-pink-transparent-9 {
  color: rgba(251, 85, 151, 0.9) !important;
}
.text-pink-transparent-8 {
  color: rgba(251, 85, 151, 0.8) !important;
}
.text-pink-transparent-7 {
  color: rgba(251, 85, 151, 0.7) !important;
}
.text-pink-transparent-6 {
  color: rgba(251, 85, 151, 0.6) !important;
}
.text-pink-transparent-5 {
  color: rgba(251, 85, 151, 0.5) !important;
}
.text-pink-transparent-4 {
  color: rgba(251, 85, 151, 0.4) !important;
}
.text-pink-transparent-3 {
  color: rgba(251, 85, 151, 0.3) !important;
}
.text-pink-transparent-2 {
  color: rgba(251, 85, 151, 0.2) !important;
}
.text-pink-transparent-1 {
  color: rgba(251, 85, 151, 0.1) !important;
}
.text-pink-darker {
  color: #c94479 !important;
}
.text-pink-lighter {
  color: #fc77ac !important;
}
a.text-pink:hover,
a.text-pink:focus {
  color: #c94479 !important;
}
.text-green {
  color: #32a932 !important;
}
.text-green-transparent-9 {
  color: rgba(50, 169, 50, 0.9) !important;
}
.text-green-transparent-8 {
  color: rgba(50, 169, 50, 0.8) !important;
}
.text-green-transparent-7 {
  color: rgba(50, 169, 50, 0.7) !important;
}
.text-green-transparent-6 {
  color: rgba(50, 169, 50, 0.6) !important;
}
.text-green-transparent-5 {
  color: rgba(50, 169, 50, 0.5) !important;
}
.text-green-transparent-4 {
  color: rgba(50, 169, 50, 0.4) !important;
}
.text-green-transparent-3 {
  color: rgba(50, 169, 50, 0.3) !important;
}
.text-green-transparent-2 {
  color: rgba(50, 169, 50, 0.2) !important;
}
.text-green-transparent-1 {
  color: rgba(50, 169, 50, 0.1) !important;
}
.text-green-darker {
  color: #288728 !important;
}
.text-green-lighter {
  color: #5bba5b !important;
}
a.text-green:hover,
a.text-green:focus {
  color: #288728 !important;
}
.bg-primary,
.bg-blue {
  background-color: #348fe2 !important;
}
.bg-blue-transparent-9 {
  background-color: rgba(52, 143, 226, 0.9) !important;
}
.bg-blue-transparent-8 {
  background-color: rgba(52, 143, 226, 0.8) !important;
}
.bg-blue-transparent-7 {
  background-color: rgba(52, 143, 226, 0.7) !important;
}
.bg-blue-transparent-6 {
  background-color: rgba(52, 143, 226, 0.6) !important;
}
.bg-blue-transparent-5 {
  background-color: rgba(52, 143, 226, 0.5) !important;
}
.bg-blue-transparent-4 {
  background-color: rgba(52, 143, 226, 0.4) !important;
}
.bg-blue-transparent-3 {
  background-color: rgba(52, 143, 226, 0.3) !important;
}
.bg-blue-transparent-2 {
  background-color: rgba(52, 143, 226, 0.2) !important;
}
.bg-blue-transparent-1 {
  background-color: rgba(52, 143, 226, 0.1) !important;
}
.bg-blue-darker {
  background-color: #2a72b5 !important;
}
.bg-blue-lighter {
  background-color: #5da5e8 !important;
}
.bg-gradient-blue {
  background: rgb(81, 136, 218);
  background: -moz-linear-gradient(
    -45deg,
    rgba(81, 136, 218, 1) 0%,
    rgba(52, 135, 226, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    -45deg,
    rgba(81, 136, 218, 1) 0%,
    rgba(52, 135, 226, 1) 100%
  ) !important;
  background: linear-gradient(
    135deg,
    rgba(81, 136, 218, 1) 0%,
    rgba(52, 135, 226, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5188da', endColorstr='#3487e2',GradientType=1 ) !important;
}
.bg-info,
.bg-aqua {
  background-color: #49b6d6 !important;
}
.bg-aqua-transparent-9 {
  background-color: rgba(73, 182, 214, 0.9) !important;
}
.bg-aqua-transparent-8 {
  background-color: rgba(73, 182, 214, 0.8) !important;
}
.bg-aqua-transparent-7 {
  background-color: rgba(73, 182, 214, 0.7) !important;
}
.bg-aqua-transparent-6 {
  background-color: rgba(73, 182, 214, 0.6) !important;
}
.bg-aqua-transparent-5 {
  background-color: rgba(73, 182, 214, 0.5) !important;
}
.bg-aqua-transparent-4 {
  background-color: rgba(73, 182, 214, 0.4) !important;
}
.bg-aqua-transparent-3 {
  background-color: rgba(73, 182, 214, 0.3) !important;
}
.bg-aqua-transparent-2 {
  background-color: rgba(73, 182, 214, 0.2) !important;
}
.bg-aqua-transparent-1 {
  background-color: rgba(73, 182, 214, 0.1) !important;
}
.bg-aqua-darker {
  background-color: #3a92ab !important;
}
.bg-aqua-lighter {
  background-color: #6dc5de !important;
}
.bg-gradient-aqua {
  background: rgb(109, 197, 222) !important;
  background: -moz-linear-gradient(
    top,
    rgba(109, 197, 222, 1) 0%,
    rgba(73, 182, 214, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(109, 197, 222, 1) 0%,
    rgba(73, 182, 214, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(109, 197, 222, 1) 0%,
    rgba(73, 182, 214, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dc5de', endColorstr='#49b6d6',GradientType=0 ) !important;
}
.bg-success,
.bg-teal {
  background-color: #00acac !important;
}
.bg-teal-transparent-9 {
  background-color: rgba(0, 172, 172, 0.9) !important;
}
.bg-teal-transparent-8 {
  background-color: rgba(0, 172, 172, 0.8) !important;
}
.bg-teal-transparent-7 {
  background-color: rgba(0, 172, 172, 0.7) !important;
}
.bg-teal-transparent-6 {
  background-color: rgba(0, 172, 172, 0.6) !important;
}
.bg-teal-transparent-5 {
  background-color: rgba(0, 172, 172, 0.5) !important;
}
.bg-teal-transparent-4 {
  background-color: rgba(0, 172, 172, 0.4) !important;
}
.bg-teal-transparent-3 {
  background-color: rgba(0, 172, 172, 0.3) !important;
}
.bg-teal-transparent-2 {
  background-color: rgba(0, 172, 172, 0.2) !important;
}
.bg-teal-transparent-1 {
  background-color: rgba(0, 172, 172, 0.1) !important;
}
.bg-teal-darker {
  background-color: #008a8a !important;
}
.bg-teal-lighter {
  background-color: #33bdbd !important;
}
.bg-gradient-teal {
  background: rgb(52, 186, 187) !important;
  background: -moz-linear-gradient(
    -45deg,
    rgba(52, 186, 187, 1) 0%,
    rgba(0, 172, 172, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    -45deg,
    rgba(52, 186, 187, 1) 0%,
    rgba(0, 172, 172, 1) 100%
  ) !important;
  background: linear-gradient(
    135deg,
    rgba(52, 186, 187, 1) 0%,
    rgba(0, 172, 172, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34babb', endColorstr='#00acac',GradientType=1 ) !important;
}
.bg-danger,
.bg-red {
  background-color: #702963 !important;
}
.bg-red-transparent-9 {
  background-color: rgba(255, 91, 87, 0.9) !important;
}
.bg-red-transparent-8 {
  background-color: rgba(255, 91, 87, 0.8) !important;
}
.bg-red-transparent-7 {
  background-color: rgba(255, 91, 87, 0.7) !important;
}
.bg-red-transparent-6 {
  background-color: rgba(255, 91, 87, 0.6) !important;
}
.bg-red-transparent-5 {
  background-color: rgba(255, 91, 87, 0.5) !important;
}
.bg-red-transparent-4 {
  background-color: rgba(255, 91, 87, 0.4) !important;
}
.bg-red-transparent-3 {
  background-color: rgba(255, 91, 87, 0.3) !important;
}
.bg-red-transparent-2 {
  background-color: rgba(255, 91, 87, 0.2) !important;
}
.bg-red-transparent-1 {
  background-color: rgba(255, 91, 87, 0.1) !important;
}
.bg-red-darker {
  background-color: #cc4946 !important;
}
.bg-red-lighter {
  background-color: #ff7c79 !important;
}
.bg-gradient-red {
  background: rgb(255, 124, 121) !important;
  background: -moz-linear-gradient(
    top,
    rgba(255, 124, 121, 1) 0%,
    rgba(255, 91, 87, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 124, 121, 1) 0%,
    rgba(255, 91, 87, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(255, 124, 121, 1) 0%,
    rgba(255, 91, 87, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7c79', endColorstr='#702963',GradientType=0 ) !important;
}
.bg-warning,
.bg-orange {
  background-color: #f59c1a !important;
}
.bg-orange-transparent-9 {
  background-color: rgba(245, 156, 26, 0.9) !important;
}
.bg-orange-transparent-8 {
  background-color: rgba(245, 156, 26, 0.8) !important;
}
.bg-orange-transparent-7 {
  background-color: rgba(245, 156, 26, 0.7) !important;
}
.bg-orange-transparent-6 {
  background-color: rgba(245, 156, 26, 0.6) !important;
}
.bg-orange-transparent-5 {
  background-color: rgba(245, 156, 26, 0.5) !important;
}
.bg-orange-transparent-4 {
  background-color: rgba(245, 156, 26, 0.4) !important;
}
.bg-orange-transparent-3 {
  background-color: rgba(245, 156, 26, 0.3) !important;
}
.bg-orange-transparent-2 {
  background-color: rgba(245, 156, 26, 0.2) !important;
}
.bg-orange-transparent-1 {
  background-color: rgba(245, 156, 26, 0.1) !important;
}
.bg-orange-darker {
  background-color: #c47d15 !important;
}
.bg-orange-lighter {
  background-color: #f7b048 !important;
}
.bg-gradient-orange {
  background: rgb(247, 176, 72) !important;
  background: -moz-linear-gradient(
    top,
    rgba(247, 176, 72, 1) 0%,
    rgba(245, 156, 26, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(247, 176, 72, 1) 0%,
    rgba(245, 156, 26, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(247, 176, 72, 1) 0%,
    rgba(245, 156, 26, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7b048', endColorstr='#f59c1a',GradientType=0 ) !important;
}
.bg-inverse,
.bg-black {
  background-color: #702963 !important;
}
.bg-black-transparent-9 {
  background-color: rgba(45, 53, 60, 0.9) !important;
}
.bg-black-transparent-8 {
  background-color: rgba(45, 53, 60, 0.8) !important;
}
.bg-black-transparent-7 {
  background-color: rgba(45, 53, 60, 0.7) !important;
}
.bg-black-transparent-6 {
  background-color: rgba(45, 53, 60, 0.6) !important;
}
.bg-black-transparent-5 {
  background-color: rgba(45, 53, 60, 0.5) !important;
}
.bg-black-transparent-4 {
  background-color: rgba(45, 53, 60, 0.4) !important;
}
.bg-black-transparent-3 {
  background-color: rgba(45, 53, 60, 0.3) !important;
}
.bg-black-transparent-2 {
  background-color: rgba(45, 53, 60, 0.2) !important;
}
.bg-black-transparent-1 {
  background-color: rgba(45, 53, 60, 0.1) !important;
}
.bg-black-darker {
  background-color: #242a30 !important;
}
.bg-black-lighter {
  background-color: #575d63 !important;
}
.bg-gradient-black {
  background: rgb(88, 97, 105) !important;
  background: -moz-linear-gradient(
    -45deg,
    rgba(88, 97, 105, 1) 0%,
    rgba(45, 53, 60, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    -45deg,
    rgba(88, 97, 105, 1) 0%,
    rgba(45, 53, 60, 1) 100%
  ) !important;
  background: linear-gradient(
    135deg,
    rgba(88, 97, 105, 1) 0%,
    rgba(45, 53, 60, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#586169', endColorstr='#702963',GradientType=1 ) !important;
}
.bg-yellow {
  background-color: #ffd900 !important;
}
.bg-yellow-transparent-9 {
  background-color: rgba(255, 217, 0, 0.9) !important;
}
.bg-yellow-transparent-8 {
  background-color: rgba(255, 217, 0, 0.8) !important;
}
.bg-yellow-transparent-7 {
  background-color: rgba(255, 217, 0, 0.7) !important;
}
.bg-yellow-transparent-6 {
  background-color: rgba(255, 217, 0, 0.6) !important;
}
.bg-yellow-transparent-5 {
  background-color: rgba(255, 217, 0, 0.5) !important;
}
.bg-yellow-transparent-4 {
  background-color: rgba(255, 217, 0, 0.4) !important;
}
.bg-yellow-transparent-3 {
  background-color: rgba(255, 217, 0, 0.3) !important;
}
.bg-yellow-transparent-2 {
  background-color: rgba(255, 217, 0, 0.2) !important;
}
.bg-yellow-transparent-1 {
  background-color: rgba(255, 217, 0, 0.1) !important;
}
.bg-yellow-darker {
  background-color: #bfa300 !important;
}
.bg-yellow-lighter {
  background-color: #fde248 !important;
}
.bg-gradient-yellow {
  background: rgb(253, 226, 72) !important;
  background: -moz-linear-gradient(
    top,
    rgba(253, 226, 72, 1) 0%,
    rgba(255, 217, 0, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(253, 226, 72, 1) 0%,
    rgba(255, 217, 0, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(253, 226, 72, 1) 0%,
    rgba(255, 217, 0, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fde248', endColorstr='#ffd900',GradientType=0 ) !important;
}
.bg-silver {
  background-color: #f0f3f4 !important;
}
.bg-silver-transparent-9 {
  background-color: rgba(240, 243, 244, 0.9) !important;
}
.bg-silver-transparent-8 {
  background-color: rgba(240, 243, 244, 0.8) !important;
}
.bg-silver-transparent-7 {
  background-color: rgba(240, 243, 244, 0.7) !important;
}
.bg-silver-transparent-6 {
  background-color: rgba(240, 243, 244, 0.6) !important;
}
.bg-silver-transparent-5 {
  background-color: rgba(240, 243, 244, 0.5) !important;
}
.bg-silver-transparent-4 {
  background-color: rgba(240, 243, 244, 0.4) !important;
}
.bg-silver-transparent-3 {
  background-color: rgba(240, 243, 244, 0.3) !important;
}
.bg-silver-transparent-2 {
  background-color: rgba(240, 243, 244, 0.2) !important;
}
.bg-silver-transparent-1 {
  background-color: rgba(240, 243, 244, 0.1) !important;
}
.bg-silver-lighter {
  background-color: #f4f6f7 !important;
}
.bg-silver-darker {
  background-color: #b4b6b7 !important;
}
.bg-gradient-silver {
  background: rgb(244, 246, 247) !important;
  background: -moz-linear-gradient(
    top,
    rgba(244, 246, 247, 1) 0%,
    rgba(240, 243, 244, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(244, 246, 247, 1) 0%,
    rgba(240, 243, 244, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(244, 246, 247, 1) 0%,
    rgba(240, 243, 244, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f6f7', endColorstr='#f0f3f4',GradientType=0 ) !important;
}
.bg-grey {
  background-color: #b6c2c9 !important;
}
.bg-grey-transparent-9 {
  background-color: rgba(182, 194, 201, 0.9) !important;
}
.bg-grey-transparent-8 {
  background-color: rgba(182, 194, 201, 0.8) !important;
}
.bg-grey-transparent-7 {
  background-color: rgba(182, 194, 201, 0.7) !important;
}
.bg-grey-transparent-6 {
  background-color: rgba(182, 194, 201, 0.6) !important;
}
.bg-grey-transparent-5 {
  background-color: rgba(182, 194, 201, 0.5) !important;
}
.bg-grey-transparent-4 {
  background-color: rgba(182, 194, 201, 0.4) !important;
}
.bg-grey-transparent-3 {
  background-color: rgba(182, 194, 201, 0.3) !important;
}
.bg-grey-transparent-2 {
  background-color: rgba(182, 194, 201, 0.2) !important;
}
.bg-grey-transparent-1 {
  background-color: rgba(182, 194, 201, 0.1) !important;
}
.bg-grey-darker {
  background-color: #929ba1 !important;
}
.bg-grey-lighter {
  background-color: #c5ced4 !important;
}
.bg-gradient-grey {
  background: rgb(197, 206, 212) !important;
  background: -moz-linear-gradient(
    top,
    rgba(197, 206, 212, 1) 0%,
    rgba(182, 194, 201, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(197, 206, 212, 1) 0%,
    rgba(182, 194, 201, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(197, 206, 212, 1) 0%,
    rgba(182, 194, 201, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5ced4', endColorstr='#b6c2c9',GradientType=0 ) !important;
}
.bg-purple {
  background-color: #727cb6 !important;
}
.bg-purple-transparent-9 {
  background-color: rgba(114, 124, 182, 0.9) !important;
}
.bg-purple-transparent-8 {
  background-color: rgba(114, 124, 182, 0.8) !important;
}
.bg-purple-transparent-7 {
  background-color: rgba(114, 124, 182, 0.7) !important;
}
.bg-purple-transparent-6 {
  background-color: rgba(114, 124, 182, 0.6) !important;
}
.bg-purple-transparent-5 {
  background-color: rgba(114, 124, 182, 0.5) !important;
}
.bg-purple-transparent-4 {
  background-color: rgba(114, 124, 182, 0.4) !important;
}
.bg-purple-transparent-3 {
  background-color: rgba(114, 124, 182, 0.3) !important;
}
.bg-purple-transparent-2 {
  background-color: rgba(114, 124, 182, 0.2) !important;
}
.bg-purple-transparent-1 {
  background-color: rgba(114, 124, 182, 0.1) !important;
}
.bg-purple-darker {
  background-color: #5b6392 !important;
}
.bg-purple-lighter {
  background-color: #8e96c5 !important;
}
.bg-gradient-purple {
  background: rgb(132, 87, 243) !important;
  background: -moz-linear-gradient(
    -45deg,
    rgba(132, 87, 243, 1) 0%,
    rgba(114, 124, 182, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    -45deg,
    rgba(132, 87, 243, 1) 0%,
    rgba(114, 124, 182, 1) 100%
  ) !important;
  background: linear-gradient(
    135deg,
    rgba(132, 87, 243, 1) 0%,
    rgba(114, 124, 182, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8457f3', endColorstr='#727cb6',GradientType=1 ) !important;
}
.bg-lime {
  background-color: #90ca4b !important;
}
.bg-lime-transparent-9 {
  background-color: rgba(144, 202, 75, 0.9) !important;
}
.bg-lime-transparent-8 {
  background-color: rgba(144, 202, 75, 0.8) !important;
}
.bg-lime-transparent-7 {
  background-color: rgba(144, 202, 75, 0.7) !important;
}
.bg-lime-transparent-6 {
  background-color: rgba(144, 202, 75, 0.6) !important;
}
.bg-lime-transparent-5 {
  background-color: rgba(144, 202, 75, 0.5) !important;
}
.bg-lime-transparent-4 {
  background-color: rgba(144, 202, 75, 0.4) !important;
}
.bg-lime-transparent-3 {
  background-color: rgba(144, 202, 75, 0.3) !important;
}
.bg-lime-transparent-2 {
  background-color: rgba(144, 202, 75, 0.2) !important;
}
.bg-lime-transparent-1 {
  background-color: rgba(144, 202, 75, 0.1) !important;
}
.bg-lime-darker {
  background-color: #6c9738 !important;
}
.bg-lime-lighter {
  background-color: #acd778 !important;
}
.bg-gradient-lime {
  background: rgb(172, 215, 120) !important;
  background: -moz-linear-gradient(
    top,
    rgba(172, 215, 120, 1) 0%,
    rgba(144, 202, 75, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(172, 215, 120, 1) 0%,
    rgba(144, 202, 75, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(172, 215, 120, 1) 0%,
    rgba(144, 202, 75, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#acd778', endColorstr='#90ca4b',GradientType=0 ) !important;
}
.bg-indigo {
  background-color: #8753de !important;
}
.bg-indigo-transparent-9 {
  background-color: rgba(135, 83, 222, 0.9) !important;
}
.bg-indigo-transparent-8 {
  background-color: rgba(135, 83, 222, 0.8) !important;
}
.bg-indigo-transparent-7 {
  background-color: rgba(135, 83, 222, 0.7) !important;
}
.bg-indigo-transparent-6 {
  background-color: rgba(135, 83, 222, 0.6) !important;
}
.bg-indigo-transparent-5 {
  background-color: rgba(135, 83, 222, 0.5) !important;
}
.bg-indigo-transparent-4 {
  background-color: rgba(135, 83, 222, 0.4) !important;
}
.bg-indigo-transparent-3 {
  background-color: rgba(135, 83, 222, 0.3) !important;
}
.bg-indigo-transparent-2 {
  background-color: rgba(135, 83, 222, 0.2) !important;
}
.bg-indigo-transparent-1 {
  background-color: rgba(135, 83, 222, 0.1) !important;
}
.bg-indigo-darker {
  background-color: #6c42b2 !important;
}
.bg-indigo-lighter {
  background-color: #9f75e5 !important;
}
.bg-gradient-indigo {
  background: rgb(159, 117, 229) !important;
  background: -moz-linear-gradient(
    top,
    rgba(159, 117, 229, 1) 0%,
    rgba(108, 66, 178, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(159, 117, 229, 1) 0%,
    rgba(108, 66, 178, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(159, 117, 229, 1) 0%,
    rgba(108, 66, 178, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9f75e5', endColorstr='#6c42b2',GradientType=0 ) !important;
}
.bg-pink {
  background-color: #fb5597 !important;
}
.bg-pink-transparent-9 {
  background-color: rgba(251, 85, 151, 0.9) !important;
}
.bg-pink-transparent-8 {
  background-color: rgba(251, 85, 151, 0.8) !important;
}
.bg-pink-transparent-7 {
  background-color: rgba(251, 85, 151, 0.7) !important;
}
.bg-pink-transparent-6 {
  background-color: rgba(251, 85, 151, 0.6) !important;
}
.bg-pink-transparent-5 {
  background-color: rgba(251, 85, 151, 0.5) !important;
}
.bg-pink-transparent-4 {
  background-color: rgba(251, 85, 151, 0.4) !important;
}
.bg-pink-transparent-3 {
  background-color: rgba(251, 85, 151, 0.3) !important;
}
.bg-pink-transparent-2 {
  background-color: rgba(251, 85, 151, 0.2) !important;
}
.bg-pink-transparent-1 {
  background-color: rgba(251, 85, 151, 0.1) !important;
}
.bg-pink-darker {
  background-color: #c94479 !important;
}
.bg-pink-lighter {
  background-color: #fc77ac !important;
}
.bg-gradient-pink {
  background: rgb(252, 119, 172) !important;
  background: -moz-linear-gradient(
    top,
    rgba(252, 119, 172, 1) 0%,
    rgba(201, 68, 121, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(252, 119, 172, 1) 0%,
    rgba(201, 68, 121, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(252, 119, 172, 1) 0%,
    rgba(201, 68, 121, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc77ac', endColorstr='#c94479',GradientType=0 ) !important;
}
.bg-green {
  background-color: #32a932 !important;
}
.bg-green-transparent-9 {
  background-color: rgba(50, 169, 50, 0.9) !important;
}
.bg-green-transparent-8 {
  background-color: rgba(50, 169, 50, 0.8) !important;
}
.bg-green-transparent-7 {
  background-color: rgba(50, 169, 50, 0.7) !important;
}
.bg-green-transparent-6 {
  background-color: rgba(50, 169, 50, 0.6) !important;
}
.bg-green-transparent-5 {
  background-color: rgba(50, 169, 50, 0.5) !important;
}
.bg-green-transparent-4 {
  background-color: rgba(50, 169, 50, 0.4) !important;
}
.bg-green-transparent-3 {
  background-color: rgba(50, 169, 50, 0.3) !important;
}
.bg-green-transparent-2 {
  background-color: rgba(50, 169, 50, 0.2) !important;
}
.bg-green-transparent-1 {
  background-color: rgba(50, 169, 50, 0.1) !important;
}
.bg-green-darker {
  background-color: #288728 !important;
}
.bg-green-lighter {
  background-color: #5bba5b !important;
}
.bg-gradient-green {
  background: rgb(91, 186, 91) !important;
  background: -moz-linear-gradient(
    top,
    rgba(91, 186, 91, 1) 0%,
    rgba(40, 135, 40, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(91, 186, 91, 1) 0%,
    rgba(40, 135, 40, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(91, 186, 91, 1) 0%,
    rgba(40, 135, 40, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5bba5b', endColorstr='#288728',GradientType=0 ) !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.bg-white-transparent-9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-white-transparent-8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.bg-white-transparent-7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.bg-white-transparent-6 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}
.bg-white-transparent-5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-transparent-4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.bg-white-transparent-3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.bg-white-transparent-2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.bg-white-transparent-1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.text-line-through {
  text-decoration: line-through !important;
}
.no-bg,
.bg-none {
  background: none !important;
}

.height-xs {
  height: 150px !important;
}
.height-sm {
  height: 300px !important;
}
.height-md {
  height: 450px !important;
}
.height-lg {
  height: 600px !important;
}
.height-full {
  height: 100% !important;
}
.height-0 {
  height: 0px !important;
}
.height-10 {
  height: 10px !important;
}
.height-20 {
  height: 20px !important;
}
.height-30 {
  height: 30px !important;
}
.height-40 {
  height: 40px !important;
}
.height-50 {
  height: 50px !important;
}
.height-60 {
  height: 60px !important;
}
.height-70 {
  height: 70px !important;
}
.height-80 {
  height: 80px !important;
}
.height-90 {
  height: 90px !important;
}
.height-100 {
  height: 100px !important;
}
.height-150 {
  height: 150px !important;
}
.height-200 {
  height: 200px !important;
}
.height-250 {
  height: 250px !important;
}
.height-300 {
  height: 300px !important;
}
.height-350 {
  height: 350px !important;
}
.height-400 {
  height: 400px !important;
}
.height-450 {
  height: 450px !important;
}
.height-500 {
  height: 500px !important;
}
.height-550 {
  height: 550px !important;
}
.height-600 {
  height: 600px !important;
}

.width-xs {
  width: 150px !important;
}
.width-sm {
  width: 300px !important;
}
.width-md {
  width: 450px !important;
}
.width-lg {
  width: 600px !important;
}
.width-full {
  width: 100% !important;
}
.width-0 {
  width: 0px !important;
}
.width-10 {
  width: 10px !important;
}
.width-20 {
  width: 20px !important;
}
.width-30 {
  width: 30px !important;
}
.width-40 {
  width: 40px !important;
}
.width-50 {
  width: 50px !important;
}
.width-60 {
  width: 60px !important;
}
.width-70 {
  width: 70px !important;
}
.width-80 {
  width: 80px !important;
}
.width-90 {
  width: 90px !important;
}
.width-100 {
  width: 100px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-300 {
  width: 300px !important;
}
.width-350 {
  width: 350px !important;
}
.width-400 {
  width: 400px !important;
}
.width-450 {
  width: 450px !important;
}
.width-500 {
  width: 500px !important;
}
.width-550 {
  width: 550px !important;
}
.width-600 {
  width: 600px !important;
}

.pointer-cursor {
  cursor: pointer !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-initial {
  cursor: initial !important;
}
.cursor-move {
  cursor: move !important;
}

.animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fade {
  opacity: 0;
  transition: opacity 0.3s linear;
}
.fade.show {
  opacity: 1;
}
.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.underline {
  border-bottom: 1px solid #e2e7eb !important;
}
.with-shadow {
  box-shadow: 0 12px 36px 6px rgba(0, 0, 0, 0.1) !important;
}

/* -------------------------------
   10.0 Content Right To Left (Added in V4.1)
------------------------------- */

/* 10.1 RTL Settings */

.rtl-mode {
  direction: rtl;
  text-align: right;
}
.rtl-mode .header .navbar-header,
.rtl-mode .header .navbar-nav > li,
.rtl-mode .header .navbar-logo {
  float: right;
}
.rtl-mode .header .navbar-logo {
  margin-left: 10px;
  margin-right: 0;
}
.rtl-mode .header .navbar-right,
.rtl-mode .pull-right {
  float: left !important;
}
.rtl-mode .text-right {
  text-align: left !important;
}
.rtl-mode .text-left {
  text-align: right !important;
}
.rtl-mode .header .navbar-left,
.rtl-mode .pull-left {
  float: right !important;
}
.rtl-mode #gritter-notice-wrapper {
  left: 20px;
  right: auto;
}
.rtl-mode .header .navbar-form .btn-search {
  left: 15px;
  right: auto;
}
.rtl-mode .header .navbar-user img,
.rtl-mode .header .navbar-user .image {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.rtl-mode .caret {
  margin-left: 0;
  margin-right: 2px;
}
.rtl-mode .sidebar,
.rtl-mode .sidebar-bg {
  left: auto;
  right: 0;
}
.rtl-mode .sidebar .nav {
  padding: 0;
}
.rtl-mode .sidebar .nav > li > a i,
.rtl-mode .sidebar .nav > li > a .icon-img {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.rtl-mode .sidebar li > a .caret,
.rtl-mode .float-sub-menu li > a .caret {
  float: left;
}
.rtl-mode .sidebar li > a .caret:before,
.rtl-mode .float-sub-menu li > a .caret:before {
  content: "\f053";
}
.rtl-mode .sidebar li.active > a .caret:before,
.rtl-mode .sidebar li.expanding > a .caret:before,
.rtl-mode .sidebar li.expand > a .caret:before,
.rtl-mode .float-sub-menu li.active > a .caret:before,
.rtl-mode .float-sub-menu li.expanding > a .caret:before,
.rtl-mode .float-sub-menu li.expand > a .caret:before {
  transform: rotate(-90deg);
}
.rtl-mode .sidebar .sub-menu {
  padding-left: 0;
  padding-right: 39px;
}
.rtl-mode .sidebar .sub-menu > li:before {
  left: auto;
  right: -13px;
}
.rtl-mode .sidebar .sub-menu > li:after {
  left: auto;
  right: 0;
}
.rtl-mode .sidebar .sub-menu > li > a {
  padding-left: 20px;
  padding-right: 15px;
}
.rtl-mode .sidebar .sub-menu > li > a:after {
  left: auto;
  right: -11px;
}
.rtl-mode .sidebar-minify-btn {
  float: left;
  border-radius: 0 20px 20px 0;
}
.rtl-mode .page-sidebar-minified .sidebar .caret {
  right: auto;
  left: 5px;
}
.rtl-mode .page-with-right-sidebar .sidebar .nav > li > a .badge {
  float: left !important;
}
.rtl-mode .content {
  margin-left: 0;
  margin-right: 220px;
}
.rtl-mode .page-sidebar-minified .content {
  margin-right: 60px;
}
.rtl-mode .float-sub-menu-container {
  left: auto !important;
  right: 60px !important;
}
.rtl-mode .float-sub-menu {
  padding-left: 0;
  padding-right: 39px;
}
.rtl-mode .float-sub-menu .sub-menu {
  padding-left: 0;
  padding-right: 15px;
}
.rtl-mode .float-sub-menu li:before {
  left: auto;
  right: -13px;
}
.rtl-mode .float-sub-menu li:after {
  left: auto;
  right: 0;
}
.rtl-mode .float-sub-menu li > a:after {
  left: auto;
  right: -11px;
}
.rtl-mode .float-sub-menu-arrow {
  right: 0;
  left: auto;
}
.rtl-mode .float-sub-menu-line {
  right: 26px;
}
.rtl-mode .btn-scroll-to-top {
  left: 25px;
  right: auto;
}
.rtl-mode .theme-panel {
  left: -175px;
  right: auto;
  transition: left 0.2s linear;
}
.rtl-mode .theme-panel.theme-panel-lg {
  left: -260px;
  right: auto;
}
.rtl-mode .theme-panel.active,
.rtl-mode .theme-panel.theme-panel-lg.active {
  left: 0;
  right: auto;
}
.rtl-mode .theme-panel .theme-collapse-btn,
.rtl-mode .theme-panel.theme-panel-lg .theme-collapse-btn {
  left: auto;
  right: -30px;
  border-radius: 0 4px 4px 0;
}
.rtl-mode .theme-panel .theme-list > li {
  float: right;
}
.rtl-mode .theme-panel .theme-list > li + li {
  margin-left: 0;
  margin-right: 5px;
}
.rtl-mode .theme-panel.theme-panel-lg .theme-list > li + li {
  margin-left: 0;
  margin-right: 3px;
}
.rtl-mode .widget-stats .stats-icon.stats-icon-lg {
  right: auto;
  left: 0;
}
.rtl-mode .widget-stats .stats-icon {
  left: 15px;
  right: auto;
  margin-left: 0;
  margin-right: 15px;
}
.rtl-mode .dropdown-menu.media-list > .media .media-left {
  padding-right: 0;
  padding-left: 10px;
}
.rtl-mode .media-list,
.rtl-mode .list-group {
  padding-left: 0;
  padding-right: 0;
}
.rtl-mode .legend > table tr td.legendLabel {
  padding-right: 0 !important;
  padding-left: 10px !important;
}
.rtl-mode .media > .pull-left,
.rtl-mode .media > .media-left {
  padding-left: 15px;
  padding-right: 0;
}
.rtl-mode .media > .pull-right,
.rtl-mode .media > .media-right {
  padding-right: 15px;
  padding-left: 0;
}
.rtl-mode .todolist-input {
  border-right: none;
  border-left: 1px solid #ddd;
}
.rtl-mode .input-group > .custom-select:not(:last-child),
.rtl-mode .input-group > .form-control:not(:last-child) {
  border-radius: 0 0.25rem 0.25rem 0;
}
.rtl-mode .input-group > .custom-select:not(:first-child),
.rtl-mode .input-group > .form-control:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem;
}
.rtl-mode .input-group-addon:last-child,
.rtl-mode .input-group > .input-group-append > .btn,
.rtl-mode .input-group > .input-group-append > .input-group-text,
.rtl-mode .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl-mode
  .input-group
  > .input-group-prepend:not(:first-child)
  > .input-group-text,
.rtl-mode
  .input-group
  > .input-group-prepend:first-child
  > .btn:not(:first-child),
.rtl-mode
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.rtl-mode .input-group .form-control + .input-group-btn > .btn:last-child {
  border-radius: 0.25rem 0 0 0.25rem;
}
.rtl-mode .input-group-addon:first-child,
.rtl-mode .input-group > .input-group-prepend > .btn,
.rtl-mode .input-group > .input-group-prepend > .input-group-text,
.rtl-mode
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl-mode
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.rtl-mode .input-group > .input-group-append:not(:last-child) > .btn,
.rtl-mode
  .input-group
  > .input-group-append:not(:last-child)
  > .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}
.rtl-mode .input-group-append > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rtl-mode .input-group-prepend > .btn:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rtl-mode .input-group-append > .btn:first-child:last-child {
  border-radius: 0.25rem 0 0 0.25rem;
}
.rtl-mode .input-group-prepend > .btn:first-child:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}
.rtl-mode .input-group-append {
  margin-left: 0;
  margin-right: -1px;
}
.rtl-mode .input-group-prepend {
  margin-right: 0;
  margin-left: -1px;
}
.rtl-mode .btn-group .btn + .btn {
  margin-right: -1px;
}
.rtl-mode .btn-group > .btn-group:not(:first-child) > .btn,
.rtl-mode .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rtl-mode .btn-group > .btn-group:not(:last-child) > .btn,
.rtl-mode .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rtl-mode .btn-group + .btn-group {
  margin-right: 5px;
}
.rtl-mode .inbox .email-to .control-label {
  left: auto;
  right: 0;
  padding-left: 0;
  padding-right: 10px;
}
.rtl-mode .inbox .float-right-link {
  right: auto;
  left: 0;
}
.rtl-mode ul.tagit li {
  float: right;
}
.rtl-mode .inbox .email-to .tagit,
.rtl-mode .inbox .email-to .form-control {
  padding: 9px 45px 9px 12px !important;
}
.rtl-mode .attached-document > li {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.rtl-mode dd {
  margin-right: 0;
}
.rtl-mode .nav {
  padding-right: 0;
}
.rtl-mode .tab-overflow .next-button {
  left: 0;
  right: auto;
}
.rtl-mode .tab-overflow .prev-button {
  right: 0;
  left: auto;
}
.rtl-mode .panel-heading-btn {
  float: left;
}
.rtl-mode .panel-heading-btn > a {
  margin-left: 0;
  margin-right: 8px;
}
.rtl-mode .panel-heading.p-0 .panel-heading-btn {
  margin-left: 10px;
  margin-right: 0 !important;
}
.rtl-mode .close {
  float: left;
  margin-left: 0;
  margin-right: 15px;
}
.rtl-mode .dropdown {
  text-align: right;
}
.rtl-mode .hljs-wrapper {
  direction: ltr;
  text-align: left;
}
.rtl-mode .input-group-append .btn.btn-lime,
.rtl-mode .btn-group .btn.btn-lime:not(.active) + .btn.btn-lime,
.rtl-mode .input-group-btn .btn.btn-lime:not(.active) + .btn.btn-lime {
  border-right-color: #6c9738;
  border-left-color: #90ca4b;
}
.rtl-mode .input-group-prepend .btn.btn-lime {
  border-left-color: #6c9738;
  border-right-color: #90ca4b;
}
.rtl-mode .input-group-append .btn.btn-grey,
.rtl-mode .btn-group .btn.btn-grey:not(.active) + .btn.btn-grey,
.rtl-mode .input-group-btn .btn.btn-grey:not(.active) + .btn.btn-grey {
  border-right-color: #889197;
  border-left-color: #b6c2c9;
}
.rtl-mode .input-group-prepend .btn.btn-grey {
  border-left-color: #889197;
  border-right-color: #b6c2c9;
}
.rtl-mode .input-group-append .btn.btn-yellow,
.rtl-mode .btn-group .btn.btn-yellow:not(.active) + .btn.btn-yellow,
.rtl-mode .input-group-btn .btn.btn-yellow:not(.active) + .btn.btn-yellow {
  border-right-color: #bfa300;
  border-left-color: #ffd900;
}
.rtl-mode .input-group-prepend .btn.btn-yellow {
  border-left-color: #bfa300;
  border-right-color: #ffd900;
}
.rtl-mode .input-group-append .btn.btn-purple,
.rtl-mode .btn-group .btn.btn-purple:not(.active) + .btn.btn-purple,
.rtl-mode .input-group-btn .btn.btn-purple:not(.active) + .btn.btn-purple {
  border-right-color: #555d88;
  border-left-color: #727cb6;
}
.rtl-mode .input-group-prepend .btn.btn-purple {
  border-left-color: #555d88;
  border-right-color: #727cb6;
}
.rtl-mode .input-group-append .btn.btn-info,
.rtl-mode .btn-group .btn.btn-info:not(.active) + .btn.btn-info,
.rtl-mode .input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
  border-right-color: #3a92ab;
  border-left-color: #49b6d6;
}
.rtl-mode .input-group-prepend .btn.btn-info {
  border-left-color: #3a92ab;
  border-right-color: #49b6d6;
}
.rtl-mode .input-group-append .btn.btn-danger,
.rtl-mode .btn-group .btn.btn-danger:not(.active) + .btn.btn-danger,
.rtl-mode .input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
  border-right-color: #cc4946;
  border-left-color: #702963;
}
.rtl-mode .input-group-prepend .btn.btn-danger {
  border-left-color: #cc4946;
  border-right-color: #702963;
}
.rtl-mode .input-group-append .btn.btn-warning,
.rtl-mode .btn-group .btn.btn-warning:not(.active) + .btn.btn-warning,
.rtl-mode .input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
  border-right-color: #c47d15;
  border-left-color: #f59c1a;
}
.rtl-mode .input-group-prepend .btn.btn-warning {
  border-left-color: #c47d15;
  border-right-color: #f59c1a;
}
.rtl-mode .input-group-append .btn.btn-success,
.rtl-mode .btn-group .btn.btn-success:not(.active) + .btn.btn-success,
.rtl-mode .input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
  border-right-color: #008a8a;
  border-left-color: #00acac;
}
.rtl-mode .input-group-prepend .btn.btn-success {
  border-left-color: #008a8a;
  border-right-color: #00acac;
}
.rtl-mode .input-group-append .btn.btn-primary,
.rtl-mode .btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.rtl-mode .input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
  border-right-color: #2a72b5;
  border-left-color: #348fe2;
}
.rtl-mode .input-group-prepend .btn.btn-primary {
  border-left-color: #2a72b5;
  border-right-color: #348fe2;
}
.rtl-mode .input-group-append .btn.btn-inverse,
.rtl-mode .btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse,
.rtl-mode .input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
  border-right-color: #242a30;
  border-left-color: #2d353c;
}
.rtl-mode .input-group-prepend .btn.btn-inverse {
  border-left-color: #242a30;
  border-right-color: #2d353c;
}
.rtl-mode .input-group-append .btn.btn-white,
.rtl-mode .btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.rtl-mode .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
  border-right-color: #d9dfe3;
}
.rtl-mode .input-group-prepend .btn.btn-white {
  border-left-color: #d9dfe3;
}
.rtl-mode .input-group-append .btn.btn-default,
.rtl-mode .btn-group .btn.btn-default:not(.active) + .btn.btn-default,
.rtl-mode .input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
  border-right-color: #c5ced4;
  border-left-color: #f2f3f4;
}
.rtl-mode .input-group-prepend .btn.btn-default {
  border-left-color: #c5ced4;
  border-right-color: #f2f3f4;
}
.rtl-mode
  .btn-group
  > .btn
  + .btn:not(:last-child):not(:first-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.rtl-mode .dropdown-menu {
  text-align: right;
}
.rtl-mode .datepicker {
  direction: rtl;
}
.rtl-mode .list-unstyled {
  padding-right: 0;
}
.rtl-mode
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: auto !important;
  left: 5px !important;
}
.rtl-mode .combobox-container .input-group input {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.rtl-mode .bootstrap-select.btn-group .btn .filter-option {
  text-align: right;
}
.rtl-mode .bootstrap-select.btn-group .btn .caret {
  right: auto;
  left: 12px;
}
.rtl-mode .bootstrap-select .dropdown-toggle::after {
  display: none;
}
.rtl-mode .bootstrap-select > .btn {
  padding-left: 25px;
  padding-right: 12px;
}
.rtl-mode .form-check-input {
  margin-left: 0;
  margin-right: -1.25rem;
}
.rtl-mode .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}
.rtl-mode .form-check-inline .form-check-input {
  margin-left: 0.3125rem;
  margin-right: 0;
}
.rtl-mode .form-check-inline {
  padding-right: 0;
  margin-left: 0.75rem;
  margin-right: 0;
}
.rtl-mode .sw-main .sw-toolbar .btn + .btn {
  margin-left: 0;
  margin-right: 10px;
}
.rtl-mode .sw-main .sw-toolbar .sw-btn-next,
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled,
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled:hover,
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled:focus {
  border-color: transparent !important;
}
.rtl-mode .sw-main > ul.step-anchor.nav-tabs > li > a .number {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}
.rtl-mode .sw-main > ul.step-anchor.nav-tabs > li > a .number + .info {
  padding-left: 15px;
  padding-right: 0;
}
.rtl-mode .sw-theme-default .step-content {
  text-align: right;
}
.rtl-mode ul.wysihtml5-toolbar > li {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}
.rtl-mode .dataTables_length,
.rtl-mode div.dataTables_info {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.rtl-mode div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}
.rtl-mode div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5em;
}
.rtl-mode .pagination > li:first-child > a {
  margin-left: 5px;
}
.rtl-mode .pagination > li:last-child > a {
  margin-left: 0;
}
.rtl-mode .pagination {
  padding-right: 0;
}
.rtl-mode div.dataTables_wrapper div.dataTables_paginate {
  text-align: left;
}
.rtl-mode .dt-buttons.btn-group {
  float: right;
}
.rtl-mode table.table-bordered.dataTable th:last-child,
.rtl-mode table.table-bordered.dataTable th:last-child,
.rtl-mode table.table-bordered.dataTable td:last-child,
.rtl-mode table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}
.rtl-mode table.dataTable thead .sorting:after,
.rtl-mode table.dataTable thead .sorting_asc:after,
.rtl-mode table.dataTable thead .sorting_desc:after,
.rtl-mode table.dataTable thead .sorting_asc_disabled:after,
.rtl-mode table.dataTable thead .sorting_desc_disabled:after {
  right: auto;
  left: 8px;
}
.rtl-mode table.dataTable thead > tr > th.sorting_asc,
.rtl-mode table.dataTable thead > tr > th.sorting_desc,
.rtl-mode table.dataTable thead > tr > th.sorting,
.rtl-mode table.dataTable thead > tr > td.sorting_asc,
.rtl-mode table.dataTable thead > tr > td.sorting_desc,
.rtl-mode table.dataTable thead > tr > td.sorting {
  padding-right: 15px;
  padding-left: 30px;
}
.rtl-mode .DTFC_LeftWrapper {
  display: none;
}
.rtl-mode .fc {
  direction: rtl;
  text-align: right;
}
.rtl-mode .fc-day-grid-event .fc-time {
  float: right;
}
.rtl-mode .map {
  left: 0;
  right: 220px;
}
.rtl-mode .content.content-full-width .breadcrumb {
  margin-left: 20px;
  margin-right: 0;
}
.rtl-mode .map-float-table {
  right: auto;
  left: 25px;
}
.rtl-mode .map .jvectormap-zoomin,
.rtl-mode .map .jvectormap-zoomout {
  right: 25px !important;
}
.rtl-mode .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}
.rtl-mode .footer {
  margin: 0 250px 0 30px;
}
.rtl-mode .page-without-sidebar .content {
  margin-right: 0;
}
.rtl-mode .page-with-wide-sidebar.page-sidebar-minified .content,
.rtl-mode
  .page-sidebar-minified.page-right-sidebar-collapsed.page-with-two-sidebar
  .content,
.rtl-mode
  .page-with-wide-sidebar.page-sidebar-minified.page-right-sidebar-collapsed.page-with-two-sidebar
  .content {
  margin-right: 60px;
}
.rtl-mode .page-with-two-sidebar .sidebar.sidebar-right,
.rtl-mode .page-with-two-sidebar .sidebar-bg.sidebar-right {
  left: 0;
  right: auto;
}
.rtl-mode .page-with-two-sidebar .content {
  margin-left: 220px;
}
.rtl-mode .page-with-two-sidebar.page-with-wide-sidebar .content {
  margin-left: 250px;
}
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed .content {
  margin-right: 220px;
  margin-left: 0;
}
.rtl-mode
  .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .content {
  margin-right: 250px;
}
.rtl-mode
  .page-with-two-sidebar.page-right-sidebar-collapsed
  .sidebar.sidebar-right,
.rtl-mode
  .page-with-two-sidebar.page-right-sidebar-collapsed
  .sidebar-bg.sidebar-right {
  right: auto;
  left: -220px;
}
.rtl-mode
  .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .sidebar.sidebar-right,
.rtl-mode
  .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar
  .sidebar-bg.sidebar-right {
  left: -250px;
}
.rtl-mode input.knob {
  transform: translate(140%, 0);
}
.rtl-mode .page-with-wide-sidebar .content {
  margin-right: 250px;
}
.rtl-mode .top-menu .nav > li > a i {
  margin-right: 0;
  margin-left: 10px;
  float: right;
}
.rtl-mode .top-menu li > a .caret {
  float: left;
}
.rtl-mode .top-menu .nav > li > a .icon-img {
  float: right;
  margin-right: 0;
  margin-left: 7px;
}
.rtl-mode .top-menu .nav > li.menu-control > a i {
  margin: 0;
  float: none;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left {
  left: auto;
  right: -50px;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left > a {
  box-shadow: -5px 0 8px -3px rgba(0, 0, 0, 0.5);
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left.show {
  -webkit-animation: slideInRight 0.2s;
  animation: slideInRight 0.2s;
  right: 0;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right {
  right: auto;
  left: -50px;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right > a {
  box-shadow: 5px 0 8px -3px rgba(0, 0, 0, 0.5);
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right.show {
  -webkit-animation: slideInLeft 0.2s;
  animation: slideInLeft 0.2s;
  left: 0;
}
.rtl-mode .top-menu .nav .sub-menu {
  left: auto;
  right: 0;
}
.rtl-mode.boxed-layout .page-container #header,
.rtl-mode.boxed-layout .page-container .sidebar,
.rtl-mode.boxed-layout .page-container .sidebar-bg {
  left: auto;
  right: auto;
}
.rtl-mode .boxed-layout .top-menu,
.rtl-mode .boxed-layout .pace {
  left: auto;
  margin-left: 0;
  right: 50%;
  margin-right: -540px;
}
.rtl-mode .timeline-header .userimage {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.rtl-mode .timeline-likes .stats-right {
  float: left;
}
.rtl-mode .timeline-likes .stats-icon {
  float: right;
}
.rtl-mode .timeline-likes .stats-text {
  float: right;
}
.rtl-mode .timeline-likes .stats-text + .stats-text {
  margin-left: 0;
  margin-right: 15px;
}
.rtl-mode .timeline-likes .stats-icon {
  margin-left: 5px;
}
.rtl-mode .timeline-likes .stats-icon + .stats-icon {
  margin-right: -2px;
  margin-left: 5px;
}
.rtl-mode .countdown-section {
  float: right;
}
.rtl-mode
  .coming-soon
  .timer
  .is-countdown
  .countdown-section
  + .countdown-section {
  border-left: none;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.rtl-mode .profile-header-img {
  float: right;
}
.rtl-mode .profile-header-img + .profile-header-info {
  margin-left: 0;
  margin-right: 140px;
}
.rtl-mode .profile-header .profile-header-tab {
  padding-left: 0;
  padding-right: 140px;
}
.rtl-mode .login .login-header {
  left: auto;
  right: 50%;
  margin-right: -225px;
}
.rtl-mode .login .login-header .icon {
  right: auto;
  left: 40px;
}
.rtl-mode .checkbox.checkbox-css label,
.rtl-mode .radio.radio-css label {
  padding-left: 0;
  padding-right: 24px;
}
.rtl-mode .checkbox.checkbox-css label:before,
.rtl-mode .checkbox.checkbox-css input:checked + label:after,
.rtl-mode .radio.radio-css label:before,
.rtl-mode .radio.radio-css input:checked + label:after {
  left: auto;
  right: 0;
}
.rtl-mode .radio.radio-css input:checked + label:after {
  right: 5px;
}
.rtl-mode .login.login-v2 {
  margin-left: 0;
  margin-right: -200px;
  right: 50%;
  left: auto;
}
.rtl-mode .login.login-v2 .login-header {
  left: 0;
  right: 0;
  margin: 0;
}
.rtl-mode .login .login-header .brand .logo {
  margin-left: 10px;
  margin-right: 0;
}
.rtl-mode .login.login-v2 .login-header .icon {
  left: 20px;
  right: auto;
}
.rtl-mode .login-bg-list {
  right: auto;
  left: 20px;
}
.rtl-mode .login.login-with-news-feed .news-feed,
.rtl-mode .register.register-with-news-feed .news-feed {
  left: 500px;
  right: 0;
}
.rtl-mode .login.login-with-news-feed .right-content,
.rtl-mode .register.register-with-news-feed .right-content {
  float: left;
}
.rtl-mode .login.login-with-news-feed .login-header,
.rtl-mode .register.register-with-news-feed .register-header {
  left: 0;
  right: 0;
  margin: 0;
}
.rtl-mode .navbar-language .flag-icon {
  float: right;
  margin-right: 0;
  margin-left: 7px;
}
.rtl-mode .widget-list-item .widget-list-media + div,
.rtl-mode .widget-list-item .widget-list-content + div,
.rtl-mode .widget-list-item .widget-list-action + div {
  padding-left: 15px;
  padding-right: 0;
}
.rtl-mode .widget-todolist-item .widget-todolist-input + div {
  padding-left: 15px;
  padding-right: 0;
}
